import { Divider, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Tag } from 'components/Form'
import { format } from 'date-fns'
import { safeParseISO } from 'lib/utils'

export type UseCaseProps = {
  date?: string | undefined | null
  data?: App.Models.UseCase[] | null
}

const UseCaseTable: React.VFC<UseCaseProps> = ({ data, date }) => {
  return (
    <TableContainer sx={{ mb: 4 }}>
      {!!data?.length && <Divider />}
      <Table size="small">
        <TableBody>
          {data &&
            data.map((el) => (
              <TableRow key={el.id}>
                <TableCell align="center" sx={{ width: 80 }}>
                  {date && format(safeParseISO(date as string), 'yyyy/MM')}
                </TableCell>
                <TableCell sx={{ minWidth: 300 }}>{el.subject}</TableCell>
                <TableCell>
                  <Tag size="small" label={el?.fd_ticket_category1} sx={{ mr: 1 }} />
                  <Tag size="small" label={el?.fd_ticket_category2} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { UseCaseTable }
