import { Box, BoxProps } from '@mui/material'
import { TitleComponentOption } from 'echarts'
import { PieChart as EPieChart, PieSeriesOption } from 'echarts/charts'
import {
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption
} from 'echarts/components'
import type { ComposeOption, ECharts } from 'echarts/core'
import { use } from 'echarts/core'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { merge } from 'lodash'
import { forwardRef } from 'react'
import { CoreChart, CoreChartProps } from './Core'
import { defaultPieOptions } from './defaultOptions'

use([TooltipComponent, LegendComponent, EPieChart, CanvasRenderer, LabelLayout, TitleComponent])

export type PieChartOption = ComposeOption<
  TooltipComponentOption | LegendComponentOption | PieSeriesOption | TitleComponentOption
>

type PieChartProps = BoxProps<'div', CoreChartProps<PieChartOption>> & {
  series?: PieSeriesOption
}

const PieChart = forwardRef<ECharts, PieChartProps>(function Pie(props, ref) {
  const { options, settings, loading, series, ...boxProps } = props
  const _options = merge({}, defaultPieOptions, options, { series })

  return (
    <Box width="100%" height="100%" {...boxProps}>
      <CoreChart {...{ options: _options, settings, loading }} ref={ref} />
    </Box>
  )
})

export { PieChart }
