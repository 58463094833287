import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { ButtonBase, ButtonBaseProps, styled } from '@mui/material'
import React from 'react'

type ArrowProps = ButtonBaseProps<
  'button',
  {
    collapsed: boolean
    button?: boolean
  }
>

const ArrowButton = styled(ButtonBase)({
  flexShrink: 0,
  alignSelf: 'stretch',
  display: 'inline-flex',
  alignItems: 'center',
  '&:last-child': {
    marginLeft: 'auto'
  },
  borderRadius: 4,
  minWidth: 44,
  backgroundColor: 'inherit'
})

const Arrow: React.VFC<ArrowProps> = ({ collapsed, button = true, ...buttonProps }) => {
  const getChildren = () => {
    return collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />
  }

  if (!button) {
    return (
      <div
        style={{
          flexShrink: 0,
          alignSelf: 'stretch',
          display: 'inline-flex',
          alignItems: 'center'
        }}
      >
        {getChildren()}
      </div>
    )
  }

  return <ArrowButton {...buttonProps}>{getChildren()}</ArrowButton>
}

export { Arrow }
