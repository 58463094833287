import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { format } from 'date-fns'
import { userVoicesCategories } from 'lib/constants'
import { safeParseISO } from 'lib/utils'
import { useMemo } from 'react'

export type UserVoiceType = App.Models.UserVoice & {
  category: App.Models.ReportCategory
}

export type UseVoiceProps = {
  date?: string | undefined | null
  data?: UserVoiceType[]
}

const getLabel = (id: number) => {
  return userVoicesCategories.find((el) => el.value === id)?.label as string
}

const UseVoiceReport: React.VFC<UseVoiceProps> = ({ date, data }) => {
  const _data = useMemo(() => {
    const map = new Map()

    data?.forEach((el) => {
      let arr = map.get(el.category_id)
      if (arr) {
        arr.push(el)
      } else {
        arr = [el]
      }
      map.set(el.category_id, arr)
    })

    return Array.from(map)
  }, [data])

  return (
    <Box mb={4}>
      {_data &&
        _data.map(([id, el]) => (
          <Stack key={id} mb={2}>
            <Typography variant="h6" mb={1}>
              {getLabel(id)}
            </Typography>
            <UseVoiceTable data={el} date={date} />
          </Stack>
        ))}
    </Box>
  )
}

export type UseVoiceTableProps = UseVoiceProps

const UseVoiceTable: React.VFC<UseVoiceTableProps> = ({ date, data }) => {
  return (
    <TableContainer>
      <Table sx={{ width: '100%' }}>
        <TableBody>
          {data &&
            data.map((el) => (
              <TableRow
                key={el.id}
                sx={{
                  '& td': { border: 1, p: 1, borderColor: (theme) => theme.palette.grey[100] }
                }}
              >
                <TableCell padding="none" align="center" sx={{ width: 80 }}>
                  {date && format(safeParseISO(date as string), 'yyyy/MM')}
                </TableCell>
                <TableCell padding="none" sx={{ width: 300 }}>
                  {el.subject}
                </TableCell>
                <TableCell padding="none">{el.content}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { UseVoiceReport }
