import { LoadingButton } from '@mui/lab'
import {
  Box,
  Grid,
  Stack,
  styled,
  Tab as MTab,
  tabClasses,
  Tabs as MTabs,
  tabsClasses,
  useTheme
} from '@mui/material'
import { BarChart, LineChart, PieChart, TableChart } from 'components/Charts'
import { ListChart } from 'components/Charts/ListChart'
import { a11yProps, TabPanel as UTabPanel } from 'components/Form'
import { drawerWidth } from 'components/Layouts/Drawer'
import { request } from 'lib/request'
import { UnknownObj } from 'lib/types'
import { formatISODate, sliceString } from 'lib/utils'
import { useState, VFC } from 'react'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { Column } from 'react-table'
import { grey } from 'styles'

const Tabs = styled(MTabs)({
  [`& .${tabsClasses.indicator}`]: {
    display: 'none'
  },
  height: 28,
  minHeight: 28
})

const TabPanel = styled(UTabPanel, {
  shouldForwardProp: (prop) => !['height', 'summary'].includes(prop as string)
})<{ height?: number }>(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  overflow: 'hidden',
  transition: theme.transitions.create(['height'])
}))

const Tab = styled(MTab, {
  shouldForwardProp: (prop) => prop !== 'legend'
})<{ legend?: boolean }>(({ theme, legend }) => ({
  opacity: 0.7,
  backgroundColor: theme.palette.grey['A100'],
  marginRight: theme.spacing(0.5),
  fontSize: '0.75rem',
  borderTopRightRadius: 5,
  borderTopLeftRadius: 5,
  padding: theme.spacing(1),
  height: 28,
  minHeight: 28,
  border: `1px solid ${theme.palette.grey['A100']}`,
  [`&.${tabClasses.selected}`]: {
    opacity: 1,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${grey[500]}`,
    borderBottom: 'none'
  },
  ...(legend && {
    color: 'black !important',
    backgroundColor: theme.palette.common.white,
    border: 'none',
    fontSize: 18,
    fontWeight: 700,
    cursor: 'auto'
  })
}))

type Quantitative = {
  id: number
  result: string
  company_report_id: number
  report_content_id: number
  report_content: {
    id: number
    name: string
    chart_type_id: ChartType
    sort_order: number
  }
}

type ChartReportDataType = {
  id: number
  name: string
  sort_order: number
  quantitatives: Quantitative[]
  quantitative_ids?: number[]
}

const ascSortChart = (q1: Quantitative, q2: Quantitative) =>
  q1.report_content.sort_order - q2.report_content.sort_order

enum ChartType {
  None,
  Line,
  Pie,
  BarHorizontal,
  BarVertical,
  List
}

const shouldXLWidth = ['category', 'Subject']
const shouldLWidth = [
  'Gender',
  'building',
  'Category 1',
  'Category 2',
  'Usage Route',
  'Group 1',
  'Group 2',
  'Company'
]
const shouldMWidth = ['Input Date', 'Created Date', 'Age Group', 'Language']
const shouldSWidth = ['ID']

const estimateWidthByName = (name: string, count: number) => {
  const nameCompare = (el: string) => name.includes(el)

  if (shouldXLWidth.some(nameCompare)) {
    const screen_width = window.screen.width

    if (screen_width > 1200 && screen_width <= 1500) {
      return count < 8 ? screen_width - drawerWidth - (count - 1) * 100 : 340
    }

    if (screen_width > 940 && screen_width <= 1200) {
      return count < 8 ? screen_width - drawerWidth - (count - 1) * 70 : 340
    }

    if (screen_width > 800 && screen_width <= 940) {
      return count < 8 ? screen_width - drawerWidth - (count - 1) * 30 : 340
    }

    if (screen_width > 600 && screen_width <= 800) {
      return count < 8 ? screen_width - drawerWidth - (count - 1) * 10 : 340
    }

    return 450
  }

  if (shouldLWidth.some(nameCompare)) {
    return 200
  }

  if (shouldMWidth.some(nameCompare)) {
    return 120
  }

  if (shouldSWidth.some(nameCompare)) {
    return 50
  }

  return 150
}

const getPieData = (result: Record<string, number>) => {
  const total = Object.keys(result)
    .filter((k) => result[k] != 0)
    .map((k) => result[k])
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0)

  return Object.keys(result)
    .filter((k) => result[k] != 0)
    .map((k) => ({
      value: result[k],
      name: k + ': ' + result[k] + ` (${((result[k] * 100) / total).toFixed(1)}%)`
    }))
}

const getListData = (result: Record<string, any>) => {
  const count = Object.keys(result[0]).length
  const columns: Column[] = Object.keys(result[0])
    .filter((k) => k !== 'name')
    .map((name) => ({
      Header: name,
      accessor: name,
      width: estimateWidthByName(name, count)
    }))
  if (result[0].name) {
    columns.unshift({
      accessor: 'name'
    })
  }

  return columns
}

const ChartReport: VFC<{
  date: Date
  comId: number
  chartTitle?: string | null | undefined
  handleDisplay?: (tabIndex: number) => void
}> = ({ date, comId, handleDisplay, chartTitle }) => {
  const [value, setValue] = useState(1)
  const theme = useTheme()
  const [exportLoading, setExportLoading] = useState<number[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleDisplay && handleDisplay(newValue)
    setValue(newValue)
    document.getElementById(`tabpanel-${newValue}`) as HTMLDivElement
  }

  const { data } = useQuery<ChartReportDataType[]>(
    [
      'company-report-chart/' + comId,
      {
        month_year: formatISODate(date, '/')
      }
    ],
    { enabled: !!date }
  )

  const getDataByOrder = (order: number) => {
    return data?.find((el) => el.sort_order === order)
  }

  const renderChart = (quantitative: Quantitative, index: number) => {
    const chartType = quantitative.report_content.chart_type_id
    const result = JSON.parse(quantitative.result || 'null')

    if (result) {
      const categories = Object.keys(result).filter((k) => result[k] != 0)
      const values = categories.map((el) => result[el])
      let columns = []
      let pieData = []

      switch (chartType) {
        case ChartType.Pie:
          pieData = getPieData(result)
          if (!pieData.length) return

          return (
            <Grid item md={6} key={index}>
              <PieChart
                key={quantitative.id}
                series={{ name: quantitative.report_content.name, type: 'pie', data: pieData }}
                options={{
                  legend: {
                    orient: 'vertical',
                    left: 0,
                    top: '10%'
                  },
                  series: {
                    top: '20%'
                  },
                  title: {
                    text: quantitative.report_content.name,
                    textStyle: {
                      fontSize: 16,
                      fontWeight: 'bold'
                    }
                  }
                }}
                height={400}
              />
            </Grid>
          )

        case ChartType.BarHorizontal:
          if (!categories.length) return

          return (
            <Grid item md={6} key={index}>
              <BarChart
                key={quantitative.id}
                options={{
                  yAxis: {
                    type: 'category',
                    data: categories,
                    axisLabel: {
                      formatter: (label: string) => sliceString(label, 10),
                      fontSize: 10
                    }
                  },
                  xAxis: {
                    type: 'value'
                  },
                  title: {
                    text: quantitative.report_content.name
                  },
                  legend: {
                    show: false
                  },
                  series: {
                    label: {
                      show: true,
                      position: 'top'
                    }
                  }
                }}
                series={{ name: quantitative.report_content.name, type: 'bar', data: values }}
                height={400}
              />
            </Grid>
          )

        case ChartType.BarVertical:
          if (!categories.length) return

          return (
            <>
              {['登録者（累積）', '利用者月次推移データ', '利用件数（単月）'].includes(
                quantitative?.report_content?.name
              ) && (
                <div style={{ position: 'relative', width: '100%' }}>
                  <div
                    style={{
                      fontSize: '13px',
                      marginLeft: '22px',
                      marginTop: '30px',
                      cursor: 'none',
                      fontWeight: 500
                    }}
                  >
                    集計データの小数点以下の 端数処理については、小数点第2位を四捨五入しております。
                  </div>
                </div>
              )}
              <Grid item md={6} key={index}>
                <BarChart
                  key={quantitative.id}
                  options={{
                    yAxis: {
                      type: 'value'
                    },
                    xAxis: {
                      type: 'category',
                      data: categories,
                      axisLabel: {
                        rotate: 80,
                        formatter: (label: string) => sliceString(label, 10),
                        fontSize: 10
                      }
                    },
                    title: {
                      text: quantitative.report_content.name,
                      padding: 5
                    },
                    legend: {
                      show: false
                    },
                    series: {
                      label: {
                        show: true,
                        position: 'top'
                      }
                    }
                  }}
                  series={{ name: quantitative.report_content.name, type: 'bar', data: values }}
                  height={400}
                />
              </Grid>
            </>
          )

        case ChartType.List: {
          if (!result.data.length) return

          const exportCSV = async () => {
            const params = {
              company_id: comId,
              report_content_id: quantitative.report_content.id,
              month: formatISODate(date, '/'),
              company_report_id: quantitative.company_report_id,
              quantitative_id: quantitative.id
            }
            try {
              setExportLoading((l) => l.concat(params.report_content_id))

              const res = await request.get('export-report', {
                responseType: 'blob',
                params
              })

              setExportLoading((ld) => ld.filter((id) => id !== params.report_content_id))

              const blob = res.data
              const url = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                quantitative.report_content.name + '.csv' || chartTitle + '.csv'
              )
              document.body.appendChild(link)
              link.click()
            } catch (error) {
              console.log(error)
              setExportLoading((ld) => ld.filter((id) => id !== params.report_content_id))
              toast.error('ダウンロードに失敗しました。')
            }
          }

          columns = result?.header?.map((el: string) => ({
            Header: el,
            accessor: el,
            width: estimateWidthByName(el, result?.header?.length)
          }))

          const totalColumnsWidth = columns.reduce(function (preValue: number, curValue: any) {
            return preValue + curValue.width
          }, 32)

          return (
            <Grid item md={12} key={index}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                width={result?.header?.length < 8 ? totalColumnsWidth : 'auto'}
              >
                <LoadingButton
                  variant="outlined"
                  color="inherit"
                  loading={exportLoading.includes(quantitative.report_content.id)}
                  onClick={exportCSV}
                  sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    borderRadius: '8px',
                    maxWidth: 150
                  }}
                >
                  CSVエクスポート
                </LoadingButton>
              </Stack>
              <ListChart key={quantitative.id} columns={columns} data={result?.data} />
            </Grid>
          )
        }

        default:
          break
      }
    }
  }

  const renderSummaryTab = () => {
    if (!data) return
    const _data = getDataByOrder(1)
    const result = _data?.quantitatives[0]?.result
    const reportData = JSON.parse(result || 'null')
    if (!reportData) return <></>
    const legend = Object.keys(reportData)
    const categories = Object.keys(reportData[legend[0]])
    const series = legend.map((name) => ({
      name,
      type: 'line' as const,
      data: Object.values(reportData[name]) as number[],
      label: {
        show: true,
        position: 'top' as const
      }
    }))

    const _dataTable = Object.keys(reportData).reduce<UnknownObj[]>((d, k) => {
      const obj: UnknownObj = reportData[k]
      obj.name = k
      d.push(obj)
      return d
    }, []) as []

    const columns = getListData(_dataTable)

    const exportCSV = async () => {
      if (!_data) return
      const params = {
        company_id: comId,
        category_id: _data.id,
        month: formatISODate(date as string | Date, '/'),
        company_report_id: _data.quantitatives[0]?.company_report_id,
        quantitative_ids: _data?.quantitative_ids
      }
      try {
        setExportLoading((l) => l.concat(params?.category_id))

        const res = await request.get('/export-report', {
          responseType: 'blob',
          params
        })

        setExportLoading((ld) => ld.filter((id) => id !== params.category_id))

        const blob = res.data
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'summary.csv')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        setExportLoading((ld) => ld.filter((id) => id !== params.category_id))
        toast.error('ダウンロードに失敗しました。')
      }
    }

    return (
      <Box width="80%">
        <LineChart
          options={{
            legend: {
              data: legend
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: categories,
              splitLine: {
                show: true
              }
            },
            yAxis: {
              type: 'value'
            }
          }}
          series={series}
          height={300}
          width="100%"
          sx={{ mb: 3 }}
        />
        <Stack direction="row" mb={1} width="100%" justifyContent="flex-end">
          <LoadingButton
            variant="outlined"
            color="inherit"
            onClick={exportCSV}
            loading={exportLoading.includes(Number(_data?.id))}
            sx={{
              fontWeight: 400,
              fontSize: 12,
              border: `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 2,
              maxWidth: 150
            }}
          >
            CSVエクスポート
          </LoadingButton>
        </Stack>
        <TableChart columns={columns} data={_dataTable} containerSx={{ pl: 4 }} />
      </Box>
    )
  }

  const getTabIndex = (nativeOrder: number) => {
    let idx = nativeOrder
    for (let index = 2; index <= nativeOrder; index++) {
      if (!getDataByOrder(index)?.quantitatives.length) {
        idx--
      }
    }

    return idx
  }

  return (
    <Box mb={4}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="チャート" legend disabled />
        <Tab label="総括レポート" {...a11yProps(1)} />
        {getDataByOrder(2)?.quantitatives.length && (
          <Tab label="登録者（累積）" {...a11yProps(2)} />
        )}
        {getDataByOrder(3)?.quantitatives.length && (
          <Tab label="利用者（累積）" {...a11yProps(3)} />
        )}
        {getDataByOrder(4)?.quantitatives.length && (
          <Tab label="利用件数（単月）" {...a11yProps(4)} />
        )}
        {getDataByOrder(5)?.quantitatives.length && (
          <Tab label="タイムチャージ（単月）" {...a11yProps(5)} />
        )}
        {getDataByOrder(6)?.quantitatives.length && (
          <Tab label="アクティブレポート（単月）" {...a11yProps(6)} />
        )}
      </Tabs>

      {!!getDataByOrder(1)?.quantitatives?.length && (
        <TabPanel value={value} index={1}>
          {renderSummaryTab()}
        </TabPanel>
      )}

      {!!getDataByOrder(2)?.quantitatives?.length && (
        <TabPanel value={value} index={getTabIndex(2)}>
          <Grid container spacing={2}>
            {getDataByOrder(2)
              ?.quantitatives.sort(ascSortChart)
              .map((q, i) => renderChart(q, i))}
          </Grid>
        </TabPanel>
      )}

      {!!getDataByOrder(3)?.quantitatives?.length && (
        <TabPanel value={value} index={getTabIndex(3)}>
          <Grid container spacing={2}>
            {getDataByOrder(3)
              ?.quantitatives.sort(ascSortChart)
              .map((q, i) => renderChart(q, i))}
          </Grid>
        </TabPanel>
      )}

      {!!getDataByOrder(4)?.quantitatives?.length && (
        <TabPanel value={value} index={getTabIndex(4)}>
          <Grid container spacing={2}>
            {getDataByOrder(4)
              ?.quantitatives.sort(ascSortChart)
              .map((q, i) => renderChart(q, i))}
          </Grid>
        </TabPanel>
      )}

      {!!getDataByOrder(5)?.quantitatives?.length && (
        <TabPanel value={value} index={getTabIndex(5)}>
          <Grid container spacing={2}>
            {getDataByOrder(5)
              ?.quantitatives.sort(ascSortChart)
              .map((q, i) => renderChart(q, i))}
          </Grid>
        </TabPanel>
      )}

      {!!getDataByOrder(6)?.quantitatives?.length && (
        <TabPanel value={value} index={getTabIndex(6)}>
          <Grid container spacing={2}>
            {getDataByOrder(6)
              ?.quantitatives.sort(ascSortChart)
              .map((q, i) => renderChart(q, i))}
          </Grid>
        </TabPanel>
      )}
    </Box>
  )
}

export { ChartReport }
