import { Box } from '@mui/material'
import { Select } from 'components/Form'
import { Page } from 'components/Layouts'
import { CellContainer, ReactTable } from 'components/ReactTable'
import { format } from 'date-fns'
import { useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { formatISODate } from 'lib/utils'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { CellProps, Column } from 'react-table'
import { LinkStyled } from 'screens/event'
import LeftHeader from './LeftHeader'

type DateCompanyReport = {
  ym: string
}

const Report: React.VFC = () => {
  const navigate = useNavigate()

  const { hasDetail } = useCheckPerm()

  const { control, setValue, watch } = useForm()
  const { data: dateCompanyReport } = useQuery<DateCompanyReport[]>([
    '/monthly-report-period',
    { per_page: -1 }
  ])
  const dateOptions = dateCompanyReport?.map((d) => ({
    label: format(new Date(d.ym), 'yyyy年MM月'),
    value: d.ym
  }))

  const date = watch('date')
  const lastDate = dateCompanyReport?.find((d, index) => index == 0)?.ym

  useEffect(() => {
    if (lastDate) {
      setValue('date', lastDate)
    }
  }, [lastDate, setValue])

  const { paginationData } = usePaginationQuery<App.Models.Company>(
    'company-monthly-report-content',
    {
      month_year: date
    },
    !!date
  )

  const columns = useMemo<Column<App.Models.Company>[]>(
    () => [
      {
        Header: '企業名',
        accessor: 'name',
        width: 250
      },
      {
        Header: '定性レポート 表示項目',
        accessor: 'company_report_contents',
        Cell: ({ value }) => value?.map((c) => c.name).join(', '),
        width: 1000
      },
      {
        Header: ' ',
        Cell: ({ row, state }: CellProps<App.Models.Company>) => (
          <CellContainer>
            {hasDetail && (
              <LinkStyled
                to={`/company-monthly-report/${row.original.id}?date=${
                  (state as unknown as { date: Date }).date as Date
                }`}
              >
                詳細
              </LinkStyled>
            )}
          </CellContainer>
        ),
        mergeWithAction: true,
        width: 60
      }
    ],
    []
  )

  const handleEdit = ({ row, state }: CellProps<App.Models.Company>) => {
    navigate(
      '/summary-report/' +
        row.original.id +
        '?month_year=' +
        formatISODate((state as unknown as { date: Date }).date as Date)
    )
  }

  return (
    <Page title="法人顧客 月次レポート一覧" leftHeader={<LeftHeader />}>
      <Box mb={2}>
        <Select
          name="date"
          control={control}
          options={dateOptions}
          sx={{ minWidth: 170, minHeight: 40 }}
        />
      </Box>

      <ReactTable<App.Models.Company>
        columns={columns}
        onActionEdit={handleEdit}
        additionState={{ date }}
        {...paginationData}
      />
    </Page>
  )
}

export { Report }
