import { Stack, StackProps } from '@mui/material'
import React from 'react'

const RowToggle: React.FC<StackProps> = ({ children, ...stackProps }) => {
  return (
    <Stack {...stackProps} direction="row" alignContent="center" justifyContent="space-between">
      {children}
    </Stack>
  )
}

export { RowToggle }
