import { SelectOption } from 'components/Form'
import { fdCategory } from 'lib/constants'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

type CategoryOptionValues = {
  category1: SelectOption[]
  category2: SelectOption[]
  event: SelectOption[]
}

const useCategoryOptions = (): CategoryOptionValues => {
  const { data: fdCategories } = useQuery<App.Models.FdTicketCategory[]>([
    'ticket-categories',
    { per_page: -1 }
  ])

  const categoryOptions = useMemo<CategoryOptionValues>(() => {
    const options: CategoryOptionValues = {
      category1: [],
      category2: [],
      event: []
    }

    if (!fdCategories) return options

    fdCategories.forEach((c) => {
      switch (Number(c.fd_ticket_field_id)) {
        case fdCategory.category1:
          options.category1 = (JSON.parse(c.choices || '[]') as string[]).map((f1) => ({
            label: f1,
            value: f1
          }))
          break
        case fdCategory.category2:
          options.category2 = (JSON.parse(c.choices || '[]') as string[]).map((f2) => ({
            label: f2,
            value: f2
          }))
          break
        case fdCategory.event:
          options.event = (JSON.parse(c.choices || '[]') as string[]).map((e) => ({
            label: e,
            value: e
          }))
          break
      }
    })

    return options
  }, [fdCategories])

  return categoryOptions
}

export { useCategoryOptions }
