import { PERM } from 'lib/constants'
import { AdminUser, AdminUserDetail, FormAdminUser } from 'screens/admin-user'
import { Concierge, ConciergeDetail, FormConcierge } from 'screens/concierge'
import { Content, FormContent } from 'screens/content'
import { ContentDetail } from 'screens/content/ContentDetail'
import { CompanyDetail, CustomerCompany, FormCompany } from 'screens/customer-company'
import { CustomerReport } from 'screens/customer-report'
import { EventDetail, EventSubScriber, EventTabs, FormEvent } from 'screens/event'
import { FormGroup, Group, GroupDetail } from 'screens/group'
import { FormInstructor, Instructor, InstructorDetail, MonthlyReward } from 'screens/instructor'
import { KeyVisual } from 'screens/key-visual'
import { FormNew, New, NewDetail } from 'screens/new'
import { CompanyMonthlyReport, FormMonthlyReport, Report } from 'screens/report'
import { FormReport } from 'screens/report/FormReport'
import { FormUser, User, UserDetail } from 'screens/user'
import { WorkingRecordTab } from 'screens/working-record'

export type RouterProps = {
  index?: boolean
  path?: string
  element: React.ReactNode
  permissions: number[]
}

export const routerPermission: RouterProps[] = [
  {
    index: true,
    path: '/',
    element: <WorkingRecordTab />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER, PERM.RESEARCH]
  },
  {
    path: '/admin-user',
    element: <AdminUser />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE]
  },
  {
    path: '/admin-user/create',
    element: <FormAdminUser />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/admin-user/edit/:id',
    element: <FormAdminUser />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/admin-user/detail/:id',
    element: <AdminUserDetail />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE]
  },
  {
    path: '/company',
    element: <CustomerCompany />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/company/create',
    element: <FormCompany />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/company/edit/:id',
    element: <FormCompany />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/company/detail/:id',
    element: <CompanyDetail />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/instructor',
    element: <Instructor />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER]
  },
  {
    path: '/instructor/create',
    element: <FormInstructor />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/instructor/edit/:id',
    element: <FormInstructor />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/instructor/detail/:id',
    element: <InstructorDetail />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE]
  },
  {
    path: '/instructor/monthly-reward',
    element: <MonthlyReward />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/group',
    element: <Group />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/group/create',
    element: <FormGroup />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/group/edit/:id',
    element: <FormGroup />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/group/detail/:id',
    element: <GroupDetail />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/user',
    element: <User />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/user/create',
    element: <FormUser />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/user/edit/:id',
    element: <FormUser />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/user/detail/:id',
    element: <UserDetail />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/event',
    element: <EventTabs />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER]
  },
  {
    path: '/event/create',
    element: <FormEvent />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/event/edit/:id',
    element: <FormEvent />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/event/detail/:id',
    element: <EventDetail />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER]
  },
  {
    path: '/event-subscriber/:id',
    element: <EventSubScriber />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER]
  },
  {
    path: '/key-visual',
    element: <KeyVisual />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/concierge',
    element: <Concierge />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/concierge/create',
    element: <FormConcierge />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/concierge/edit/:id',
    element: <FormConcierge />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/concierge/detail/:id',
    element: <ConciergeDetail />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/news',
    element: <New />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/news/create',
    element: <FormNew />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/news/edit/:id',
    element: <FormNew />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/news/detail/:id',
    element: <NewDetail />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: 'working-record',
    element: <WorkingRecordTab />,
    permissions: [PERM.ADMIN, PERM.CONCIERGE, PERM.CONTENT_MANAGER, PERM.RESEARCH]
  },
  {
    path: '/content',
    element: <Content />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/content/create',
    element: <FormContent />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/content/edit/:id',
    element: <FormContent />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/content/detail/:id',
    element: <ContentDetail />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/summary-report',
    element: <FormMonthlyReport />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/monthly-report',
    element: <Report />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/quantitative-report',
    element: <FormReport />,
    permissions: [PERM.ADMIN]
  },
  {
    path: '/company-monthly-report/:id',
    element: <CompanyMonthlyReport />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/summary-report/:id',
    element: <FormMonthlyReport />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
  },
  {
    path: '/customer-report',
    element: <CustomerReport />,
    permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE, PERM.RESEARCH]
  }
]
