import { Button, styled } from '@mui/material'
import { CellContainer, ReactTable } from 'components/ReactTable'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

export const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 500
}))

const EventList: React.VFC = () => {
  const { paginationData, refetch } = usePaginationQuery<App.Models.Event>('events')
  const dialog = useDialog()
  const navigate = useNavigate()
  const { isFullAction, hasDetail } = useCheckPerm()

  const columns = useMemo<Column<App.Models.Event>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'タイトル',
        accessor: 'title',
        width: 500
      },
      {
        Header: '配信グループ',
        accessor: 'group.name' as 'group',
        width: 230
      },
      {
        Header: '講師1',
        accessor: 'instructors[0].display_name' as 'instructors',
        width: 200
      },
      {
        Header: '講師2',
        accessor: 'instructors[1].display_name' as 'instructors',
        width: 200
      },
      {
        Header: '公開',
        accessor: (row) => (Number(row.is_private) == 1 ? '非公開' : '公開'),
        width: 100
      },
      {
        Header: ' ',
        Cell: ({ row }: CellProps<App.Models.Event>) => (
          <CellContainer>
            <Button
              variant="contained"
              size="small"
              component={Link}
              to={`/event-subscriber/${row.original.id}`}
            >
              参加者一覧
            </Button>
          </CellContainer>
        ),
        width: 100,
        mergeWithAction: true,
        sticky: 'right'
      }
    ],
    []
  )

  const { deleteApi } = useApiResource('events')

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.Event>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<App.Models.Event>) => {
    navigate('/event/detail/' + original.id)
  }

  return (
    <ReactTable<App.Models.Event>
      columns={columns}
      defaultActionEdit
      onActionDelete={handleDelete}
      onRowClick={hasDetail ? onRowClick : undefined}
      {...paginationData}
      disabledRowClick={!isFullAction}
      actionConfig={{
        columnWidth: 60
      }}
    />
  )
}

export { EventList }
