import { PERM } from 'lib/constants'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '.'

export type ActionPermission = {
  path: string
  permission: Record<number, Action | Action[]>
}

export enum Action {
  List,
  Full,
  Create,
  Detail,
  Edit,
  Delete
}

const actionPermission: ActionPermission[] = [
  {
    path: '/company',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/key-visual',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/group',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/user',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/news',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full
    }
  },
  {
    path: '/event',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full
    }
  },
  {
    path: '/scheduler',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full,
      [PERM.CONCIERGE]: Action.Full
    }
  },
  {
    path: '/event-subscriber',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full,
      [PERM.CONCIERGE]: [Action.Detail]
    }
  },
  {
    path: '/instructor',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: [Action.Edit, Action.Delete, Action.Create],
      [PERM.CONCIERGE]: [Action.Detail]
    }
  },
  {
    path: '/concierge',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/admin-user',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: [Action.Detail],
      [PERM.CONCIERGE]: [Action.Detail]
    }
  },
  {
    path: '/working-record',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full,
      [PERM.CONCIERGE]: Action.Full,
      [PERM.RESEARCH]: Action.Full
    }
  },
  {
    path: '/monthly-report',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full
    }
  },
  {
    path: '/quantitative-report',
    permission: {
      [PERM.ADMIN]: Action.Full
    }
  },
  {
    path: '/summary-report',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full
    }
  },
  {
    path: '/content',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full
    }
  },
  {
    path: '/customer-report',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full,
      [PERM.CONCIERGE]: Action.Full,
      [PERM.RESEARCH]: Action.Full
    }
  },
  {
    path: '/company-monthly-report',
    permission: {
      [PERM.ADMIN]: Action.Full,
      [PERM.CONTENT_MANAGER]: Action.Full,
      [PERM.CONCIERGE]: Action.List
    }
  }
]

export type EnabledPermission = {
  hasCreate: boolean
  hasEdit: boolean
  hasDelete: boolean
  hasDetail: boolean
  hasDetailButton: boolean
  isFullAction: boolean
  permission: Action | Action[] | undefined
}

export type UseCheckPremParams = {
  tableId?: string
}

export const useCheckPerm = (props: UseCheckPremParams = {}) => {
  const { tableId } = props
  const { user } = useAuth()
  const location = useLocation()

  const getScreen = useCallback(
    (_path?: string) => {
      const path = _path || location.pathname
      return actionPermission.find((perm) => path.includes(perm.path))
    },
    [location.pathname]
  )

  const enabledPermission = useMemo<EnabledPermission>(() => {
    const _path = tableId === 'event-scheduler' ? '/scheduler' : undefined
    const screenPerm = getScreen(_path)

    const permission = screenPerm?.permission[user?.permission_id as number]

    const defaultPermObj: EnabledPermission = {
      hasCreate: false,
      hasEdit: false,
      hasDelete: false,
      hasDetail: false,
      hasDetailButton: false,
      permission: permission,
      isFullAction: false
    }

    if (!permission) {
      return defaultPermObj
    }

    if (permission === Action.Full) {
      return {
        hasCreate: true,
        hasEdit: true,
        hasDelete: true,
        hasDetail: true,
        hasDetailButton: false,
        permission: permission,
        isFullAction: true
      }
    }

    const isFullAction = (permission as Action[]).includes(Action.Full)
    const hasCreate = (permission as Action[]).includes(Action.Create)
    const hasEdit = (permission as Action[]).includes(Action.Edit)
    const hasDelete = (permission as Action[]).includes(Action.Delete)
    const hasDetail = (permission as Action[]).includes(Action.Detail)
    const hasDetailButton = !hasDelete && !hasEdit && hasDetail

    return {
      hasCreate,
      hasEdit,
      hasDelete,
      hasDetail,
      hasDetailButton,
      permission,
      isFullAction
    }
  }, [getScreen, tableId, user?.permission_id])

  return enabledPermission
}
