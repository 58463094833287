import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { FormLabel, Input, InputTag, RichText, Select } from 'components/Form'
import { DatetimePickerSeparator } from 'components/Form/Input/DatetimePickerSeparator'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { format, isAfter } from 'date-fns'
import { language } from 'lib/constants'
import { useCategoryOptions } from 'lib/hooks'
import { useApiResource } from 'lib/hooks/useApiResource'
import { UnknownObj } from 'lib/types'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

type ContentForm = Omit<App.Models.Content, 'instructor' | 'group'> & {
  language?: string[]
}

const validateContent = yup.object({
  detail: yup.string().required('詳細は必須です。'),
  detail_en: yup.string().required('英語詳細は必須です。'),
  fd_category_1: yup.string().required('Freshdesk Category1を選択してください。'),
  fd_category_2: yup.string().required('Freshdesk Category2を選択してください。'),
  group_id: yup
    .number()
    .typeError('配信グループを選択してください。')
    .required('配信グループを選択してください。'),
  image_path: yup.string().required('アイキャッチ画像は必須です。'),
  title: yup.string().required('タイトルは必須です。').trim(),
  title_en: yup.string().required('英語タイトルは必須です。').trim(),
  video_url: yup.string().required('動画URLは必須です。').trim(),
  distribute_from_date: yup
    .date()
    .typeError('正しい日付形式を入力してください。')
    .required('公開期間は必須です。'),
  distribute_to_date: yup
    .date()
    .when('distribute_from_date', {
      is: (value: string) => !!value,
      then: yup
        .date()
        .typeError('正しい日付形式を入力してください。')
        .test({
          name: 'check end date',
          test: function checkEnd(end) {
            const { distribute_from_date } = this.parent
            if (isAfter(end as Date, distribute_from_date)) {
              return true
            }
            return false
          },
          message: '終了日が無効です。'
        })
    })
    .required('公開期間は必須です。')
})

const FormContent: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const isEdit = !!params?.id

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<ContentForm>({
    defaultValues: {
      id: Number(params?.id) || undefined,
      total_input_time: undefined,
      tags: [],
      fd_tags: [],
      language: []
    },
    resolver: yupResolver(validateContent)
  })

  const { data } = useQuery<ContentForm>([`contents/${params.id}`], {
    onSuccess: (data) => {
      const ignoreKeys = ['instructor', 'group']
      const language: string[] = []
      let name: keyof ContentForm
      for (name in data) {
        if (ignoreKeys.includes(name)) continue
        if (name === 'tags' || name === 'fd_tags') {
          data[name] && setValue(name, JSON.parse(data[name]))
          continue
        }
        if (name === 'is_english' || name === 'is_japanese') {
          if (data[name]) {
            language.push(name)
            setValue('language', language)
            continue
          }
        }

        setValue(name, data[name])
      }
    },
    enabled: isEdit
  })

  const { createOrUpdateApi } = useApiResource<ContentForm>('contents')

  const onSubmit: SubmitHandler<ContentForm> = async (values) => {
    const languages = ['is_english', 'is_japanese']
    const _lngObj = languages.reduce<UnknownObj>((acc, cur) => {
      acc[cur] = !!values.language?.includes(cur)
      return acc
    }, {})

    values['distribute_from_date'] = format(
      new Date(values['distribute_from_date']),
      'yyyy/MM/dd HH:mm'
    )
    values['distribute_to_date'] = format(
      new Date(values['distribute_to_date']),
      'yyyy/MM/dd HH:mm'
    )

    values = {
      ...values,
      ..._lngObj
    }

    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/content')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const categoryOptions = useCategoryOptions()

  return (
    <Page title={isEdit ? '編集フォーム' : 'コンテンツ新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Input fullWidth label="タイトル" name="title" control={control} />

          <ImageUploader
            control={control}
            layout="imageBox"
            name="image_path"
            label="アイキャッチ画像"
          />

          <RichText control={control} defaultValue={data?.detail} name="detail" label="詳細" />

          <Input fullWidth control={control} name="title_en" label="英語タイトル" />

          <RichText
            control={control}
            defaultValue={data?.detail_en}
            name="detail_en"
            label="英語詳細"
          />

          <Select control={control} name="group_id" label="配信グループ" query="groups" />

          <Select
            control={control}
            name="instructor_id"
            label="講師"
            query="instructors"
            labelValueKeys={['display_name', 'id']}
            addQueryFilter={{ with_trashed: isEdit ? 1 : 0 }}
          />

          <Input fullWidth control={control} name="video_url" label="動画URL" />

          <Select control={control} name="language" multiple label="対応言語" options={language} />

          <InputTag control={control} name="tags" label="ユーザー用動画専用カテゴリー" />

          <Select
            control={control}
            name="fd_category_1"
            label="Freshdesk Category1"
            options={categoryOptions.category1}
          />

          <Select
            control={control}
            name="fd_category_2"
            label="Freshdesk Category2"
            options={categoryOptions.category2}
          />

          <Select
            control={control}
            name="fd_category_event"
            label="Freshdesk Category Event"
            options={categoryOptions.event}
          />

          <InputTag control={control} name="fd_tags" label="Freshdesk Tags" />

          <Stack>
            <FormLabel error={!!errors.distribute_from_date || !!errors.distribute_to_date}>
              公開期間
            </FormLabel>
            <Stack direction="row" alignItems="flex-start" spacing={2}>
              <DatetimePickerSeparator control={control} name="distribute_from_date" />
              <Typography lineHeight="40px">〜</Typography>
              <DatetimePickerSeparator control={control} name="distribute_to_date" />
            </Stack>
          </Stack>

          <Input
            type={'number'}
            fullWidth
            label="稼働時間"
            name="total_input_time"
            control={control}
          />
        </Stack>

        <Grid container justifyContent="center">
          {isEdit ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export { FormContent }
