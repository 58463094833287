import { DatePicker } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import { ja } from 'date-fns/locale'
import { usePaginationQuery } from 'lib/hooks'
import { InstructorMonthlyReward } from 'lib/types'
import { formatISODate, yearMonthFormat } from 'lib/utils'
import { useMemo, useState } from 'react'
import { Column } from 'react-table'

const MonthlyReward = () => {
  const [date, setDate] = useState(new Date())

  const { paginationData } = usePaginationQuery<InstructorMonthlyReward>(
    'instructors/monthly-reward',
    {
      start_date: formatISODate(startOfMonth(subMonths(date, 2)), '/'),
      end_date: formatISODate(endOfMonth(date), '/')
    }
  )

  const columns = useMemo<Column<InstructorMonthlyReward>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: '講師名',
        accessor: 'display_name',
        width: 350
      },

      {
        Header: '請求先氏名',
        accessor: 'demand_name',
        width: 350
      },
      {
        Header: '会社名',
        accessor: 'demand_company_name',
        width: 350
      },
      {
        Header: '開催数',
        accessor: 'events_count'
      },
      {
        Header: 'お支払い金額',
        accessor: 'total_fee',
        Cell: ({ value }) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '円'
      }
    ],
    []
  )

  return (
    <Page title="イベント講師支払い報酬一覧">
      <Box mb={2}>
        <DatePicker
          value={date}
          onChange={(_date) => setDate(_date as Date)}
          openTo="month"
          views={['year', 'month']}
          renderInput={(params) => <TextField {...params} />}
          inputFormat={yearMonthFormat}
          disableMaskedInput
        />
        <Typography>
          {format(date, yearMonthFormat, { locale: ja }) +
            ' ~ ' +
            format(subMonths(date, 2), yearMonthFormat, { locale: ja })}
        </Typography>
      </Box>

      <ReactTable<InstructorMonthlyReward> columns={columns} {...paginationData} />
    </Page>
  )
}

export { MonthlyReward }
