import ClearIcon from '@mui/icons-material/Clear'
import { DatePicker } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { FormHelper, InputControl, StyledCheckedIcon, StyledIcon } from 'components/Form'
import { format, isValid } from 'date-fns'
import { formatISODate, safeParseISO } from 'lib/utils'
import { ChangeEvent, SyntheticEvent } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { FormEventValue } from '.'

type SelectOption = {
  label: string
  value: number | null
}

function EventDateTime({ control, name }: UseControllerProps<FormEventValue, 'event_dates'>) {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController<FormEventValue, 'event_dates'>({ control, name })

  const params = useParams()
  const isEdit = !!params?.id
  const { data: adminUser } = useQuery<App.Models.AdminUser[]>([
    'admin-users',
    { per_page: -1, with_trashed: isEdit ? 1 : 0 }
  ])

  const optionAdmin = (adminUser || [])?.map((admin) => ({ label: admin.name, value: admin.id }))

  const optionDisabled = (adminUser || [])
    ?.map(({ id, deleted_at }) => deleted_at && id)
    .filter(Boolean)

  const findOption = (index: number) =>
    optionAdmin.find((op) => op.value === value[index].admin_user_id)

  const addInput = () => {
    const temp = {
      start_date_time: formatISODate(new Date()) + ' 00:00',
      end_date_time: formatISODate(new Date()) + ' 00:00',
      max_booking_number: undefined,
      admin_user_id: undefined,
      is_rec: false
    }
    onChange([...(value as App.Models.EventDate[]), temp])
  }

  const deleteInput = (index: number) => {
    onChange((value as App.Models.EventDate[]).filter((_, idx) => idx !== index))
  }

  const handleChangeDate = (newValue: Date | null, index: number) => {
    const isValidDate = isValid(newValue as Date)
    if (!isValidDate) return
    const values = [...(value as App.Models.EventDate[])]
    const isValidStartDate = isValid(safeParseISO(values[index].start_date_time))

    values[index].start_date_time = isValidStartDate
      ? [
          formatISODate(newValue as Date),
          format(safeParseISO(values[index].start_date_time), 'HH:mm')
        ].join(' ')
      : format(newValue as Date, 'yyyy-MM-dd HH:mm')

    if (values[index].end_date_time) {
      values[index].end_date_time = [
        formatISODate(newValue as Date),
        format(safeParseISO(values[index].end_date_time), 'HH:mm')
      ].join(' ')
    }
    onChange(values)
  }

  const handleChangeTime = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: 'start' | 'end'
  ) => {
    const time = event.target.value
    const values = [...(value as App.Models.EventDate[])]
    const isValidStartDate = isValid(safeParseISO(values[index].start_date_time))
    const startTime = isValidStartDate ? formatISODate(values[index].start_date_time) : ''

    if (type === 'start') {
      values[index].start_date_time = [startTime, time].join(' ')
    } else {
      values[index].end_date_time = [startTime, time].join(' ')
    }
    onChange(values)
  }

  const handleChangeMBN = (newValue: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const values = [...(value as App.Models.EventDate[])]
    if (newValue.target.value.length > 0) {
      values[index].max_booking_number = Number(newValue.target.value)
    } else {
      values[index].max_booking_number = undefined as unknown as number
    }

    onChange(values)
  }

  const handleChangeAdmin = (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption,
    index: number
  ) => {
    const values = [...(value as App.Models.EventDate[])]
    if (newValue !== null) {
      values[index].admin_user_id = Number((newValue as SelectOption).value)
    } else {
      values[index].admin_user_id = undefined as unknown as number
    }

    onChange(values)
  }

  const handleChangeIsRec = (newValue: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const values = [...(value as App.Models.EventDate[])]
    values[index].is_rec = Boolean(newValue.target.checked)
    onChange(values)
  }

  return (
    <Box>
      {(value as App.Models.EventDate[])?.map((e, index) => {
        return (
          <Grid
            sx={{
              pt: 2,
              bgcolor: (theme) => theme.palette.grey[50],
              borderRadius: 2
            }}
            position="relative"
            key={index}
          >
            {value.length > 1 && (
              <ClearIcon
                onClick={() => deleteInput(index)}
                sx={{ position: 'absolute', top: 8, right: 16, cursor: 'pointer' }}
              />
            )}

            <Grid p={2} mb={2}>
              <Stack direction="row" spacing={2} mb={1}>
                <InputControl
                  label="イベント日時"
                  fieldError={
                    (error && (error as any)[index]?.start_date_time) ||
                    (error && (error as any)[index]?.end_date_time)
                  }
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <DatePicker
                      value={e.start_date_time ? safeParseISO(e.start_date_time) : new Date()}
                      onChange={(newValue) => handleChangeDate(newValue, index)}
                      mask="____-__-__"
                      inputFormat="yyyy-MM-dd"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{ minWidth: 180 }}
                          {...params}
                          error={error && !!(error as any)[index]?.start_date_time}
                        />
                      )}
                    />

                    <TextField
                      type="time"
                      value={
                        e.start_date_time
                          ? format(safeParseISO(e.start_date_time), 'HH:mm')
                          : '00:00'
                      }
                      onChange={(newValue) =>
                        e.start_date_time ? handleChangeTime(newValue, index, 'start') : null
                      }
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      sx={{ width: 150, minWidth: 150 }}
                      error={error && !!(error as any)[index]?.start_date_time}
                    />
                    <Typography>〜</Typography>

                    <TextField
                      type="time"
                      value={
                        e.end_date_time ? format(safeParseISO(e.end_date_time), 'HH:mm') : '00:00'
                      }
                      onChange={(newValue) => handleChangeTime(newValue, index, 'end')}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      sx={{ width: 150, minWidth: 150 }}
                      error={error && !!(error as any)[index]?.end_date_time}
                    />
                  </Stack>
                </InputControl>

                <InputControl
                  label="最大予約数"
                  fieldError={error && !!(error as any)[index]?.max_booking_number}
                >
                  <TextField
                    error={
                      error ? ((error as any)[index]?.max_booking_number ? true : false) : false
                    }
                    type="number"
                    value={e.max_booking_number || ''}
                    onChange={(newValue) =>
                      handleChangeMBN(newValue as React.ChangeEvent<HTMLInputElement>, index)
                    }
                    size="small"
                  />
                </InputControl>
              </Stack>

              <InputControl
                label="ホスト担当者"
                fullWidth
                fieldError={error && !!(error as any)[index]?.admin_user_id}
              >
                <Autocomplete
                  disablePortal
                  options={optionAdmin}
                  value={findOption(index) || null}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  isOptionEqualToValue={(options: SelectOption, value: SelectOption) =>
                    options.value === value.value
                  }
                  onChange={(event, newValue) =>
                    handleChangeAdmin(
                      event as SyntheticEvent<Element, Event>,
                      newValue as SelectOption,
                      index
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error && !!(error as any)[index]?.admin_user_id}
                    />
                  )}
                  getOptionDisabled={(option) => optionDisabled.includes(option.value)}
                  size="small"
                />
              </InputControl>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(newValue) =>
                      handleChangeIsRec(newValue as React.ChangeEvent<HTMLInputElement>, index)
                    }
                    checked={!!e.is_rec}
                    checkedIcon={<StyledCheckedIcon />}
                    icon={<StyledIcon />}
                  />
                }
                label="見逃し配信動画の録画"
                sx={{ '& .MuiTypography-root': { fontSize: 15, fontWeight: 500 } }}
              />
            </Grid>
          </Grid>
        )
      })}
      {!!error &&
        (error.type === 'unique' ? (
          <FormHelper error>{error.message}</FormHelper>
        ) : (
          <FormHelper error>イベントスケジュールは必須です。</FormHelper>
        ))}

      <Grid container justifyContent="center">
        <Button variant="contained" color="warning" onClick={addInput}>
          ＋日時を追加
        </Button>
      </Grid>
    </Box>
  )
}

export { EventDateTime }
