import { Stack, Typography } from '@mui/material'
import { FormDetail } from 'components/Form'
import { Page } from 'components/Layouts'
import { permissions } from 'lib/constants'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const AdminUserDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.AdminUser>([`admin-users/${params?.id}`])

  const permission = permissions.find((p) => p.value === data?.permission_id)

  return (
    <Page title="管理者詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="名前">
          <Typography>{permission && permission.label}</Typography>
        </FormDetail>

        <FormDetail label="名前">
          <Typography>{data?.name}</Typography>
        </FormDetail>

        <FormDetail label="メールアドレス">
          <Typography>{data?.email}</Typography>
        </FormDetail>

        <FormDetail label="パスワード">
          <Typography>********</Typography>
        </FormDetail>

        <FormDetail label="プロフィール画像">
          {data?.image_path && (
            <img
              src={data?.image_path}
              alt="logo"
              width={150}
              height={150}
              style={{ objectFit: 'cover' }}
            />
          )}
        </FormDetail>

        <FormDetail label="表示名">
          <Typography>{data?.display_name}</Typography>
        </FormDetail>

        <FormDetail label="表示名">
          <Typography>{data?.languages && JSON.parse(data?.languages).join(', ')}</Typography>
        </FormDetail>

        <FormDetail label="語学">
          <Typography>
            {data?.strong_fields && JSON.parse(data?.strong_fields).join(', ')}
          </Typography>
        </FormDetail>

        <FormDetail label="プロフィール">
          <Typography>{data?.profile}</Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { AdminUserDetail }
