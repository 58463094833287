import ClearIcon from '@mui/icons-material/Clear'
import { Autocomplete, Box, Button, Grid, Stack, TextField } from '@mui/material'
import { FormHelper, InputControl, InputStyled } from 'components/Form'
import { useCategoryOptions } from 'lib/hooks'
import { SyntheticEvent } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { MonthlyReport } from '.'
import { SelectOption } from './UserVoices'

function UserCases({ name, control }: UseControllerProps<MonthlyReport, 'use_cases'>) {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController<MonthlyReport, 'use_cases'>({ control, name })

  const categoryOptions = useCategoryOptions()
  const findOption1 = (index: number) =>
    categoryOptions.category1.find((op1) => op1.label === value?.[index].fd_ticket_category1)
  const findOption2 = (index: number) =>
    categoryOptions.category2.find((op2) => op2.label === value?.[index].fd_ticket_category2)

  const addInput = () => {
    const temp = {
      subject: '',
      fd_ticket_category1: undefined,
      fd_ticket_category2: undefined
    }
    onChange([...(value as App.Models.UseCase[]), temp])
  }

  const handleChangeSub = (newValue: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const values = [...(value as App.Models.UseCase[])]
    values[index].subject = newValue.target.value
    onChange(values)
  }

  const handleChangeFC1 = (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption,
    index: number
  ) => {
    const values = [...(value as App.Models.UseCase[])]
    if (newValue !== null) {
      values[index].fd_ticket_category1 = newValue.value as string
    } else {
      values[index].fd_ticket_category1 = ''
    }

    onChange(values)
  }

  const handleChangeFC2 = (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption,
    index: number
  ) => {
    const values = [...(value as App.Models.UseCase[])]
    if (newValue !== null) {
      values[index].fd_ticket_category2 = newValue.value as string
    } else {
      values[index].fd_ticket_category2 = ''
    }

    onChange(values)
  }

  const deleteInput = (index: number) => {
    onChange((value as App.Models.UseCase[]).filter((_, idx) => idx !== index))
  }

  return (
    <Box>
      {(value as App.Models.UseCase[])?.map((el, index) => (
        <Grid position="relative" key={index}>
          <ClearIcon
            onClick={() => deleteInput(index)}
            sx={{ position: 'absolute', top: 10, right: 17, cursor: 'pointer' }}
          />
          <Grid
            sx={{
              border: 1,
              padding: 2,
              marginBottom: 3,
              borderColor: (theme) => theme.palette.grey[200],
              borderRadius: 1
            }}
          >
            <InputControl
              label="Subject"
              sx={{ width: '50%', mb: 1 }}
              fieldError={error && (error as any)[index]?.subject}
            >
              <InputStyled
                value={el.subject || ''}
                onChange={(newValue) =>
                  handleChangeSub(newValue as React.ChangeEvent<HTMLInputElement>, index)
                }
              />
            </InputControl>

            <Stack direction="row" spacing={2}>
              <InputControl
                label="Freshdesk Category 1"
                fullWidth
                fieldError={error && (error as any)[index]?.fd_ticket_category1}
              >
                <Autocomplete
                  disablePortal
                  options={categoryOptions.category1 as SelectOption[]}
                  value={findOption1(index) || null}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  isOptionEqualToValue={(options: SelectOption, value: SelectOption) =>
                    options.value === value.value
                  }
                  onChange={(event, newValue) =>
                    handleChangeFC1(
                      event as SyntheticEvent<Element, Event>,
                      newValue as SelectOption,
                      index
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error && !!(error as any)[index]?.fd_ticket_category1}
                    />
                  )}
                  size="small"
                  noOptionsText="データなし"
                />
              </InputControl>

              <InputControl
                label="Freshdesk Category 2"
                fullWidth
                fieldError={error && (error as any)[index]?.fd_ticket_category2}
              >
                <Autocomplete
                  disablePortal
                  options={categoryOptions.category2 as SelectOption[]}
                  value={findOption2(index) || null}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  isOptionEqualToValue={(options: SelectOption, value: SelectOption) =>
                    options.value === value.value
                  }
                  onChange={(event, newValue) =>
                    handleChangeFC2(
                      event as SyntheticEvent<Element, Event>,
                      newValue as SelectOption,
                      index
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error && !!(error as any)[index]?.fd_ticket_category2}
                    />
                  )}
                  size="small"
                  noOptionsText="データなし"
                />
              </InputControl>
            </Stack>
          </Grid>
        </Grid>
      ))}

      {!!error && <FormHelper error>利用事例は必須です。</FormHelper>}

      <Grid container justifyContent="center">
        <Button variant="contained" color="warning" onClick={addInput}>
          +追加
        </Button>
      </Grid>
    </Box>
  )
}

export { UserCases }
