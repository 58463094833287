import { Checkbox, FormControlLabel, FormControlProps } from '@mui/material'
import { styled } from '@mui/system'
import { useController, UseControllerProps } from 'react-hook-form'
import { AddControlProps } from '..'

const StyledIcon = styled('span')(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.common.black}`,
  '.Mui-focusVisible &': {},
  'input:hover ~ &': {},
  'input:disabled ~ &': {}
}))

const StyledCheckedIcon = styled(StyledIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main
}))

export type CheckBoxProps<T> = UseControllerProps<T> &
  AddControlProps & {
    controlProps?: FormControlProps
  }

function CheckBox<T>({ name, label, control, controlProps }: CheckBoxProps<T>) {
  const {
    field: { onChange, value }
  } = useController({ name, control })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          checked={!!value}
          checkedIcon={<StyledCheckedIcon />}
          icon={<StyledIcon />}
        />
      }
      label={label as string}
      sx={{ '& .MuiTypography-root': { fontSize: 15, fontWeight: 500 }, ...controlProps?.sx }}
    />
  )
}

export { CheckBox, StyledIcon, StyledCheckedIcon }
