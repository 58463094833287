import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { Input, Select } from 'components/Form'
import { Page } from 'components/Layouts'
import { roleOptions, statusOptions } from 'lib/constants'
import { useApiResource } from 'lib/hooks'
import { Company } from 'lib/types'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

type UserValueForm = Omit<App.Models.User, 'is_manager'> & {
  is_manager: number | null
}

export type PrefCodeType = {
  value: number
  label: string
}

const FormUser: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const isEdit = !!params?.id

  const validateUser = yup.object({
    last_name: yup.string().required().label('姓').trim(),
    first_name: yup.string().required().label('名').trim(),
    email: yup
      .string()
      .required()
      .label('メールアドレス')
      .email('メールアドレスが正しい形式ではありません。'),
    password: !isEdit
      ? yup
          .string()
          .required()
          .label('パスワード')
          .min(8, 'パスワードは8文字以上で入力して下さい。')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/,
            'パスワードは半角英数字を混ぜたものを指定してください。'
          )
      : yup.string().when({
          is: (value: string) => !!value,
          then: yup
            .string()
            .min(8, 'パスワードは8文字以上で入力して下さい。')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/,
              'パスワードは半角英数字を混ぜたものを指定してください。'
            )
        }),
    company_id_1: yup.string().required('Company 1を選択してください。'),
    is_active: yup.string().required('ステータスを選択してください。')
  })

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useForm<UserValueForm>({
    defaultValues: {
      office: '',
      id: Number(params?.id) || undefined,
      last_name: '',
      first_name: '',
      email: '',
      company_id_1: undefined,
      company_id_2: undefined,
      is_active: undefined,
      is_manager: 0
    },
    resolver: yupResolver(validateUser)
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const company_id_1 = watch('company_id_1')

  useQuery<UserValueForm>([`users/${params.id}`], {
    onSuccess: (data) => {
      for (const name in data) {
        setValue(name as keyof UserValueForm, data[name as keyof UserValueForm])
      }
    },
    enabled: isEdit
  })

  const { data: prefCode } = useQuery<PrefCodeType[]>([`pref-option?lng=ja`])

  const { createOrUpdateApi } = useApiResource<UserValueForm>('users')

  const onSubmit: SubmitHandler<UserValueForm> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/user')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title={isEdit ? 'ユーザー情報変更' : 'ユーザー情報登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Typography variant="h5">基本情報</Typography>

          <Select
            name="is_manager"
            label="ロール"
            fullWidth
            control={control}
            options={roleOptions}
            defaultValue={0}
          />

          <Select
            name="office"
            label="勤務地"
            fullWidth
            control={control}
            options={prefCode}
            defaultValue={0}
          />

          <Stack direction="row" spacing={2}>
            <Input fullWidth label="姓" name="last_name" control={control} />
            <Input fullWidth label="名" name="first_name" control={control} />
          </Stack>

          <Input fullWidth label="メールアドレス" name="email" control={control} />

          <Stack>
            <Input
              fullWidth
              label="パスワード"
              name="password"
              type="password"
              autoComplete="new-password"
              control={control}
            />
            <Typography variant="caption" color="grey.A400">
              パスワードは英大文字、英小文字、数字、記号を混ぜて8文字以上で指定してください。
            </Typography>
          </Stack>

          <Select<Company>
            name="company_id_1"
            label="Company 1"
            fullWidth
            control={control}
            query="/companies"
          />

          <Select<Company>
            name="company_id_2"
            label="Company 2"
            fullWidth
            control={control}
            query={`/companies?parent_company_id_equal=${company_id_1}`}
          />

          <Select
            name="is_active"
            label="ステータス"
            fullWidth
            control={control}
            options={statusOptions}
          />
        </Stack>

        <Grid container justifyContent="center">
          {isEdit ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export { FormUser }
