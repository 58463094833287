import { AppBar, Container, Stack, Toolbar, Typography } from '@mui/material'
import { AvatarWithSize } from 'components/Avatar'
import { useAuth } from 'lib/hooks'

const Header = () => {
  const { user } = useAuth()

  return (
    <>
      <AppBar
        color="inherit"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[50]}` }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ display: '-webkit-inline-box', width: '100%' }}>
            <Stack
              direction="row"
              width="100%"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <AvatarWithSize size={50} src={user?.image_path as string} />
              <Typography variant="subtitle1">{user?.display_name}</Typography>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  )
}

export { Header }
