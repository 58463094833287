import { Button } from '@mui/material'
import { CellContainer, ReactTable } from 'components/ReactTable'
import format from 'date-fns/format'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

type EventScheduleType = App.Models.Event &
  App.Models.EventDate & {
    admin_name: string
  }

const EventSchedule: React.VFC = () => {
  const { paginationData, refetch } = usePaginationQuery<EventScheduleType>('events-calendar')
  const dialog = useDialog()
  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const columns = useMemo<Column<EventScheduleType>[]>(
    () => [
      {
        Header: '開催日時',
        accessor: 'start_date_time',
        Cell: ({ value }) => format(new Date(value), 'yyyy/MM/dd H:mm')
      },
      {
        Header: 'タイトル',
        accessor: 'title',
        width: 300
      },
      {
        Header: '配信グループ',
        accessor: 'group.name' as 'group',
        width: 150
      },
      {
        Header: '講師1',
        accessor: 'instructors[0].display_name' as 'instructors',
        width: 150
      },
      {
        Header: '講師2',
        accessor: 'instructors[1].display_name' as 'instructors',
        width: 150
      },
      {
        Header: 'ホスト',
        accessor: 'admin_name',
        width: 100
      },
      {
        Header: '予約数',
        accessor: 'max_booking_number',
        width: 100
      },
      {
        Header: ' ',
        Cell: ({ row }: CellProps<App.Models.Event>) => (
          <CellContainer>
            <Button
              variant="contained"
              size="small"
              component={Link}
              to={`/event-subscriber/${row.original.id}`}
            >
              参加者一覧
            </Button>
          </CellContainer>
        ),
        width: 100,
        mergeWithAction: true
      }
    ],
    []
  )

  const { deleteApi } = useApiResource('events')

  const handleDelete = useCallback(
    async ({ row }: CellProps<EventScheduleType>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<EventScheduleType>) => {
    navigate('/event/detail/' + original.id)
  }

  return (
    <ReactTable<EventScheduleType>
      columns={columns}
      defaultActionEdit
      onActionDelete={handleDelete}
      onRowClick={onRowClick}
      {...paginationData}
      id="event-scheduler"
      disabledRowClick={!isFullAction}
      actionConfig={{
        columnWidth: 60
      }}
    />
  )
}

export { EventSchedule }
