import { useEffect, useRef, useState } from 'react'

const usePreserveState = <T = unknown>(
  bindValue: T,
  onChange?: (value: T) => void,
  bypassed?: boolean
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(bindValue)
  const ref = useRef({ effectInit: false })

  useEffect(() => {
    setValue((val) => (val !== bindValue ? bindValue : val))
  }, [bindValue])

  useEffect(() => {
    if (ref.current.effectInit && !bypassed && onChange) onChange(value)
  }, [bypassed, onChange, value])

  useEffect(() => {
    ref.current.effectInit = true
  }, [])

  return [bypassed ? bindValue : value, setValue]
}

export { usePreserveState }
