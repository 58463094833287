import { Stack, SvgIcon, Tooltip, Typography } from '@mui/material'
import { ReactComponent as Delete } from 'assets/svg/delete.svg'
import { ReactComponent as Edit } from 'assets/svg/edit.svg'
import IButton from 'components/IButton'
import { Page } from 'components/Layouts'
import { CellContainer, ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { format } from 'date-fns'
import { fdStatus } from 'lib/constants'
import { useApiResource, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { sliceString } from 'lib/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

const CustomerReport: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.FdTicket>('customer-reports')

  const [fbTicketId, setFbTicketId] = useState<string | null>()

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)

    if (queryParameters.get('id')) {
      setFbTicketId(queryParameters.get('id'))

      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('fd_ticket_id').value = queryParameters.get('id')
      }, 2000)

      handleChangeParams({ fd_ticket_id_like: queryParameters.get('id') })
    }
  }, [handleChangeParams])

  const { deleteApi } = useApiResource<App.Models.FdTicket>('customer-reports')

  const dialog = useDialog()
  const navigate = useNavigate()

  const handleDelete = useCallback(
    async (row: Row<App.Models.FdTicket>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(Number(row?.original?.customer_report?.id))
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = useCallback(
    ({ original }: Row<App.Models.FdTicket>) => {
      const isEdit = !!original.customer_report
      const action = isEdit ? 'edit' : 'create'
      navigate(`/customer-report/${action}/${original.id}`)
    },
    [navigate]
  )

  const columns = useMemo<Column<App.Models.FdTicket>[]>(
    () => [
      {
        Header: 'ID',
        id: 'fd_ticket_id',
        accessor: 'fd_ticket_id'
      },
      {
        Header: 'Freshdesk チケット タイトル',
        accessor: 'subject',
        width: 330
      },
      {
        Header: '会社名',
        accessor: 'company.name' as 'company',
        searchType: 'select',
        regex: 'none',
        queryKey: 'company_id',
        additionSearchProps: {
          query: 'companies',
          labelValueKeys: ['name', 'id']
        },
        width: 180
      },
      {
        Header: 'ステータス',
        accessor: 'status',
        Cell: ({ value }) => (Number(value) === fdStatus.CLOSED ? 'Closed' : 'Open'),
        searchType: 'select',
        regex: 'none',
        additionSearchProps: {
          options: [
            {
              value: 1,
              label: 'Closed'
            },
            {
              value: 2,
              label: 'Open'
            }
          ]
        },
        width: 80
      },
      {
        Header: '作成日',
        accessor: 'fd_ticket_created_at',
        search: false,
        Cell: ({ value }) => format(new Date(value as string), 'yyyy/MM/dd'),
        width: 80
      },
      {
        Header: 'URL',
        accessor: 'customer_report.url' as 'customer_report',
        regex: 'none',
        queryKey: 'customer_report_url',
        Cell: ({ value }: { value: string }) => (
          <CellContainer sx={{ cursor: 'pointer' }}>
            <CopyToClipboard
              text={`<a target="_blank" href="${value}" style="font-size: 13px" >${value}</a>`}
              options={{ format: 'text/html' }}
            >
              <Tooltip
                title={
                  <Stack>
                    <Typography variant="caption">{value}</Typography>
                    <Typography fontSize={11}>Click to copy!</Typography>
                  </Stack>
                }
              >
                <a
                  href={value || ''}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                >
                  <Typography variant="body2" style={{ fontSize: '13px', color: '#000000DE' }}>
                    {sliceString(value, 59)}
                  </Typography>
                </a>
              </Tooltip>
            </CopyToClipboard>
          </CellContainer>
        ),
        width: 300
      },
      {
        Header: ' ',
        search: false,
        Cell: ({ row }: CellProps<App.Models.FdTicket>) => (
          <CellContainer direction="row" spacing={1}>
            <IButton onClick={() => onRowClick(row)}>
              <SvgIcon viewBox="0 0 28 28" component={Edit} />
            </IButton>
            {row?.original?.customer_report && (
              <IButton onClick={() => handleDelete(row)}>
                <SvgIcon viewBox="0 0 28 28" component={Delete} />
              </IButton>
            )}
          </CellContainer>
        ),
        sticky: 'right',
        width: 60
      }
    ],
    [handleDelete, onRowClick, fbTicketId]
  )

  return (
    <Page title="C向けレポート一覧">
      <TopHeader<App.Models.FdTicket>
        path="/customer-report/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
        hideCreateBtn
      />

      <ReactTable<App.Models.FdTicket> columns={columns} {...paginationData} />
    </Page>
  )
}

export { CustomerReport }
