import { Stack, Typography } from '@mui/material'
import PDF from 'assets/images/pdf.png'
import { FormDetail, Tag } from 'components/Form'
import { Page } from 'components/Layouts'
import { DrawerHeader } from 'components/Layouts/Drawer'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FormCompanyValues } from './FormCompany'

export function getFilenameFromUrl(url: string) {
  if (url) {
    const pathname = new URL(url).pathname
    const index = pathname.lastIndexOf('/')
    return -1 !== index ? pathname.substring(index + 1) : pathname
  }
}

const CompanyDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<FormCompanyValues>([`companies/${params?.id}`])

  const url_privacy = data?.privacy_policy_path
  const url_service = data?.service_policy_path

  const handlePDF = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Page title="企業詳細">
      <Stack spacing={2} ml={10}>
        <Typography variant="h5">基本情報</Typography>

        <FormDetail label="企業名（Freshdeskデータ連携）">
          <Typography>{data?.name}</Typography>
        </FormDetail>

        <FormDetail label="Freshdesk Company Id">
          <Typography>{data?.res_fd_company_id}</Typography>
        </FormDetail>

        <FormDetail label="住所">
          <Typography>{data?.address}</Typography>
        </FormDetail>

        <FormDetail label="ユーザー登録可能ドメイン">
          <Stack direction="row" spacing={1}>
            {data?.domains?.map((domain) => (
              <Tag label={domain.domain} key={domain.domain} />
            ))}
          </Stack>
        </FormDetail>

        <FormDetail label="グループ">
          <Stack direction="row" spacing={1}>
            {data?.groups?.map((group) => (
              <Tag label={group.name} key={group.id} />
            ))}
          </Stack>
        </FormDetail>

        <FormDetail label="招待コード">
          <Typography>{data?.invitation_code}</Typography>
        </FormDetail>

        <FormDetail label="親会社（Freshdeskデータ連携）">
          <Typography>{data?.parent_company?.name}</Typography>
        </FormDetail>

        <FormDetail label="企業ロゴ（上書きの場合アップロード）">
          <img src={data?.logo_path} alt="logo" width={150} height={150} />
        </FormDetail>

        <FormDetail label="お問い合わせ先">
          {data?.contact_detail && (
            <div dangerouslySetInnerHTML={{ __html: data?.contact_detail || '' }} />
          )}
        </FormDetail>

        <FormDetail label="Privacy Policy（上書きの場合アップデート）">
          <DrawerHeader onClick={() => handlePDF(url_privacy as string)}>
            <img src={PDF} alt="pdf" />
          </DrawerHeader>
        </FormDetail>

        <FormDetail label="Terms of  Service（上書きの場合アップデート）">
          <DrawerHeader onClick={() => handlePDF(url_service as string)}>
            <img src={PDF} alt="pdf" />
          </DrawerHeader>
        </FormDetail>

        <FormDetail label="FAQ（上書きの場合アップデート）">
          {data?.faq && <div dangerouslySetInnerHTML={{ __html: data?.faq || '' }} />}
        </FormDetail>

        <FormDetail label="FAQ（英語）">
          {data?.faq_en && <div dangerouslySetInnerHTML={{ __html: data?.faq_en || '' }} />}
        </FormDetail>

        <Typography variant="h5">Home表示コンテンツ項目</Typography>
        {data?.home_contents?.map((c) => (
          <Typography key={c.id}>{c.name}</Typography>
        ))}

        <Typography variant="h5">月次レポート</Typography>

        <FormDetail label="月次レポートタイトル">
          <Typography>{data?.report_title}</Typography>
        </FormDetail>

        <Typography variant="h5">相談件数アラート</Typography>

        <FormDetail label="">
          <Typography>アラート：{data?.show_ticket_warning_limit ? 'On' : 'Off'}</Typography>
        </FormDetail>

        <FormDetail label="">
          <Typography>件数 : {data?.ticket_warning_limit} 以上</Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { CompanyDetail }
