import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Container, Link, Stack, Typography } from '@mui/material'
import Logo from 'assets/images/logo.png'
import { Input } from 'components/Form'
import { resetPasswordApi } from 'lib/api'
import { handleValidateErrors } from 'lib/utils'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

export type ForgotPasswordType = {
  email: string
}

export type ForgotPasswordError = {
  message: string
}

const forgotValidate = yup.object({
  email: yup.string().email().required().label('メールアドレス')
})

const ResetPassword: React.VFC = () => {
  const [error, setError] = useState('')

  const {
    control,
    handleSubmit,
    setError: setFormError,
    formState: { isSubmitting }
  } = useForm<ForgotPasswordType>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(forgotValidate)
  })

  const onSubmit: SubmitHandler<ForgotPasswordType> = async (values) => {
    try {
      await resetPasswordApi(values)
      toast.success('メールを確認してください。')
    } catch (error) {
      if (error.message) {
        setError((error as ForgotPasswordError).message)
      } else if (error.errors) {
        handleValidateErrors(error, setFormError)
      }
    }
  }

  return (
    <Container maxWidth="xs">
      <Stack alignItems="center">
        <img src={Logo} alt="logo" />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{
            mt: 1,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}

          <Typography
            variant="h4"
            mb={4}
            color={(theme) => ({ xs: theme.palette.common.white, md: 'unset' })}
          >
            パスワードリセット
          </Typography>

          <Input
            fullWidth
            label="メールアドレス"
            name="email"
            autoComplete="email"
            controlProps={{
              sx: {
                mb: 2
              }
            }}
            control={control}
          />

          <Stack spacing={2} alignItems="center">
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              メールを送信する
            </LoadingButton>

            <Link
              display="flex"
              alignSelf="center"
              color="grey.500"
              variant="caption"
              href="/login"
            >
              戻る
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export { ResetPassword }
