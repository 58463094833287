import { Stack, Typography } from '@mui/material'
import { FormDetail } from 'components/Form'
import { Page } from 'components/Layouts'
import { roleOptions, statusOptions } from 'lib/constants'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { PrefCodeType } from './FormUser'

const UserDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.User>([`users/${params?.id}`])

  const { data: prefCode } = useQuery<PrefCodeType[]>([`pref-option?lng=ja`])

  return (
    <Page title="ユーザー詳細">
      <Typography variant="h5" mb={2} fontWeight="bold">
        基本情報
      </Typography>
      <Stack spacing={2} ml={10}>
        <FormDetail label="ロール">
          <Typography>
            {roleOptions.find((role) => role.value === Number(data?.is_manager))?.label}
          </Typography>
        </FormDetail>

        <Stack direction="row" spacing={8}>
          <FormDetail label="姓">
            <Typography>{data?.last_name}</Typography>
          </FormDetail>

          <FormDetail label="名">
            <Typography>{data?.first_name}</Typography>
          </FormDetail>
        </Stack>

        <FormDetail label="メールアドレス">
          <Typography>{data?.email}</Typography>
        </FormDetail>

        <FormDetail label="パスワード">
          <Typography>********</Typography>
        </FormDetail>

        <FormDetail label="勤務地">
          <Typography>{prefCode?.find((p) => p.value === (data?.office || 0))?.label}</Typography>
        </FormDetail>

        <FormDetail label="Company 1">
          <Typography>{data?.parent_company && data?.parent_company?.name}</Typography>
        </FormDetail>

        <FormDetail label="Company 2">
          <Typography>{data?.child_company && data?.child_company?.name}</Typography>
        </FormDetail>

        <FormDetail label="ステータス">
          <Typography>
            {(data?.is_active || (data?.is_active as unknown as number) === 0) &&
              statusOptions.find((s) => s.value === Number(data?.is_active))?.label}
          </Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { UserDetail }
