import { Portal } from '@mui/base'
import SearchIcon from '@mui/icons-material/Search'
import { Box, BoxProps, Button, Collapse, Grid, Stack } from '@mui/material'
import { Input, InputProps, Select, SelectProps } from 'components/Form'
import { UnknownObj } from 'lib/types'
import { snakeToCamel } from 'lib/utils'
import debounce from 'lodash/debounce'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Column } from 'react-table'

export type FilterBarColumn = {
  regex?: '_like' | '_equal' | '_between' | '_notEqual' | '_isnull' | 'has_' | 'none'
  queryKey?: string
  searchType?: 'select' | 'text' | 'radio'
  additionSearchProps?: Partial<SelectProps<UnknownObj, UnknownObj> | InputProps<UnknownObj>>
  search?: boolean
  id?: string
}

export type FilterBarLayout = 'inline' | 'collapse'

export type FilterBarProps<T extends UnknownObj> = BoxProps<
  'div',
  {
    searchColumns: Column<T>[]
    handleChangeParams: (params: UnknownObj) => void
    watchMode?: boolean
    layout?: FilterBarLayout
    searchContainer?: HTMLElement
  }
>

const convertRelationship = (accessor = '') => {
  const arr = accessor.split('.')
  return [snakeToCamel(arr[0]), arr[1]].join(':')
}

const convertParamKey = (accessor = ''): string => {
  // Replace all array key
  const _accessor = accessor.replace(/\[[^\]]*\]/g, '')
  const name = _accessor.includes('.') ? convertRelationship(_accessor) : accessor
  return name
}

function FilterBarComponent<T extends UnknownObj>({
  handleChangeParams,
  searchColumns,
  watchMode,
  layout = 'inline',
  searchContainer,
  ...props
}: FilterBarProps<T>) {
  const { control, handleSubmit, watch } = useForm<UnknownObj>({
    defaultValues: searchColumns.reduce((df, cur) => {
      ;(df as UnknownObj)[convertParamKey(cur['accessor'] as string)] = ''
      return df
    }, {})
  })

  const getSearchObj = useCallback(
    (key: string) => {
      return searchColumns.find((el) => convertParamKey(el.accessor as string) === key)
    },
    [searchColumns]
  )

  const getQueryParams = useCallback(
    (values: UnknownObj) => {
      const params = Object.keys(values).reduce<UnknownObj>((_params, cur) => {
        if (values[cur]) {
          const searchObj = getSearchObj(cur)
          const _regex = searchObj?.regex || '_like'
          const regex = _regex === 'none' ? '' : _regex
          const queryKey = searchObj?.queryKey || cur
          _params[`${queryKey}${regex}`] = values[cur]
        }
        return _params
      }, {})

      return params
    },
    [getSearchObj]
  )

  const debounceChange = useMemo(
    () => debounce((params) => handleChangeParams(params), 300),
    [handleChangeParams]
  )

  useEffect(() => {
    if (!watchMode) return
    const subscription = watch((value, { name }) => {
      const searchObj = getSearchObj(name as string)
      const hasDebounce = searchObj?.searchType === 'text' || searchObj?.searchType === undefined
      const params = getQueryParams(value)
      if (hasDebounce) {
        debounceChange(params)
      } else {
        handleChangeParams(params)
      }
    })

    return () => subscription.unsubscribe()
  }, [debounceChange, getQueryParams, getSearchObj, handleChangeParams, watch, watchMode])

  const onSubmit: SubmitHandler<UnknownObj> = (values) => {
    const params = getQueryParams(values)
    handleChangeParams(params)
  }

  const [open, setOpen] = useState(true)
  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const SearchLayout: React.FC = useCallback(
    ({ children }) =>
      layout === 'inline' ? (
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          {children}
          {!watchMode && (
            <Button sx={{ height: 32 }} onClick={handleSubmit(onSubmit)} variant="contained">
              検索
            </Button>
          )}
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 32 }}
            size="small"
            onClick={handleClick}
          >
            <SearchIcon sx={{ fill: 'black' }} />
          </Button>

          <Portal container={searchContainer}>
            <Collapse in={open}>
              <Grid spacing={2} mb={2} container>
                {children}
              </Grid>
              {!watchMode && (
                <Button
                  type="submit"
                  sx={{ height: 32 }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                >
                  検索
                </Button>
              )}
            </Collapse>
          </Portal>
        </Stack>
      ),
    [handleClick, handleSubmit, layout, onSubmit, open, searchContainer, watchMode]
  )

  const SearchItemContainer: React.FC = useCallback(
    ({ children }) =>
      layout == 'inline' ? (
        <>{children}</>
      ) : (
        <Grid item md={3} sm={4}>
          {children}
        </Grid>
      ),
    [layout]
  )
  const isInline = layout === 'inline'

  return (
    <Box
      component="form"
      width="100%"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      {...props}
    >
      <SearchLayout>
        {searchColumns.map(
          (
            { accessor = '', Header, searchType, additionSearchProps, search = true, id },
            index
          ) => {
            const name = convertParamKey(accessor as string)
            const controlProps = {
              name: name,
              label: Header as string,
              control,
              key: index,
              id: id
            }

            if (!search) return null

            switch (searchType) {
              case 'select':
                return (
                  <SearchItemContainer key={index}>
                    <Select
                      fullWidth={!isInline}
                      size="small"
                      {...controlProps}
                      {...additionSearchProps}
                    />
                  </SearchItemContainer>
                )
              default:
                return (
                  <SearchItemContainer key={index}>
                    <Input
                      fullWidth={!isInline}
                      size="small"
                      {...controlProps}
                      {...additionSearchProps}
                    />
                  </SearchItemContainer>
                )
            }
          }
        )}
      </SearchLayout>
    </Box>
  )
}

const FilterBar = memo(FilterBarComponent) as typeof FilterBarComponent

export { FilterBar }
