import {
  styled,
  SxProps,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead
} from '@mui/material'
import { Cell, Row } from 'components/ReactTable'
import { Column, useTable } from 'react-table'

type TableChartProps = {
  columns: Array<Column>
  data: []
  id?: string
  containerSx?: SxProps
}

const CellList = styled(Cell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[900]
  },
  whiteSpace: 'nowrap'
}))

const RowList = styled(Row)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}))

const StyledTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  '& td, th': {
    border: `1px solid ${theme.palette.grey[200]}`
  }
}))

function TableChart({ columns, data, id, containerSx }: TableChartProps) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <TableContainer sx={{ maxHeight: 376, ...containerSx }}>
      <StyledTable size="small" sx={{ minWidth: 'fit-content' }} stickyHeader {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Row key={key} {...headerGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...cellHeaderProps } = column.getHeaderProps()
                  if (id === 'list') {
                    return (
                      <CellList {...cellHeaderProps} key={key}>
                        {column.render('Header')}
                      </CellList>
                    )
                  } else {
                    return (
                      <Cell {...cellHeaderProps} sx={{ whiteSpace: 'nowrap' }} key={key}>
                        {column.render('Header')}
                      </Cell>
                    )
                  }
                })}
              </Row>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...getRowProps } = row.getRowProps()
            if (id === 'list') {
              return (
                <RowList key={key} {...getRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...getCellProps } = cell.getCellProps()
                    return (
                      <CellList {...getCellProps} key={key}>
                        {cell.render('Cell')}
                      </CellList>
                    )
                  })}
                </RowList>
              )
            } else {
              return (
                <Row key={key} {...getRowProps}>
                  {row.cells.map((cell, i) => {
                    const { key, ...getCellProps } = cell.getCellProps()
                    return (
                      <Cell
                        align={i === 0 ? 'left' : 'center'}
                        sx={{ whiteSpace: 'nowrap' }}
                        {...getCellProps}
                        key={key}
                      >
                        {cell.render('Cell')}
                      </Cell>
                    )
                  })}
                </Row>
              )
            }
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export { TableChart }
