import AddIcon from '@mui/icons-material/Add'
import { Button, Stack, useTheme } from '@mui/material'
import { useCheckPerm } from 'lib/hooks'
import { UnknownObj } from 'lib/types'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FilterBar, FilterBarProps } from './ReactTable'

type HeaderProps<T extends UnknownObj> = FilterBarProps<T> & {
  path: string
  hideCreateBtn?: boolean
  filterBarLayout?: 'inline' | 'collapse'
}

function TopHeader<T extends UnknownObj>({
  path,
  hideCreateBtn = false,
  searchColumns,
  handleChangeParams,
  filterBarLayout = 'inline'
}: HeaderProps<T>) {
  const { hasCreate: hasCreatePerm } = useCheckPerm()
  const navigate = useNavigate()

  const handleCreate = () => {
    navigate(path)
  }

  const hasCreate = hasCreatePerm && !hideCreateBtn

  const theme = useTheme()
  const gridContainer = useRef<HTMLElement>()
  const isInline = filterBarLayout === 'inline'

  return (
    <Stack ref={gridContainer} mb={isInline ? 0 : 2}>
      <Stack
        direction={
          isInline ? { xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'row' } : 'row'
        }
        spacing={{ md: 2, lg: 4 }}
        justifyContent={hasCreate ? 'space-between' : 'flex-end'}
        alignItems="flex-end"
        mb={isInline ? 4 : 2}
      >
        {hasCreate && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            startIcon={<AddIcon sx={{ fill: theme.palette.common.black }} />}
            onClick={handleCreate}
            sx={{ width: 120 }}
          >
            新規追加
          </Button>
        )}

        <FilterBar
          searchColumns={searchColumns}
          searchContainer={gridContainer.current}
          layout={filterBarLayout}
          handleChangeParams={handleChangeParams}
        />
      </Stack>
    </Stack>
  )
}

export { TopHeader }
