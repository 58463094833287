import { LoadingButton } from '@mui/lab'
import { Container, Stack } from '@mui/material'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { updateKeyVisual } from 'lib/api'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

export type KeyVisualFormValues = Omit<App.Models.KeyVisual, 'deleted_at'>

const KeyVisual = () => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting }
  } = useForm<App.Models.KeyVisual>({
    defaultValues: {
      image_path: ''
    }
  })

  useQuery<App.Models.KeyVisual>(['key-visual'], {
    onSuccess(data) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key as keyof App.Models.KeyVisual]
          setValue(key as keyof App.Models.KeyVisual, element)
        }
      }
    }
  })

  const onSubmit: SubmitHandler<App.Models.KeyVisual> = async (values) => {
    try {
      await updateKeyVisual(values)
      toast.success('更新しました。')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error('更新に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title="キービジュアル変更">
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <ImageUploader control={control} name="image_path" layout="imageBox" label="現在の画像" />
        </Stack>

        <Stack direction="row" justifyContent="center">
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            更新する
          </LoadingButton>
        </Stack>
      </Container>
    </Page>
  )
}

export { KeyVisual }
