import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Stack } from '@mui/material'
import { Input, RichText, Select } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { statusNew } from 'lib/constants'
import { useApiResource, useModalState } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import NewPreviewModal from './NewPreviewModal'

export type NewFormValues = Omit<App.Models.News, 'groups'> & {
  groups: number[]
  image_path: ''
}

const validateNews = yup.object({
  title: yup.string().required('タイトルは必須です').trim(),
  image_path: yup.string().required('アイキャッチ画像を選択してください。'),
  detail: yup.string().required('詳細は必須です。'),
  title_en: yup.string().required('英語タイトルは必須です。').trim(),
  detail_en: yup.string().required('英語詳細は必須です。'),
  status: yup.number().typeError('公開を選択してください。').required(' 公開を選択してください。')
})

const FormNew: React.VFC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const isEdit = !!params?.id

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useForm<NewFormValues>({
    defaultValues: {
      id: Number(params?.id),
      title: '',
      title_en: '',
      detail: '',
      detail_en: '',
      groups: [],
      image_path: '',
      status: undefined
    },
    resolver: yupResolver(validateNews)
  })

  const { data } = useQuery<NewFormValues>([`news/${params.id}`], {
    onSuccess: (data) => {
      for (const name in data) {
        setValue(name as keyof NewFormValues, data[name as keyof NewFormValues])
        setValue(
          'groups',
          (data.groups as unknown as App.Models.Group[]).map((el) => el.id)
        )
      }
    },
    enabled: isEdit
  })

  const { createOrUpdateApi } = useApiResource<NewFormValues>('news')

  const onSubmit: SubmitHandler<NewFormValues> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/news')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const { isOpen, onClose, onOpen } = useModalState()

  return (
    <Page title={isEdit ? 'ニュース編集' : 'ニュース新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Input fullWidth name="title" label="タイトル" control={control} />

          <ImageUploader
            control={control}
            layout="imageBox"
            name="image_path"
            label="アイキャッチ画像"
          />

          <RichText name="detail" label="詳細" control={control} defaultValue={data?.detail} />

          <Input fullWidth name="title_en" label="英語タイトル" control={control} />

          <RichText
            name="detail_en"
            label="英語詳細"
            control={control}
            defaultValue={data?.detail_en}
          />

          <Select
            fullWidth
            name="groups"
            label="配信グループ"
            control={control}
            multiple
            query="groups"
          />

          <Select fullWidth name="status" label="公開" control={control} options={statusNew} />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          {params?.id ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}

          <Button variant="outlined" color="inherit" onClick={onOpen}>
            プレビュー
          </Button>
        </Stack>
      </Container>
      <NewPreviewModal open={isOpen} onClose={onClose} {...watch()} />
    </Page>
  )
}

export { FormNew }
