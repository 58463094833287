import { Box, Container, Stack, Typography } from '@mui/material'
import { DialogBase } from 'components/Dialog'
import { ModalProps } from 'lib/types'
import { formatISODate, safeParseISO } from 'lib/utils'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChartReport, UseCaseTable, UserVoiceType, UseVoiceReport } from './components'
import { MonthlyReport } from './FormMonthlyReport'

export type ReportPreviewModal = ModalProps<MonthlyReport>

const ReportPreviewModal: React.VFC<ReportPreviewModal> = ({ open, onClose, date, ...data }) => {
  const [searchParams] = useSearchParams()
  const monthYear = searchParams.get('month_year')
  const date2 = monthYear ? safeParseISO(monthYear) : formatISODate(new Date(), '/')

  const [displayFlg, setDisplayFlg] = useState<boolean | null>(true)

  const handleDisplay = (currentTabNumber: number) => {
    // only display when active tab is "総括レポート"
    setDisplayFlg(currentTabNumber === 1)
  }

  return (
    <DialogBase open={open} onClose={onClose}>
      <Container disableGutters maxWidth="md">
        <Stack mb={4}>
          <Typography variant="h6" ml={1}>
            総括（利用傾向など）
          </Typography>

          <Box
            mb={4}
            component="div"
            dangerouslySetInnerHTML={{ __html: data?.summary as string }}
          />
        </Stack>

        <ChartReport
          date={date2 as Date}
          comId={Number(data?.company_id)}
          handleDisplay={handleDisplay}
        />

        {!!data?.last_measure && (
          <Stack mb={4}>
            <Typography variant="h6" ml={1}>
              前回の施策・結果
            </Typography>
            <Box
              mb={4}
              component="div"
              dangerouslySetInnerHTML={{ __html: data?.last_measure as string }}
            />
          </Stack>
        )}

        {!!data?.next_measure && (
          <Stack mb={4}>
            <Typography variant="h6" ml={1}>
              今後の施策・取り組み
            </Typography>
            <Box
              mb={4}
              component="div"
              dangerouslySetInnerHTML={{ __html: data?.next_measure as string }}
            />
          </Stack>
        )}

        {!!data?.free && (
          <Stack mb={4}>
            <Typography variant="h6" ml={1}>
              その他
            </Typography>
            <Box
              mb={4}
              component="div"
              dangerouslySetInnerHTML={{ __html: data?.free as string }}
            />
          </Stack>
        )}

        {displayFlg && !!data?.user_voices?.[0]?.category_id && (
          <>
            <Typography variant="h6" ml={1}>
              お客様からの声・クライアントへの課題共有
            </Typography>
            <UseVoiceReport data={data?.user_voices as UserVoiceType[]} date={date} />
          </>
        )}

        {displayFlg && data?.use_cases?.[0]?.subject.length > 0 && (
          <>
            <Typography variant="h6" ml={1} mb={1}>
              利用事例
            </Typography>
            <UseCaseTable data={data?.use_cases as App.Models.UseCase[]} date={date} />
          </>
        )}
      </Container>
    </DialogBase>
  )
}

export default ReportPreviewModal
