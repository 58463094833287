import { Box, Card, Divider, Stack, styled, Typography } from '@mui/material'
import { safeParseJSON } from 'lib/utils'
import { VFC } from 'react'
import { CreateCustomerReportForm } from '../CreateCustomerReport'

export const ImageBox = styled('img')({
  height: 220,
  objectFit: 'cover'
})

const ReportPage: VFC<CreateCustomerReportForm['customer_report_contents'][number]> = ({
  image_path,
  title,
  tags,
  information,
  comment
}) => {
  return (
    <Card elevation={1} sx={{ width: '100%' }}>
      <Box height={220}>
        <ImageBox src={image_path as string} width="100%" />
      </Box>

      <Box p={2}>
        <Stack>
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="subtitle2">
            {safeParseJSON((tags as unknown as string) || '[]').join('/ ')}
          </Typography>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Stack>
          <Typography variant="body2" fontWeight="bold">
            Information
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
            dangerouslySetInnerHTML={{ __html: information || '<p/>' }}
          />
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Stack>
          <Typography variant="body2" fontWeight="bold">
            Comment
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
            dangerouslySetInnerHTML={{ __html: comment || '<p/>' }}
          />
        </Stack>
      </Box>
    </Card>
  )
}

export { ReportPage }
