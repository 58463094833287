import Link, { LinkProps } from '@mui/material/Link'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import { Link as RouterLink, useMatch } from 'react-router-dom'

interface ListItemLinkProps extends ListItemProps {
  to: string
  open?: boolean
}

export function ListItemLink(props: ListItemLinkProps) {
  const { to, sx, ...other } = props
  const match = useMatch(to)

  return (
    <ListItem
      button
      component={RouterLink as any}
      sx={{
        backgroundColor: (theme) =>
          match ? theme.palette.grey['A200'] : theme.palette.common.white,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.grey[50]
        },
        ...sx
      }}
      to={to}
      {...other}
    />
  )
}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

export const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink} />
