import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { Select, StyledCheckedIcon, StyledIcon } from 'components/Form'
import { Page } from 'components/Layouts'
import { useApiResource } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

export type CompanyReportContent = Omit<
  App.Models.CompanyReportContent,
  'report_content' | 'company'
> & {
  report_content: Omit<App.Models.ReportContent, 'companies'>[] | number[]
}

const FormReport: React.VFC = () => {
  const navigate = useNavigate()

  const { control, handleSubmit, setError, setValue, getValues, watch } =
    useForm<CompanyReportContent>({
      defaultValues: {
        company_id: undefined
      }
    })

  const { data: reportCategories } = useQuery<App.Models.ReportCategory[]>([
    'report-categories',
    { per_page: -1, sort: 'id|asc' }
  ])

  const company_id = watch('company_id')
  const reportContentValues = watch('report_content')

  useQuery<App.Models.CompanyReportContent[]>(
    ['company-report-contents', { per_page: -1, company_id_equal: company_id }],
    {
      onSuccess: (data) => {
        setValue('report_content', data.map((el) => el?.report_content?.id) as number[])
      },
      enabled: !!company_id
    }
  )

  const { createOrUpdateApi } = useApiResource<CompanyReportContent>('company-report-contents')

  const onSubmit: SubmitHandler<CompanyReportContent> = async (values) => {
    try {
      await createOrUpdateApi(values)
      navigate('/monthly-report')
      toast.success(company_id ? '更新しました。' : '登録しました。')
    } catch (error) {
      toast.error(company_id ? '更新に失敗しました。' : '登録に失敗しました。')
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const handleChangeReportContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.name)
    const checked = event.target.checked
    const values = (getValues('report_content') || []) as App.Models.ReportContent[] | number[]

    if (checked) {
      setValue('report_content', [...values, id] as unknown as App.Models.ReportContent[])
    } else {
      setValue(
        'report_content',
        (values as number[])?.filter((v: number) => v !== id)
      )
    }
  }

  return (
    <Page title="月次レポート定量データ ">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Stack spacing={2} mb={3}>
          <Select<App.Models.Company>
            name="company_id"
            label="対象企業"
            fullWidth
            control={control}
            query="companies"
          />

          <Grid container spacing={4}>
            {reportCategories?.map((rc, index) => (
              <Grid item xs={index == 0 ? 12 : 6} key={index}>
                <Typography variant="h5" fontWeight="bold">
                  {rc?.name}
                </Typography>
                {rc?.report_contents?.map((content, ind) => (
                  <Stack key={ind}>
                    <FormControlLabel
                      key={content.id}
                      control={
                        <Checkbox
                          name={content.id.toString()}
                          onChange={handleChangeReportContent}
                          checkedIcon={<StyledCheckedIcon />}
                          icon={<StyledIcon />}
                        />
                      }
                      label={content.name}
                      checked={
                        (
                          (getValues('report_content') ||
                            reportContentValues) as unknown as number[]
                        )?.includes(content.id) || false
                      }
                      labelPlacement="start"
                      sx={{
                        justifyContent: 'start',
                        '& .MuiFormControlLabel-label': { minWidth: 350 },
                        '& .MuiTypography-root': { fontSize: 15, fontWeight: 500 }
                      }}
                    />
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Grid container justifyContent="center">
          <Button type="submit" variant="contained">
            新規登録
          </Button>
        </Grid>
      </Box>
    </Page>
  )
}

export { FormReport }
