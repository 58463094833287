import { InputControl, InputProps, InputStyled } from 'components/Form'
import { useController } from 'react-hook-form'

export function formatPhoneNumber(value: string) {
  if (!value) return value

  const phoneNumber = value.replace(/[^\d]/g, '')

  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength <= 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`
}

function InputPhone<T>({
  name,
  control,
  defaultValue,
  fullWidth,
  label,
  helperText,
  controlProps,
  insideLabel,
  required,
  ...props
}: InputProps<T>) {
  const {
    field: { onChange, value, ref, ...inputProps },
    fieldState: { error }
  } = useController({ name, control, defaultValue })

  const handleChangeInput = (newValue: React.ChangeEvent<HTMLInputElement>) => {
    const values = newValue.target.value
    onChange(formatPhoneNumber(values as unknown as string))
  }

  return (
    <InputControl
      fieldError={error}
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      required={required}
      {...controlProps}
    >
      <InputStyled
        value={value}
        onChange={(newValue) => handleChangeInput(newValue as React.ChangeEvent<HTMLInputElement>)}
        label={insideLabel}
        {...inputProps}
        {...props}
        inputRef={ref}
      />
    </InputControl>
  )
}

export { InputPhone }
