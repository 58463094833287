import s from '@emotion/styled'
import React from 'react'
import { Column, useBlockLayout, useTable } from 'react-table'
import { FixedSizeList } from 'react-window'

const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  )
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

const Styles = s.div`
  padding: 1rem;
  overflow: auto;

  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #dcdfe6;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      :nth-of-type(odd) {
        background-color: rgba(0,0,0, 0.04)
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      color: #1b1e25;

      :last-child {
        border-right: 1px solid #dcdfe6;
      }
    }

    .th {
        padding: 8px;
        font-weight: 700;
        font-size: 14px;
        background-color: #ffee00
    }

    .td {
        font-size: 14px;
        padding: 6px;
    }
  }
`

type TableChartProps = {
  columns: Array<Column>
  data: []
  id?: string
}

function ListChart({ columns, data }: TableChartProps) {
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useBlockLayout
    )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style
          })}
          className="tr"
        >
          {row.cells.map((cell, i) => {
            return (
              <div {...cell.getCellProps()} key={i} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  const size = columns.length < 8 ? 50 : 70

  const defaultHeight = 400

  const fullHeight = rows.length * size

  const height = fullHeight < defaultHeight ? fullHeight : defaultHeight

  return (
    <Styles>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup, i) => (
            <div {...headerGroup.getHeaderGroupProps()} key={i} className="tr">
              {headerGroup.headers.map((column, idx) => (
                <div {...column.getHeaderProps()} key={idx} className="th">
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={height}
            itemCount={rows.length}
            itemSize={size}
            width={totalColumnsWidth + scrollBarSize}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
    </Styles>
  )
}

export { ListChart }
