// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as yup from 'yup'

export const localeJP = {
  mixed: {
    default: '${path}は無効です。',
    required: '${path}は必須です。',
    oneOf: '${path}は次の値のいずれかでなければなりません:${values}',
    notOneOf: '${path}は次の値のいずれかであってはなりません:${values}',
    notType: '${path}は有効である必要があります。'
  },
  string: {
    length: '${path}は正確に${length}文字でなければなりません。',
    min: '${path}は少なくとも${min}文字でなければなりません。',
    max: '${path}は最大${max}文字でなければなりません。',
    matches: '${path}は次と一致する必要があります: "${regex}"',
    email: '${path}はメールアドレス形式である必要があります。',
    url: '${path}は有効なURLでなければなりません。',
    trim: '${path}はトリミングされた文字列でなければなりません。',
    lowercase: '${path}は小文字の文字列でなければなりません。',
    uppercase: '${path}は大文字の文字列でなければなりません。'
  },
  number: {
    min: '${path}は${min}以上である必要があります。',
    max: '${path}は${max}以下でなければなりません。',
    lessThan: '${path}は${less}より小さくなければなりません。',
    moreThan: '${path}は${more}より大きくなければなりません。',
    notEqual: '${path}は${notEqual}と等しくない必要があります。',
    positive: '${path}は正の数でなければなりません。',
    negative: '${path}は負の数でなければなりません。',
    integer: '${path}は整数でなければなりません。'
  },
  date: {
    min: '${path}フィールドは${min}より後でなければなりません。',
    max: '${path}フィールドは${max}より前でなければなりません。'
  },
  object: {
    noUnknown:
      '${path}フィールドには,オブジェクトシェイプで指定されていないキーを含めることはできません。'
  },
  array: {
    min: '${path}フィールドには少なくとも${min}の項目が必要です。',
    max: '${path}フィールドには${max}以下の項目が必要です。'
  }
}

export const localeEn = {
  mixed: {
    default: '${path} is invalid',
    required: '${path} is a required field',
    defined: '${path} must be defined',
    notNull: '${path} cannot be null',
    oneOf: '${path} must be one of the following values: ${values}',
    notOneOf: '${path} must not be one of the following values: ${values}'
  },
  string: {
    length: '${path} must be exactly ${length} characters',
    min: '${path} must be at least ${min} characters',
    max: '${path} must be at most ${max} characters',
    matches: '${path} must match the following: "${regex}"',
    email: '${path} must be a valid email',
    url: '${path} must be a valid URL',
    uuid: '${path} must be a valid UUID',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} must be a lowercase string',
    uppercase: '${path} must be a upper case string'
  },
  number: {
    min: '${path} must be greater than or equal to ${min}',
    max: '${path} must be less than or equal to ${max}',
    lessThan: '${path} must be less than ${less}',
    moreThan: '${path} must be greater than ${more}',
    positive: '${path} must be a positive number',
    negative: '${path} must be a negative number',
    integer: '${path} must be an integer'
  },
  date: {
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}'
  },
  boolean: {
    isValue: '${path} field must be ${value}'
  },
  object: {
    noUnknown: '${path} field has unspecified keys: ${unknown}'
  },
  array: {
    min: '${path} field must have at least ${min} items',
    max: '${path} field must have less than or equal to ${max} items',
    length: '${path} must have ${length} items'
  }
}

export const yupLocale = {
  mixed: {
    default: ({ path }) => ({
      key: 'validations.mixed.default',
      values: { path }
    }),
    required: ({ path }) => ({
      key: 'validations.mixed.required',
      values: { path }
    }),
    defined: ({ path }) => ({
      key: 'validations.mixed.defined',
      values: { path }
    }),
    notNull: ({ path }) => ({
      key: 'validations.mixed.notNull',
      values: { path }
    }),
    oneOf: (values) => ({
      key: 'validations.mixed.oneOf',
      values
    }),
    notOneOf: (values) => ({
      key: 'validations.mixed.oneOf',
      values
    }),
    notType: (values) => ({
      key: 'validations.common.typeError',
      values
    })
  },
  string: {
    length: (values) => ({
      key: 'validations.string.length',
      values
    }),
    min: (values) => ({
      key: 'validations.string.min',
      values
    }),
    max: (values) => ({
      key: 'validations.string.max',
      values
    }),
    matches: (values) => ({
      key: 'validations.string.matches',
      values
    }),
    email: (values) => ({
      key: 'validations.string.email',
      values
    }),
    url: (values) => ({
      key: 'validations.string.url',
      values
    }),
    uuid: (values) => ({
      key: 'validations.string.uuid',
      values
    }),
    trim: (values) => ({
      key: 'validations.string.trim',
      values
    }),
    lowercase: (values) => ({
      key: 'validations.string.lowercase',
      values
    }),
    uppercase: (values) => ({
      key: 'validations.string.uppercase',
      values
    })
  },
  number: {
    min: (values) => ({
      key: 'validations.number.min',
      values
    }),
    max: (values) => ({
      key: 'validations.number.max',
      values
    }),
    lessThan: (values) => ({
      key: 'validations.number.lessThan',
      values
    }),
    moreThan: (values) => ({
      key: 'validations.number.moreThan',
      values
    }),
    positive: (values) => ({
      key: 'validations.number.positive',
      values
    }),
    negative: (values) => ({
      key: 'validations.number.negative',
      values
    }),
    integer: (values) => ({
      key: 'validations.number.integer',
      values
    })
  },
  date: {
    min: (values) => ({
      key: 'validations.date.min',
      values
    }),
    max: (values) => ({
      key: 'validations.date.max',
      values
    })
  },
  boolean: {
    isValue: (values) => ({
      key: 'validations.boolean.isValue',
      values
    })
  },
  object: {
    noUnknown: (values) => ({
      key: 'validations.object.noUnknown',
      values
    })
  },
  array: {
    min: (values) => ({
      key: 'validations.array.min',
      values
    }),
    max: (values) => ({
      key: 'validations.array.max',
      values
    }),
    length: (values) => ({
      key: 'validations.array.length',
      values
    })
  }
}

yup.setLocale(localeJP)
