import { Layout } from 'components/Layouts'
import { FullScreenLoading } from 'components/Loader'
import { useAuth } from 'lib/hooks'
import React, { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ChangePassword, Login, ResetPassword } from 'screens/auth'
import { CreateCustomerReport } from 'screens/customer-report'
import { NoMatch } from 'screens/NoMatch'
import { RequireAuth } from './RequireAuth'
import { routerPermission } from './routes'

const Router: React.VFC = () => {
  const { loading, user } = useAuth()

  const routing = useMemo(
    () => routerPermission.filter((r) => r.permissions.includes(user?.permission_id as number)),
    [user?.permission_id]
  )

  if (loading) {
    return <FullScreenLoading />
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        {routing.map((r, index) => (
          <Route path={r.path} element={r.element} key={index} />
        ))}
      </Route>

      <Route
        path="/customer-report/edit/:id"
        element={
          <RequireAuth>
            <CreateCustomerReport />
          </RequireAuth>
        }
      />

      <Route
        path="/customer-report/create/:id"
        element={
          <RequireAuth>
            <CreateCustomerReport />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export { Router }
