import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { Input, InputTag, Select } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { permissions } from 'lib/constants'
import { useApiResource } from 'lib/hooks/useApiResource'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

const FormAdminUser: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const isEdit = !!params?.id

  const validateAdmin = yup.object({
    name: yup.string().required('名前は必須です。').trim(),
    permission_id: yup.number().typeError('権限を選択してください。').required('権限は必須です。'),
    email: yup
      .string()
      .required()
      .label('メールアドレス')
      .email('メールアドレスが正しい形式ではありません。'),
    password: !isEdit
      ? yup
          .string()
          .required()
          .label('パスワード')
          .min(8, 'パスワードは8文字以上で入力して下さい。')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/,
            'パスワードは半角英数字を混ぜたものを指定してください。'
          )
      : yup.string().when({
          is: (value: string) => !!value,
          then: yup
            .string()
            .min(8, 'パスワードは8文字以上で入力して下さい。')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/,
              'パスワードは半角英数字を混ぜたものを指定してください。'
            )
        }),
    display_name: yup.string().required('表示名は必須です。').trim()
  })

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting }
  } = useForm<App.Models.AdminUser>({
    defaultValues: {
      id: Number(params?.id),
      name: '',
      email: '',
      password: '',
      permission_id: undefined,
      image_path: '',
      display_name: '',
      languages: [],
      strong_fields: [],
      profile: ''
    },
    resolver: yupResolver(validateAdmin)
  })

  useQuery<App.Models.AdminUser>([`admin-users/${params.id}`], {
    onSuccess: (data) => {
      for (const name in data) {
        setValue(name as keyof App.Models.AdminUser, data[name as keyof App.Models.AdminUser])
      }
      setValue('languages', JSON.parse(data.languages as string))
      setValue('strong_fields', JSON.parse(data.strong_fields as string))
    },
    enabled: isEdit
  })

  const { createOrUpdateApi } = useApiResource<App.Models.AdminUser>('admin-users')

  const onSubmit: SubmitHandler<App.Models.AdminUser> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/admin-user')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title={isEdit ? '管理者変更' : '管理者新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Select
            fullWidth
            label="権限"
            name="permission_id"
            options={permissions}
            control={control}
          />

          <Input fullWidth label="名前" name="name" control={control} />

          <Input fullWidth label="メールアドレス" name="email" control={control} />

          <Stack>
            <Input
              fullWidth
              label="パスワード"
              name="password"
              type="password"
              autoComplete="new-password"
              control={control}
            />
            <Typography variant="caption" color="grey.A400">
              パスワードは英大文字、英小文字、数字、記号を混ぜて8文字以上で指定してください。
            </Typography>
          </Stack>

          <Grid container justifyContent="center">
            <ImageUploader name="image_path" control={control} label="プロフィール画像" />
          </Grid>

          <Input fullWidth label="表示名" name="display_name" control={control} />

          <InputTag fullWidth label="語学" name="languages" control={control} />

          <InputTag fullWidth label="得意な領域・サポート" name="strong_fields" control={control} />

          <Input
            fullWidth
            label="プロフィール"
            name="profile"
            control={control}
            multiline
            rows={6}
          />
        </Stack>

        <Grid container justifyContent="center">
          {isEdit ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export { FormAdminUser }
