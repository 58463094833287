import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { AvatarWithSize } from 'components/Avatar'
import { DialogBase } from 'components/Dialog'
import IButton from 'components/IButton'
import { useAuth } from 'lib/hooks'
import { ModalProps } from 'lib/types'
import { green } from 'styles'
import { CreateCustomerReportForm, CustomerReportType } from '../CreateCustomerReport'
import { ImageBox, ReportPage } from './ReportPage'

export type PreviewModalProps = ModalProps<{
  report?: CustomerReportType
  data?: CreateCustomerReportForm
}>

const PreviewModal: React.VFC<PreviewModalProps> = ({ open, onClose, report, data }) => {
  const { user } = useAuth()

  const getStatusLabel = () => {
    if (report?.status === 4 || report?.status === 5) {
      return 'Closed'
    }

    return 'Open'
  }

  return (
    <DialogBase maxWidth="xl" open={open} onClose={onClose}>
      <Stack direction="row" mb={2} justifyContent="flex-end">
        <IButton onClick={onClose as () => void}>
          <CloseRoundedIcon />
        </IButton>
      </Stack>
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={5} spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center">
            <ImageBox
              src={data?.image_path as string}
              sx={{ borderRadius: 2, width: 80, height: 80 }}
            />

            <Box>
              <Stack direction="row">
                <Chip
                  label={getStatusLabel()}
                  size="small"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: green[700],
                    color: 'white',
                    fontWeight: '500',
                    mr: 1,
                    py: 0.5
                  }}
                />

                <Typography fontWeight={500} color="grey.400">
                  #{report?.fd_ticket_id}
                </Typography>
              </Stack>

              <Typography variant="h4">{report?.subject}</Typography>
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ mb: 3 }} />

        <Box>
          <Stack direction="row" spacing={2} alignItems="center" mb={5}>
            <AvatarWithSize size={64} src={user?.image_path as string} />

            <Stack>
              <Typography variant="h6" fontWeight={500}>
                {user?.display_name as string}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {report?.fd_ticket_created_at}
              </Typography>
            </Stack>
          </Stack>

          <Typography
            variant="body1"
            mb={5}
            // whiteSpace="pre-line"
            sx={{
              wordBreak: 'break-all',
              '& img': { maxWidth: '100%', height: 'auto' }
            }}
            component="div"
            dangerouslySetInnerHTML={{
              __html: data?.beginning as string
            }}
          />

          <Grid container spacing={{ md: 3, xs: 1, sm: 2 }} mb={9}>
            {data?.customer_report_contents?.map((value, index) => (
              <Grid item container justifyContent="center" md={4} key={index}>
                <ReportPage {...value} />
              </Grid>
            ))}
          </Grid>

          <Typography
            component="div"
            sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
            dangerouslySetInnerHTML={{ __html: data?.body || '<p/>' }}
          />
        </Box>
      </Container>
    </DialogBase>
  )
}

export default PreviewModal
