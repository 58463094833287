import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Page } from 'components/Layouts'
import { MinimizeScrollbar } from 'components/MinimizeScrollbar'
import { format } from 'date-fns'
import { Subscriber } from 'lib/types'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

export type EventSubScriberQuery = {
  event: App.Models.Event
  subscribers: Subscriber[]
}

const EventSubScriber: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<EventSubScriberQuery>([`event-subscribers/${params?.id}`])

  return (
    <Page title={data?.event.title}>
      {data?.subscribers.map((sub, index) => (
        <Box key={index}>
          <Typography p={2}>
            {format(new Date(sub.event_dates.start_date_time), 'yyyy/MM/dd H:mm')} {' - '}
            {format(new Date(sub.event_dates.end_date_time), 'H:mm')}
          </Typography>
          <MinimizeScrollbar border={1} height={250} p={1}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Freshdesk ID</TableCell>
                    <TableCell>名前</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>Company 1</TableCell>
                    <TableCell>Company 2</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sub.users.map((u, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {format(new Date(sub.event_dates.start_date_time), 'yyyy/MM/dd')}
                      </TableCell>
                      <TableCell>{u?.fd_ticket_id}</TableCell>
                      <TableCell>{u?.last_name + ' ' + u?.first_name}</TableCell>
                      <TableCell>{u?.email}</TableCell>
                      <TableCell>{u?.parent_company && u?.parent_company?.name}</TableCell>
                      <TableCell>{u?.child_company && u?.child_company?.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MinimizeScrollbar>
        </Box>
      ))}
    </Page>
  )
}

export { EventSubScriber }
