import { Button, SvgIcon } from '@mui/material'
import { ReactComponent as Delete } from 'assets/svg/delete.svg'
import { ReactComponent as Edit } from 'assets/svg/edit.svg'
import IButton from 'components/IButton'
import { Page } from 'components/Layouts'
import { CellContainer, ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { permissions } from 'lib/constants'
import { useApiResource, useAuth, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { CellProps, Column, Row } from 'react-table'

const AdminUser: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.AdminUser>('admin-users')
  const { deleteApi } = useApiResource<App.Models.AdminUser>('admin-users')
  const { isFullAction, hasDetail } = useCheckPerm()
  const navigate = useNavigate()
  const dialog = useDialog()

  const { user } = useAuth()

  const handleDelete = useCallback(
    async ({ original }: Row<App.Models.AdminUser>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const handleEdit = useCallback(
    ({ original }: Row<App.Models.AdminUser>) => {
      navigate(`/admin-user/edit/${original.id}`)
    },
    [navigate]
  )

  const onRowClick = useCallback(
    ({ original }: Row<App.Models.AdminUser>) => {
      navigate('/admin-user/detail/' + original.id)
    },
    [navigate]
  )

  const columns = useMemo<Column<App.Models.AdminUser>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: '名前',
        accessor: 'name',
        width: 200
      },
      {
        Header: 'メールアドレス',
        accessor: 'email',
        width: 200
      },
      {
        Header: '権限',
        accessor: 'permission_id',
        Cell: ({ value }) => permissions.find((p) => p.value === value)?.label,
        searchType: 'select',
        additionSearchProps: {
          options: permissions
        },
        regex: '_equal'
      },
      {
        Header: 'Language',
        accessor: 'languages',
        Cell: ({ value }) => (value ? JSON.parse(value).join(', ') : ''),
        width: 150
      },
      {
        Header: '得意な領域',
        accessor: 'strong_fields',
        Cell: ({ value }) => (value ? JSON.parse(value).join(', ') : ''),
        width: 200
      },
      {
        Header: 'プロフィール',
        accessor: 'profile',
        width: 300
      },
      {
        Header: ' ',
        Cell: ({ row }: CellProps<App.Models.AdminUser>) => (
          <CellContainer direction="row" spacing={1} width={60}>
            {!isFullAction ? (
              <>{hasDetail && <Button onClick={() => onRowClick(row)}>詳細</Button>}</>
            ) : (
              <>
                <IButton onClick={() => handleEdit(row)}>
                  <SvgIcon viewBox="0 0 28 28" component={Edit} />
                </IButton>
                {!(row.original.id === user?.id) && (
                  <IButton onClick={() => handleDelete(row)}>
                    <SvgIcon viewBox="0 0 28 28" component={Delete} />
                  </IButton>
                )}
              </>
            )}
          </CellContainer>
        ),
        search: false,
        width: 76
      }
    ],
    [handleDelete, handleEdit, hasDetail, isFullAction, onRowClick, user?.id]
  )

  return (
    <Page title="管理者管理一覧">
      <TopHeader
        path="/admin-user/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
        filterBarLayout="collapse"
      />

      <ReactTable<App.Models.AdminUser>
        columns={columns}
        {...paginationData}
        onRowClick={onRowClick}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { AdminUser }
