import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Input } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { useModalState } from 'lib/hooks'
import { useApiResource } from 'lib/hooks/useApiResource'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { InputPhone } from './InputPhone'
import InstructorPreview from './InstructorPreview'

type InstructorType = Omit<App.Models.Instructor, 'events' | 'videos' | 'contents' | 'users'>

const validateInstructor = yup.object({
  company_name: yup.string().required().label('会社名・肩書き（日本語）').trim(),
  company_name_en: yup.string().required().label('会社名・肩書き（English）').trim(),
  demand_company_name: yup.string().required().label('請求先会社名').trim(),
  demand_name: yup.string().required().label('請求先氏名').trim(),
  display_name: yup.string().required().label('表示名（日本語）').trim(),
  display_name_en: yup.string().required().label('表示名（English）').trim(),
  email: yup.string().email().required().label('メールアドレス').trim(),
  image_path: yup.string().required('プロフィール画像を選択してください。').trim(),
  profile: yup.string().required().label('プロフィール（日本語）').trim(),
  profile_en: yup.string().required().label('プロフィール（English）').trim(),
  tel: yup
    .string()
    .required()
    .label('電話番号')
    .matches(/^0\d{1,4}-\d{1,5}-\d{1,5}$/, '電話番号は有効である必要があります。'),
  url: yup.string().required().label('URL').trim()
})

const FormInstructor: React.VFC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const isEdit = !!params?.id
  const { isOpen, onClose, onOpen } = useModalState()

  const { createOrUpdateApi } = useApiResource<InstructorType>('instructors')

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { isSubmitting }
  } = useForm<InstructorType>({
    defaultValues: {
      id: Number(params?.id),
      image_path: '',
      profile: '',
      display_name: '',
      display_name_en: '',
      company_name: '',
      company_name_en: '',
      email: '',
      tel: '',
      notes: '',
      profile_en: '',
      url: '',
      demand_name: '',
      demand_company_name: ''
    },
    resolver: yupResolver(validateInstructor)
  })

  useQuery<InstructorType>([`instructors/${params.id}`], {
    onSuccess: (data) => {
      let name: keyof InstructorType
      for (name in data) {
        if (name === 'tel') {
          setValue
        }
        setValue(name, data[name])
      }
    },
    enabled: isEdit
  })

  const onSubmit: SubmitHandler<InstructorType> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/instructor')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title={isEdit ? 'イベント講師編集' : 'イベント講師登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Stack spacing={2} mb={3}>
          <Grid container justifyContent="center">
            <ImageUploader name="image_path" control={control} label="プロフィール画像" />
          </Grid>

          <Input fullWidth label="会社名・肩書き（日本語）" name="company_name" control={control} />

          <Input
            fullWidth
            label="会社名・肩書き（English）"
            name="company_name_en"
            control={control}
          />

          <Input fullWidth label="表示名（日本語） " name="display_name" control={control} />

          <Input fullWidth label="表示名（English）" name="display_name_en" control={control} />

          <Input fullWidth label="URL" name="url" control={control} />

          <Input
            fullWidth
            label="プロフィール（日本語）"
            name="profile"
            rows={6}
            multiline
            control={control}
          />

          <Input
            fullWidth
            label="プロフィール（English）"
            name="profile_en"
            rows={6}
            multiline
            control={control}
          />

          <Typography align="center" variant="h4" fontWeight="bold">
            業務情報
          </Typography>

          <Input fullWidth label="請求先氏名" name="demand_name" control={control} />

          <Input fullWidth label="請求先会社名" name="demand_company_name" control={control} />

          <Input fullWidth label="メールアドレス " name="email" control={control} />

          <InputPhone fullWidth label="電話番号" name="tel" control={control} />

          <Input fullWidth label="注意事項" name="notes" rows={6} multiline control={control} />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          {isEdit ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}

          <Button variant="outlined" color="inherit" onClick={onOpen}>
            プレビュー
          </Button>
        </Stack>
      </Container>

      <InstructorPreview open={isOpen} onClose={onClose} {...watch()} />
    </Page>
  )
}

export { FormInstructor }
