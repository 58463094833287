import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Stack, Typography } from '@mui/material'
import { CheckBox, Input, InputTag, RichText, Select } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { format, isAfter, isDate } from 'date-fns'
import { language } from 'lib/constants'
import { useApiResource } from 'lib/hooks'
import { useCategoryOptions } from 'lib/hooks/useCategoryOptions'
import { UnknownObj } from 'lib/types'
import { formatISODate, handleValidateErrors } from 'lib/utils'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { InstructorType } from '.'
import { EventDateTime } from './EventDateTime'

export type FormEventValue = Omit<
  App.Models.Event,
  'event_reservations' | 'company' | 'group' | 'instructors' | 'users'
> & {
  language?: string[]
  instructor_id1: number
  instructor_id1_fee: number
  instructor_id2: number
  instructor_id2_fee: number
  event_dates: Array<Omit<App.Models.EventDate, 'event_reservations' | 'event'>>
  group: number[]
  instructors: Array<Omit<InstructorType, 'events' | 'videos' | 'contents'>>
}

declare module 'yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(message?: string, mapping?: (event: App.Models.EventDate) => string): ArraySchema<T>
  }
}

yup.addMethod(yup.array, 'unique', function (message, mapping) {
  return this.test('unique', message, function (list) {
    const mapper = (x: Record<string, unknown>) => mapping(x)
    const set = [...new Set((list as Record<string, unknown>[]).map(mapper))]
    const isUnique = (list as Record<string, unknown>[]).length === set.length
    if (isUnique) {
      return true
    }

    return this.createError({
      path: `event_dates`,
      message: message
    })
  })
})

const mapping = (event: App.Models.EventDate) => {
  return `${event.start_date_time}-${event.end_date_time}-${event.admin_user_id}-${event.is_rec}`
}

const FormEvent: React.VFC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const isEdit = !!params?.id
  const [isPrivate, setIsPrivate] = useState<boolean>()

  const validateEvent = yup.object({
    title: yup.string().required('タイトルは必須です。').max(255, '入力は255文字までです。').trim(),
    image_path: yup.string().required('アイキャッチ画像を選択してください。'),
    detail: yup.string().required(' 詳細は必須です。'),
    title_en: yup
      .string()
      .required('英語タイトルは必須です。')
      .max(255, '入力は255文字までです。')
      .trim(),
    detail_en: yup.string().required('英語詳細は必須です。'),
    group_id: yup
      .number()
      .typeError('配信グループを選択してください。')
      .required('配信グループを選択してください。'),
    instructor_id1: yup
      .number()
      .typeError('講師1を選択してください。')
      .required('講師1を選択してください。'),
    instructor_id1_fee: yup
      .number()
      .typeError('講師1の報酬が正しい形式ではありません。')
      .required('講師1の報酬は必須です。')
      .min(1, '1より大きい値を入力してください。'),
    fd_ticket_category_1: yup.string().required('Freshdesk Category1を選択してください。'),
    fd_ticket_category_3: yup.string().required('Freshdesk Category Eventを選択してください。'),
    event_dates: yup
      .array()
      .of(
        yup.object().shape({
          start_date_time: isEdit
            ? yup.date()
            : yup.date().test({
                name: 'check start date',
                test: function checkStart(value) {
                  if (!!value && value < new Date()) return false
                  return true
                },
                message: '開始時間が無効です。'
              }),
          end_date_time: yup.date().when('start_date_time', {
            is: (value: string) => !!value,
            then: yup.date().test({
              name: 'check end date',
              test: function checkEnd(end) {
                const { start_date_time } = this.parent
                if (isAfter(end as Date, start_date_time)) {
                  return true
                }
                return false
              },
              message: '終了時間が無効です。'
            })
          }),
          max_booking_number: yup.number().required(' ').min(1, ' '),
          admin_user_id: yup.number().required(' ')
        })
      )
      .unique('同じイベントスケジュールを選択しないでください。', (event: App.Models.EventDate) =>
        mapping(event)
      )
  })

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useForm<FormEventValue>({
    defaultValues: {
      id: Number(params?.id) || undefined,
      title: '',
      title_en: '',
      detail: '',
      detail_en: '',
      image_path: '',
      instructor_id1: undefined,
      instructor_id1_fee: undefined,
      instructor_id2: undefined,
      instructor_id2_fee: undefined,
      zoom_url: '',
      zoom_account: '',
      group_id: undefined,
      language: [],
      is_private: false,
      fd_ticket_category_1: '',
      fd_ticket_category_2: 'TPO Event',
      fd_ticket_category_3: '',
      fd_tags: [],
      fd_description: '',
      event_dates: [
        {
          start_date_time: formatISODate(new Date()) + ' 00:00',
          end_date_time: formatISODate(new Date()) + ' 00:00',
          max_booking_number: undefined,
          admin_user_id: undefined,
          is_rec: false
        }
      ],
      venue: ''
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    resolver: isPrivate === true ? null : yupResolver(validateEvent)
  })

  const category2 = watch('fd_ticket_category_2')
  const categoryOptions = useCategoryOptions()

  const { data } = useQuery<FormEventValue>([`events/${params.id}`], {
    onSuccess: (data) => {
      const instanceKeys: Array<keyof FormEventValue> = ['instructors']
      const language: string[] = []
      let name: keyof FormEventValue
      for (name in data) {
        if (instanceKeys.includes(name as keyof FormEventValue)) {
          setValue('instructor_id1', data?.instructors[0]?.id)
          setValue('instructor_id2', data?.instructors[1]?.id)

          setValue('instructor_id1_fee', data?.instructors[0]?.instructor_fee)
          setValue('instructor_id2_fee', data?.instructors[1]?.instructor_fee)
        }
        if (name === 'fd_tags') {
          data[name] && setValue(name, JSON.parse(data[name]))
          continue
        }
        if (name === 'is_english' || name === 'is_japanese') {
          if (data[name]) {
            language.push(name)
            setValue('language', language)
            continue
          }
        }

        setValue(name, data[name])
      }
    },
    enabled: isEdit
  })

  useEffect(() => {
    if (isPrivate === true) {
      setValue('is_private', true)
    }
  }, [isPrivate, setValue])

  const { createOrUpdateApi } = useApiResource('events')

  const onSubmit: SubmitHandler<FormEventValue> = async (values) => {
    const languages = ['is_english', 'is_japanese']
    const _lngObj = languages.reduce<UnknownObj>((acc, cur) => {
      acc[cur] = !!values.language?.includes(cur)
      return acc
    }, {})

    values = {
      ...values,
      ..._lngObj
    }

    const arr = values['event_dates'] as []
    arr.map((el: any) => {
      return Object.keys(el).forEach((i1) => {
        if (isDate(el[i1])) {
          el[i1] = format(new Date(el[i1]), 'yyyy-MM-dd HH:mm')
        }
      })
    })

    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/event')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const instructor1 = watch('instructor_id1')

  return (
    <Page title={isEdit ? 'イベント編集' : 'イベント新規登録 '}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Input fullWidth label="タイトル" name="title" control={control} />

          <ImageUploader
            control={control}
            layout="imageBox"
            name="image_path"
            label="アイキャッチ画像"
          />

          <RichText label="詳細" name="detail" defaultValue={data?.detail} control={control} />

          <Input label="英語タイトル" name="title_en" control={control} />

          <RichText
            label="英語詳細"
            name="detail_en"
            defaultValue={data?.detail_en}
            control={control}
          />

          <Select<App.Models.Group>
            name="group_id"
            label="配信グループ"
            control={control}
            fullWidth
            query="groups"
          />

          <Select<App.Models.Instructor>
            name="instructor_id1"
            label="講師1"
            fullWidth
            control={control}
            query="instructors"
            labelValueKeys={['display_name', 'id']}
          />

          <Stack direction="row" spacing={4} alignItems="center">
            <Input
              fullWidth
              label="講師1の報酬"
              name="instructor_id1_fee"
              control={control}
              type="number"
            />
            <Typography>円</Typography>
          </Stack>

          <Select<App.Models.Instructor>
            name="instructor_id2"
            label="講師2"
            fullWidth
            control={control}
            query={`instructors?id_notEqual=${instructor1}`}
            labelValueKeys={['display_name', 'id']}
          />

          <Stack direction="row" spacing={4} alignItems="center">
            <Input
              fullWidth
              label="講師2の報酬"
              name="instructor_id2_fee"
              control={control}
              type="number"
            />
            <Typography>円</Typography>
          </Stack>

          <Input fullWidth name="venue" label="開催場所" control={control} />

          <Input fullWidth name="zoom_account" label="ZOOM アカウント" control={control} />

          <Input fullWidth name="zoom_url" label="ZOOM URL" control={control} />

          <Select
            fullWidth
            name="language"
            label="対応言語"
            control={control}
            options={language}
            multiple
          />

          <Select
            control={control}
            name="fd_ticket_category_1"
            label="Freshdesk Category 1"
            options={categoryOptions.category1}
          />
          <Stack>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Freshdesk Category 2
            </Typography>
            <Typography>{category2}</Typography>
          </Stack>

          <Select
            control={control}
            name="fd_ticket_category_3"
            label="Freshdesk Category Event"
            options={categoryOptions.event}
          />

          <InputTag name="fd_tags" label="freshdesk Tags" fullWidth control={control} />

          <RichText
            label="Freshdesk Description"
            name="fd_description"
            defaultValue={data?.fd_description}
            control={control}
          />

          <EventDateTime name="event_dates" control={control} />

          <CheckBox
            name="is_private"
            label="非公開"
            control={control}
            controlProps={{ sx: { width: 'max-content' } }}
          />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setIsPrivate(true)}
            type="submit"
          >
            下書き保存
          </Button>

          {params?.id ? (
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => setIsPrivate(false)}
              loading={isSubmitting}
            >
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => setIsPrivate(false)}
              loading={isSubmitting}
            >
              新規登録
            </LoadingButton>
          )}
        </Stack>
      </Container>
    </Page>
  )
}

export { FormEvent }
