import { Box, Tab, Tabs } from '@mui/material'
import { a11yProps, TabPanel } from 'components/Form'
import { Page } from 'components/Layouts'
import { useState } from 'react'
import { WorkingRecord } from './WorkingRecord'
import { WorkingRecordTotal } from './WorkingRecordTotal'

const WorkingRecordTab: React.VFC = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Page>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            float: 'right',
            marginRight: 4,
            mb: 2
          }}
        >
          <Tabs
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                height: '4px'
              }
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="一覧" {...a11yProps(0)} />
            <Tab label="合算" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <WorkingRecord />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WorkingRecordTotal />
        </TabPanel>
      </Box>
    </Page>
  )
}

export { WorkingRecordTab }
