import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ja } from 'date-fns/locale'
import { useAuth } from 'lib/hooks'
import DialogUtilsProvider from 'lib/providers/DialogProvider'
import { queryClient } from 'lib/react-query'
import 'lib/yupI18n'
import { Suspense, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { Router } from 'routers'
import { defaultTheme } from 'styles'

function App() {
  const { fetchUser } = useAuth()

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <ThemeProvider theme={defaultTheme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Toaster />
        <Suspense fallback="Loading...">
          <DialogUtilsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
              <Router />
            </LocalizationProvider>
          </DialogUtilsProvider>
        </Suspense>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
