import { InputBase, Stack, useTheme } from '@mui/material'
import { useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { InputProps } from '.'
import { InputControl } from '..'
import { Tag } from '../components'

function InputTag<T>({
  name,
  control,
  defaultValue,
  fullWidth,
  label,
  helperText,
  controlProps,
  ...props
}: InputProps<T>) {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue })

  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputState, setInputState] = useState<'focus' | 'blur'>('blur')

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const trimmedInput = String(inputRef.current?.value || '').trim()
    if (key === ',' && trimmedInput.length && !(value as string[]).includes(trimmedInput)) {
      e.preventDefault()
      onChange([...(value as string[]), trimmedInput])
      ;(inputRef.current as HTMLInputElement).value = ''
    }
  }

  const onDeleteTag = (tag: string) => {
    const newValue = (value as string[]).filter((t: string) => t !== tag)
    onChange(newValue)
  }

  const handleBlur = () => {
    const trimmedInput = String(inputRef.current?.value || '').trim()
    if (trimmedInput) {
      onChange([...(value as string[]), trimmedInput])
      ;(inputRef.current as HTMLInputElement).value = ''
    }
    setInputState('blur')
    onBlur()
  }

  const getBorderColor = () => {
    if (error) {
      return theme.palette.error.main
    }

    if (inputState === 'focus') {
      return theme.palette.primary.main
    }

    if (inputState === 'blur') {
      return theme.palette.action.disabled
    }

    return theme.palette.action.disabled
  }

  return (
    <InputControl
      fieldError={error}
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      {...controlProps}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        p={0.5}
        sx={{
          border: (theme) =>
            `${inputState === 'focus' ? 2 : 1}px solid ${theme.palette.action.disabled}`,
          borderRadius: 1,
          alignItems: 'center',
          borderColor: getBorderColor()
        }}
      >
        {((value as string[]) || []).map((tag: string) => (
          <Tag
            size="small"
            label={tag}
            key={tag}
            onDelete={() => onDeleteTag(tag)}
            sx={{ m: 0.25 }}
          />
        ))}
        <InputBase
          onFocus={() => setInputState('focus')}
          onBlur={handleBlur}
          {...props}
          sx={{
            px: 0.25,
            mt: (value as string[])?.length > 0 ? 0.25 : 0,
            display: 'flex',
            flexGrow: 1,
            ...props.sx
          }}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
        />
      </Stack>
    </InputControl>
  )
}

export { InputTag }
