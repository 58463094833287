import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { format } from 'date-fns'
import { languageUser, statusOptions } from 'lib/constants'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

const User: React.VFC = () => {
  const dialog = useDialog()
  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.User>('users')

  const { deleteApi } = useApiResource<App.Models.User>('users')

  const columns = useMemo<Column<App.Models.User>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: 'Registration Date',
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value as string), 'yyyy/MM/dd'),
        search: false
      },
      {
        Header: '姓',
        accessor: 'last_name',
        width: 200
      },
      {
        Header: '名',
        accessor: 'first_name',
        width: 200
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 200
      },
      {
        Header: 'Language',
        accessor: 'lng',
        Cell: ({ value }) => languageUser.find((p) => p.value === Number(value))?.label,
        searchType: 'select',
        additionSearchProps: {
          options: languageUser
        },
        regex: '_equal'
      },
      {
        Header: 'Company 1',
        accessor: 'parent_company.name' as 'parent_company',
        searchType: 'select',
        additionSearchProps: {
          query: 'companies',
          labelValueKeys: ['name', 'name']
        },
        width: 150
      },
      {
        Header: 'Company 2',
        accessor: 'child_company.name' as 'child_company',
        searchType: 'select',
        additionSearchProps: {
          query: 'companies',
          labelValueKeys: ['name', 'name']
        },
        width: 150
      },
      {
        Header: 'ステータス',
        accessor: 'is_active',
        Cell: ({ value }) => statusOptions.find((s) => s.value === (value as unknown))?.label,
        searchType: 'select',
        additionSearchProps: {
          options: [
            {
              value: '1',
              label: 'アクティブ'
            },
            {
              value: '0',
              label: '非アクティブ'
            }
          ]
        },
        regex: '_equal'
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.User>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<App.Models.User>) => {
    navigate('/user/detail/' + original.id)
  }

  return (
    <Page title="ユーザー一覧">
      <TopHeader<App.Models.User>
        path="/user/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
        filterBarLayout="collapse"
      />
      <ReactTable<App.Models.User>
        columns={columns}
        defaultActionEdit
        onActionDelete={handleDelete}
        onRowClick={onRowClick}
        {...paginationData}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { User }
