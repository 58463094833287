import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { LoadingButton } from '@mui/lab'
import { Button, Chip, Container, Divider, Stack, styled, Typography } from '@mui/material'
import { FormLabel as Label, RichText } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { useModalState } from 'lib/hooks'
import { request } from 'lib/request'
import { handleValidateErrors, safeParseJSON } from 'lib/utils'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { green } from 'styles'
import * as yup from 'yup'
import { CreateReportContent, Header } from './components'
import PreviewModal from './components/Previewer'
import {getValueLabel} from "echarts/types/src/component/axisPointer/viewHelper";

const FormLabel = styled(Label)({
  fontSize: 24,
  fontWeight: 700,
  marginBottom: 12
})

export type CustomerReportType = {
  subject: string
  status?: number
  fd_ticket_created_at: string
  customer_report: App.Models.CustomerReport
  fd_ticket_id: number
  company: App.Models.Company
}

export type CreateCustomerReportForm = {
  ticket_id: number
  fd_ticket_id: number
  beginning: string
  body: string
  image_path: string
  company_id: number
  fd_ticket_subject: string
  is_private: number
  customer_report_contents: Array<{
    title: string
    url: string
    information: string
    comment: string
    image_path: string
    tags: string[]
    should_fetch?: boolean
  }>
}

const validateSchema = yup.object({
  image_path: yup.string().required().label('画像'),
  beginning: yup.string().required().label('冒頭文'),
  body: yup.string().nullable().label('Body'),
  customer_report_contents: yup.array().of(
    yup.object().shape({
      title: yup.string().label('タイトル'),
      url: yup.string().label('URL'),
      information: yup.string().label('情報'),
      comment: yup.string().label('コメント'),
      image_path: yup.string().label('画像')
    })
  )
})

const CreateCustomerReport = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isPrivate, setPrivate] = useState(false)
  const [updateId, setUpdateId] = useState<number | null>(null)
  const { onOpen, onClose, isOpen } = useModalState()

  const methods = useForm<CreateCustomerReportForm>({
    defaultValues: {
      ticket_id: Number(id),
      is_private: 0,
      customer_report_contents: [
        {
          title: '',
          url: '',
          information: '',
          comment: '',
          image_path: '',
          tags: [],
          should_fetch: true
        }
      ]
    },
    resolver: isPrivate ? undefined : yupResolver(validateSchema)
  })

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit: (_private: number) => SubmitHandler<CreateCustomerReportForm> =
    (_private: number) => async (data) => {
      data.is_private = _private

      try {
        if (updateId) {
          await request.put('customer-reports/' + updateId, data)
        } else {
          await request.post('customer-reports', data)
        }
        toast.success('保存しました。')
        navigate(`/customer-report?id=${data?.fd_ticket_id}`)
      } catch (error) {
        if (error?.message) {
          toast.error(error.message)
        } else {
          toast.error('削除に失敗しました。')
        }
        if (error.errors) {
          handleValidateErrors(error, setError)
        }
      }
    }

  const submit = () => {
    setPrivate(false)
  }

  const submitPrivate = () => {
    clearErrors()
    setPrivate(true)
  }

  const { data } = useQuery<CustomerReportType>(['/customer-reports/' + id], {
    enabled: !!id,
    onSuccess(res) {
      setValue('fd_ticket_id', res.fd_ticket_id)
      setValue('fd_ticket_subject', res.subject)
      setValue('company_id', res.company.id)

      if (res.customer_report) {
        setUpdateId(res.customer_report.id)
        const contents = res.customer_report?.customer_report_contents?.length
          ? res.customer_report.customer_report_contents.map(
              ({ title, url, information, comment, image_path, tags }) => ({
                title,
                url,
                information,
                comment,
                image_path,
                tags: safeParseJSON(tags) || [],
                should_fetch: !!(!image_path && !url)
              })
            )
          : [
              {
                title: '',
                url: '',
                information: '',
                comment: '',
                image_path: '',
                tags: [],
                should_fetch: true
              }
            ]

        setValue('beginning', res.customer_report.beginning as string)
        setValue(
          'customer_report_contents',
          contents as CreateCustomerReportForm['customer_report_contents']
        )
        setValue('body', res.customer_report.body as string)
        setValue('image_path', res.customer_report.image_path as string)
        setValue('is_private', res.customer_report.is_private as unknown as number)
      }
    }
  })

  const getStatusLabel = () => {
    if (data?.status === 4 || data?.status === 5) {
      return 'Closed'
    }

    return 'Open'
  }

  return (
    <>
      <Header />
      <Container maxWidth="xl" sx={{ pt: 4 }}>
        <Stack direction="row" alignItems="center" mb={5}>
          <ArrowBackIosNewIcon
            sx={{ fill: '#000', fontSize: 16, mr: 1, cursor: 'pointer' }}
            onClick={() => navigate(-1)}
          />
          <Typography variant="subtitle2">#{data?.fd_ticket_id}</Typography>
        </Stack>

        <Stack spacing={1} mb={3}>
          <Stack direction="row">
            <Chip
              label={getStatusLabel()}
              size="small"
              sx={{
                borderRadius: 1,
                backgroundColor: green[700],
                color: 'white',
                fontWeight: '500',
                mr: 1,
                py: 0.5
              }}
            />

            <Typography fontWeight={500} color="grey.400">
              #{data?.fd_ticket_id}
            </Typography>
          </Stack>

          <Typography variant="h4">{data?.subject}</Typography>

          <Typography variant="body2" fontWeight={500}>
            {data?.fd_ticket_created_at}
          </Typography>
        </Stack>

        <Divider sx={{ mb: 3 }} />
        <FormProvider {...methods}>
          <Stack component="form" onSubmit={handleSubmit(onSubmit(isPrivate ? 1 : 0))}>
            <ImageUploader
              control={control}
              layout="row-imageBox"
              disableEmptyText
              name="image_path"
              containerSx={{ mb: 4 }}
            />

            <Stack mb={4}>
              <FormLabel error={!!errors.beginning}>
                冒頭文（推奨200字以内・最大500字まで）
              </FormLabel>
              <RichText
                control={control}
                name="beginning"
                defaultValue={data?.customer_report?.beginning || ''}
              />
            </Stack>

            <Stack mb={4}>
              <FormLabel error={!!errors.customer_report_contents}>比較コンテンツカード</FormLabel>
              <CreateReportContent />
            </Stack>

            <Stack mb={4}>
              <FormLabel error={!!errors.body}>本文</FormLabel>
              <RichText
                control={control}
                name="body"
                defaultValue={data?.customer_report?.body || ''}
              />
            </Stack>

            <Stack width="100%" direction="row" justifyContent="center" spacing={3} mb={10}>
              <Button variant="outlined" color="inherit" onClick={onOpen}>
                プレビュー
              </Button>

              <LoadingButton
                variant="contained"
                type="submit"
                onClick={submitPrivate}
                color="inherit"
                loading={isSubmitting && isPrivate}
              >
                下書き保存
              </LoadingButton>

              <LoadingButton
                variant="contained"
                type="submit"
                onClick={submit}
                color="primary"
                loading={isSubmitting && !isPrivate}
              >
                公開
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </Container>

      <PreviewModal report={data} onClose={onClose} open={isOpen} data={watch()} />
    </>
  )
}

export { CreateCustomerReport }
