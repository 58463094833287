import { Stack, Typography } from '@mui/material'
import { FormDetail } from 'components/Form'
import { Page } from 'components/Layouts'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const ConciergeDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.Concierge>([`concierges/${params?.id}`])

  return (
    <Page title="コンシェルジュ詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="プロフィール画像">
          <img
            src={data?.image_path}
            alt="logo"
            width={150}
            height={150}
            style={{ objectFit: 'cover' }}
          />
        </FormDetail>

        <FormDetail label="名前">
          <Typography>{data?.name}</Typography>
        </FormDetail>

        <FormDetail label="名前（English）">
          <Typography>{data?.name_en}</Typography>
        </FormDetail>

        <FormDetail label="プロフィール">
          {data?.profile && <div dangerouslySetInnerHTML={{ __html: data.profile }} />}
        </FormDetail>

        <FormDetail label="プロフィール（English）">
          {data?.profile_en && <div dangerouslySetInnerHTML={{ __html: data.profile_en }} />}
        </FormDetail>

        <FormDetail label="語学">
          <Typography>{data?.languages && JSON.parse(data.languages).join(', ')}</Typography>
        </FormDetail>

        <FormDetail label="得意な領域・サポート">
          <Typography>
            {data?.strong_fields && JSON.parse(data.strong_fields).join(', ')}
          </Typography>
        </FormDetail>

        <FormDetail label="表示順">
          <Typography>{data?.sort_order}</Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { ConciergeDetail }
