import ClearIcon from '@mui/icons-material/Clear'
import { Autocomplete, Box, Button, Grid, Stack, TextField } from '@mui/material'
import { FormHelper, InputControl, InputStyled } from 'components/Form'
import { SyntheticEvent } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { useQuery } from 'react-query'
import { MonthlyReport } from '.'

export type SelectOption = {
  label: string
  value: unknown
}

type CategoryOptions = {
  id: number
  name: string
}

function UserVoices({ control, name }: UseControllerProps<MonthlyReport, 'user_voices'>) {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController<MonthlyReport, 'user_voices'>({ control, name })

  const { data: category } = useQuery<CategoryOptions[]>(['user-voice/categories'])

  const optionCategory = (category || [])?.map((cat) => ({ label: cat.name, value: cat.id }))
  const findOption = (index: number) =>
    optionCategory.find((op) => op.value === value?.[index].category_id)

  const addInput = () => {
    const temp = {
      category_id: undefined,
      subject: '',
      content: ''
    }
    onChange([...(value as App.Models.UserVoice[]), temp])
  }

  const handleChangeCategory = (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption,
    index: number
  ) => {
    const values = [...(value as App.Models.UserVoice[])]
    if (newValue !== null) {
      values[index].category_id = Number(((newValue as SelectOption) || { value: undefined }).value)
    } else {
      values[index].category_id = undefined as unknown as number
    }

    onChange(values)
  }

  const handleChangeSub = (newValue: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const values = [...(value as App.Models.UserVoice[])]
    values[index].subject = newValue.target.value
    onChange(values)
  }

  const handleChangeContent = (newValue: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const values = [...(value as App.Models.UserVoice[])]
    values[index].content = newValue.target.value
    onChange(values)
  }

  const deleteInput = (index: number) => {
    onChange((value as App.Models.UserVoice[]).filter((_, idx) => idx !== index))
  }

  return (
    <Box>
      {(value as App.Models.UserVoice[])?.map((el, index) => (
        <Grid position="relative" key={index}>
          <Grid
            sx={{
              border: 1,
              padding: 2,
              marginBottom: 3,
              borderColor: (theme) => theme.palette.grey[200],
              borderRadius: 1
            }}
          >
            <ClearIcon
              onClick={() => deleteInput(index)}
              sx={{ position: 'absolute', top: 10, right: 17, cursor: 'pointer' }}
            />
            <InputControl
              label="カテゴリー"
              sx={{ width: '50%', mb: 1 }}
              fieldError={error && (error as any)[index]?.category_id}
            >
              <Autocomplete
                disablePortal
                options={optionCategory}
                value={findOption(index) || null}
                getOptionLabel={(option) => (option ? option.label : '')}
                isOptionEqualToValue={(options: SelectOption, value: SelectOption) =>
                  options.value === value.value
                }
                onChange={(event, newValue) =>
                  handleChangeCategory(
                    event as SyntheticEvent<Element, Event>,
                    newValue as SelectOption,
                    index
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} error={error && !!(error as any)[index]?.category_id} />
                )}
                size="small"
                noOptionsText="データなし"
              />
            </InputControl>

            <Stack direction="row" spacing={2}>
              <InputControl
                label="Subject"
                fullWidth
                fieldError={error && (error as any)[index]?.subject}
              >
                <InputStyled
                  value={el.subject || ''}
                  onChange={(newValue) =>
                    handleChangeSub(newValue as React.ChangeEvent<HTMLInputElement>, index)
                  }
                />
              </InputControl>

              <InputControl
                label="Content"
                fullWidth
                fieldError={error && (error as any)[index]?.content}
              >
                <InputStyled
                  value={el.content || ''}
                  onChange={(newValue) =>
                    handleChangeContent(newValue as React.ChangeEvent<HTMLInputElement>, index)
                  }
                />
              </InputControl>
            </Stack>
          </Grid>
        </Grid>
      ))}

      {!!error && (
        <FormHelper error>お客様からの声・クライアントへの課題共有は必須です。</FormHelper>
      )}

      <Grid container justifyContent="center">
        <Button variant="contained" color="warning" onClick={addInput}>
          +追加
        </Button>
      </Grid>
    </Box>
  )
}

export { UserVoices }
