import { DatePickerProps } from '@mui/lab'
import MuiDatePicker from '@mui/lab/DatePicker'
import { FormControlProps, Stack, TextField } from '@mui/material'
import { format, isValid } from 'date-fns'
import { formatISODate, safeParseISO } from 'lib/utils'
import { ChangeEvent } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { AddControlProps, InputControl } from '..'

type ControlProps = AddControlProps & {
  controlProps?: FormControlProps
  fullWidth?: boolean
}

type DatePickerType<T> = UseControllerProps<T> &
  ControlProps &
  Omit<DatePickerProps, 'renderInput' | 'onChange' | 'value'>

function DatetimePickerSeparator<T>({
  name,
  control,
  defaultValue,
  label,
  helperText,
  fullWidth,
  controlProps,
  ...props
}: DatePickerType<T>) {
  const {
    field: { onChange, value, ref, ...inputProps },
    fieldState: { error: fieldError }
  } = useController({ name, control, defaultValue })

  const handleChange = (newValue: unknown) => {
    if (!isValid(newValue)) return onChange('')

    onChange(
      [
        formatISODate(newValue as Date, '/'),
        value ? format(safeParseISO(value as string), 'HH:mm') : '00:00'
      ].join(' ')
    )
  }

  const handleChangeTime = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const time = event.target.value

    onChange(
      [
        value ? formatISODate(safeParseISO(value as string), '/') : formatISODate(new Date(), '/'),
        time
      ].join(' ')
    )
  }

  return (
    <InputControl
      fieldError={fieldError}
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      {...controlProps}
    >
      <Stack direction="row" spacing={1}>
        <MuiDatePicker
          {...props}
          {...inputProps}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          value={value ? format(safeParseISO(value as string), 'yyyy-MM-dd') : null}
          onChange={handleChange}
          inputRef={ref}
          renderInput={(params) => <TextField size="small" {...params} error={!!fieldError} />}
        />

        <TextField
          type="time"
          value={value ? format(safeParseISO(value as string), 'HH:mm') : '00:00'}
          onChange={handleChangeTime}
          inputProps={{
            step: 300 // 5 min
          }}
          InputLabelProps={{
            shrink: true
          }}
          size="small"
          sx={{ width: 150, minWidth: 150 }}
          error={!!fieldError}
        />
      </Stack>
    </InputControl>
  )
}

export { DatetimePickerSeparator }
