import { Box, BoxProps } from '@mui/material'
import { TitleComponentOption } from 'echarts'
import { LineChart as ELineChart, LineSeriesOption } from 'echarts/charts'
import {
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption
} from 'echarts/components'
import type { ComposeOption, ECharts } from 'echarts/core'
import { use } from 'echarts/core'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { merge } from 'lodash'
import { forwardRef } from 'react'
import { CoreChart, CoreChartProps } from './Core'
import { defaultLineOptions } from './defaultOptions'

use([TooltipComponent, LegendComponent, ELineChart, CanvasRenderer, LabelLayout, TitleComponent])

export type LineChartOption = ComposeOption<
  TooltipComponentOption | LegendComponentOption | LineSeriesOption | TitleComponentOption
>

type LineChartProps = BoxProps<'div', CoreChartProps<LineChartOption>> & {
  series?: LineSeriesOption[]
}

const LineChart = forwardRef<ECharts, LineChartProps>(function Line(props, ref) {
  const { options, settings, loading, series, ...boxProps } = props
  const _options = merge({}, defaultLineOptions, options, { series })

  return (
    <Box width="100%" height="100%" {...boxProps}>
      <CoreChart {...{ options: _options, settings, loading }} ref={ref} />
    </Box>
  )
})

export { LineChart }
