import ImageIcon from '@mui/icons-material/Image'
import { AvatarProps, Box, Button, Stack, styled, SxProps, Typography } from '@mui/material'
import { AvatarWithSize } from 'components/Avatar'
import { FileBag, useModalState, useUploader } from 'lib/hooks'
import React, { HTMLInputTypeAttribute, useCallback, useEffect, useState } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import toast from 'react-hot-toast'
import Viewer from 'react-viewer'
import { FormLabel, InputControl } from './Form'

type ImageUploaderProps<T> = AvatarProps<
  'div',
  {
    label?: string
    size?: number
    urlUpload?: string
    inputProps?: HTMLInputTypeAttribute
    layout?: 'avatar' | 'imageBox' | 'row-imageBox'
    disableEmptyText?: boolean
    imageSize?: {
      width: number | string
      height: number | string
    }
    containerSx?: SxProps
  } & UseControllerProps<T>
>

function ImageUploader<T>({
  name,
  label,
  control,
  urlUpload,
  inputProps,
  defaultValue,
  size,
  layout = 'avatar',
  disableEmptyText,
  imageSize,
  containerSx,
  ...props
}: ImageUploaderProps<T>) {
  const { isOpen, onClose, onOpen } = useModalState()
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue })

  const [uploadError, setUploadError] = useState<boolean>()
  const [textError, setTextError] = useState<string>()

  const { onDrop } = useUploader({
    url: urlUpload || 'upload',
    onUploaded(file: FileBag) {
      onChange(file.responseData.link as string)
      setUploadError(false)
      toast.success('更新しました。')
    },
    onFailed() {
      setUploadError(true)
    }
  })

  useEffect(() => {
    if (uploadError) {
      setTextError('アップロード可能な上限サイズを超えています。')
    } else {
      setTextError('')
    }
  }, [uploadError])

  const handleChooseImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement
      onDrop(target.files as FileList)
    },
    [onDrop]
  )

  const Picker = useCallback(
    () => (
      <Stack justifyContent="flex-end">
        <Stack direction="row" alignItems="center" spacing={2}>
          <label htmlFor={`image-uploader-button-${name}`}>
            <HiddenInput
              accept="image/*"
              id={`image-uploader-button-${name}`}
              type="file"
              onChange={handleChooseImage}
              {...inputProps}
            />

            <Button variant="contained" color="info" component="span" disableElevation>
              画像を選択
            </Button>
          </label>

          {!disableEmptyText && !value && (
            <Typography variant="body2" color="grey.600">
              選択されていません
            </Typography>
          )}
        </Stack>
        <Typography variant="caption">最大アップロードサイズ:2MB</Typography>
      </Stack>
    ),
    [disableEmptyText, handleChooseImage, inputProps, name, value]
  )

  const AvatarLayout = useCallback(
    () => (
      <InputControl fieldError={error} helperText={textError}>
        <Stack direction="row" spacing={3} alignItems="center">
          <AvatarWithSize onClick={onOpen} src={value as string} size={size} {...props} />

          <Stack spacing={1}>
            <FormLabel>{label}</FormLabel>
            <Picker />
          </Stack>
        </Stack>
      </InputControl>
    ),
    [Picker, error, label, onOpen, props, size, textError, value]
  )

  const RowImageBoxLayout = useCallback(
    () => (
      <InputControl label={label} fieldError={error} helperText={textError} fullWidth>
        <Stack direction="row" spacing={4}>
          {value ? (
            <Box
              sx={{
                cursor: 'pointer',
                width: imageSize?.width || 120,
                height: imageSize?.height || 120,
                borderRadius: 2
              }}
              onClick={onOpen}
            >
              <img
                src={value as string}
                width={imageSize?.width || 120}
                height={imageSize?.height || 120}
                style={{ objectFit: 'cover', borderRadius: 10 }}
                alt="upload"
              />
            </Box>
          ) : (
            <Box
              borderRadius={2}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: imageSize?.width || 120,
                height: imageSize?.height || 120
              }}
              bgcolor="grey.50"
              component="label"
              htmlFor={`image-uploader-button-${name}`}
            >
              <ImageIcon color="disabled" sx={{ fontSize: '2rem' }} />
              <HiddenInput
                accept="image/*"
                id={`image-uploader-button-${name}`}
                type="file"
                onChange={handleChooseImage}
                {...inputProps}
              />
            </Box>
          )}
          <Picker />
        </Stack>
      </InputControl>
    ),
    [
      Picker,
      error,
      handleChooseImage,
      imageSize?.height,
      imageSize?.width,
      inputProps,
      label,
      name,
      onOpen,
      textError,
      value
    ]
  )

  const ImageBoxLayout = useCallback(
    () => (
      <InputControl label={label} fieldError={error} helperText={textError} fullWidth>
        {value ? (
          <Box
            sx={{
              cursor: 'pointer',
              width: imageSize?.width || 400,
              height: imageSize?.height || 200
            }}
            mb={1}
            onClick={onOpen}
          >
            <img src={value as string} height={imageSize?.height || 200} alt="upload" />
          </Box>
        ) : (
          <Box
            borderRadius={2}
            mb={1}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: imageSize?.width || 400,
              height: imageSize?.height || 200
            }}
            bgcolor="grey.50"
            component="label"
            htmlFor={`image-uploader-button-${name}`}
          >
            <ImageIcon color="disabled" sx={{ fontSize: '2rem' }} />
            <Typography color="grey.300" sx={{ cursor: 'inherit' }} ml={1}>
              選択されていません
            </Typography>
            <HiddenInput
              accept="image/*"
              id={`image-uploader-button-${name}`}
              type="file"
              onChange={handleChooseImage}
              {...inputProps}
            />
          </Box>
        )}
        <Picker />
      </InputControl>
    ),
    [
      Picker,
      error,
      handleChooseImage,
      imageSize?.height,
      imageSize?.width,
      inputProps,
      label,
      name,
      onOpen,
      textError,
      value
    ]
  )

  const renderLayout = () => {
    switch (layout) {
      case 'avatar':
        return <AvatarLayout />

      case 'row-imageBox':
        return <RowImageBoxLayout />
      default:
        return <ImageBoxLayout />
    }
  }

  return (
    <>
      <Box sx={containerSx}>{renderLayout()}</Box>

      <Viewer
        zIndex={1600}
        visible={isOpen}
        onClose={onClose}
        drag={false}
        noFooter
        onMaskClick={onClose}
        images={[
          {
            src: value as string,
            alt: 'avatar'
          }
        ]}
      />
    </>
  )
}

const HiddenInput = styled('input')({
  display: 'none'
})

export { ImageUploader }
