import ClearIcon from '@mui/icons-material/Clear'
import {
  Container,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { AvatarWithSize } from 'components/Avatar'
import { DialogBase } from 'components/Dialog'
import { ModalProps } from 'lib/types'
import { useState, VFC } from 'react'
import { languageEnum } from 'screens/new/NewPreviewModal'

export type InstructorModal = ModalProps<App.Models.Instructor>

const InstructorPreview: VFC<InstructorModal> = ({ open, onClose, ...data }) => {
  const [language, setLanguage] = useState(languageEnum.JA)

  const isEn = language === languageEnum.Eng

  const handleChangeLanguage = (event: React.MouseEvent<HTMLElement>, newLang: number) => {
    setLanguage(newLang)
  }
  return (
    <DialogBase open={open} maxWidth="md">
      <ClearIcon
        onClick={onClose}
        sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 20 }}
      />
      <ToggleButtonGroup
        value={language}
        exclusive
        sx={{ height: 30, position: 'sticky', top: '50%', left: 10 }}
        orientation="vertical"
        onChange={handleChangeLanguage}
      >
        <ToggleButton value={languageEnum.Eng} aria-label="English">
          <Typography width={30} variant="subtitle1">
            Eng
          </Typography>
        </ToggleButton>
        <ToggleButton value={languageEnum.JA} aria-label="Japan">
          <Typography width={30} variant="subtitle1">
            JA
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>

      <Container disableGutters maxWidth="sm">
        <Stack direction="row" spacing={2} alignItems="center" mb={5}>
          <AvatarWithSize size={148} src={data?.image_path as string} />

          <Stack flexGrow={1} flexBasis={{ md: 700, sm: 'auto' }}>
            <Typography
              variant="body2"
              color="grey.600"
              component="div"
              dangerouslySetInnerHTML={{
                __html: isEn ? data?.company_name_en : data?.company_name
              }}
            />
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" mr={1}>
                {data?.display_name}
              </Typography>
              <Typography variant="h6" color="grey.600">
                {data?.display_name_en}
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
              component="div"
              dangerouslySetInnerHTML={{
                __html: isEn ? data?.profile_en : data?.profile
              }}
            />
          </Stack>
        </Stack>

        <Divider />
        <Typography variant="h6" fontWeight="bold">
          業務情報
        </Typography>

        <Stack ml={2} spacing={1}>
          <Typography>{data?.demand_name}</Typography>

          <Typography>{data?.demand_company_name}</Typography>

          <Typography>{data?.email}</Typography>

          <Typography>{data?.tel}</Typography>

          <Typography>{data?.notes}</Typography>
        </Stack>
      </Container>
    </DialogBase>
  )
}

export default InstructorPreview
