import { Box, Button, Stack, Typography } from '@mui/material'
import { Select } from 'components/Form'
import { ReactTable } from 'components/ReactTable'
import { format } from 'date-fns'
import { usePaginationQuery } from 'lib/hooks'
import { request } from 'lib/request'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { Column } from 'react-table'

type WRTotalForm = {
  id: number
  fd_ticket_id: number
  subject: string
  company_name: string
  admin_name: string
  total_working_time: number
}

type Date = {
  label: string
  value: string
}

const WorkingRecordTotal: React.VFC = () => {
  const { control, watch, setValue } = useForm()
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const { data: dateWorkingRecord } = useQuery<Date[]>(['/get-ticket-created', { per_page: -1 }])
  const dateOptions = dateWorkingRecord?.map((d) => ({
    label: d.label,
    value: d.value
  }))

  const date = watch('date')
  const lastDate = dateWorkingRecord?.find((d, index) => index == 0)?.value

  useEffect(() => {
    if (lastDate) {
      setValue('date', lastDate)
    }
  }, [lastDate, setValue])

  const { paginationData, queries } = usePaginationQuery<WRTotalForm>(
    `total-working-record`,
    {
      date: date
    },
    !!date
  )
  const columns = useMemo<Column<WRTotalForm>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'fd_ticket_id',
        width: 50
      },
      {
        Header: 'Freshdesk タイトル',
        accessor: 'subject',
        width: 400
      },
      {
        Header: '企業名',
        accessor: 'company_name',
        width: 200
      },
      {
        Header: '担当者',
        accessor: 'admin_name',
        width: 200
      },
      {
        Header: '合計時間',
        accessor: 'total_working_time',
        Cell: ({ value }) =>
          value ? <Typography>{value}分</Typography> : <Typography>未記入</Typography>,
        width: 80
      }
    ],
    []
  )

  const exportCSV = async () => {
    try {
      const month = date.split(',')
      setExportLoading(true)

      const res = await request.get('/export-total-working-records', {
        responseType: 'blob',
        params: { ...queries, per_page: 10000 }
      })

      setExportLoading(false)

      const blob = res.data
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `working-record-total_${format(new Date(month[0]), 'yyyyMM')}.csv`
      )
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      setExportLoading(false)
      toast.error('ダウンロードに失敗しました。')
    }
  }

  return (
    <>
      <Box mb={2}>
        <Select name="date" control={control} options={dateOptions} sx={{ minWidth: 170 }} />
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="end" mb={{ md: 3 }}>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          sx={{ bgcolor: (theme) => theme.palette.common.white, mr: 1 }}
          onClick={exportCSV}
          disabled={exportLoading}
        >
          CSVエクスポート
        </Button>
      </Stack>

      <ReactTable<WRTotalForm> columns={columns} {...paginationData} />
    </>
  )
}

export { WorkingRecordTotal }
