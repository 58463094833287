import { Box, BoxProps, Paper, PaperProps, Stack, Typography } from '@mui/material'
import { RefObject } from 'react'

type PageProps = {
  title?: React.ReactNode
  leftHeader?: React.ReactNode
  containerProps?: BoxProps
  innerRef?: RefObject<HTMLDivElement | null>
} & Omit<PaperProps, 'title'>

const Page: React.VFC<PageProps> = ({
  children,
  title,
  leftHeader,
  containerProps,
  innerRef,
  ...paperProps
}) => {
  return (
    <Box ref={innerRef} {...containerProps}>
      {title && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={{ md: 3 }}>
          {typeof title === 'string' ? (
            <Typography variant="inherit" sx={{ fontSize: 26, fontWeight: 700 }}>
              {title}
            </Typography>
          ) : (
            title
          )}

          {leftHeader}
        </Stack>
      )}
      <Paper elevation={1} sx={{ p: 3, height: '100%', borderRadius: 2 }} {...paperProps}>
        {children}
      </Paper>
    </Box>
  )
}

export { Page }
