import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Container, Stack, Typography } from '@mui/material'
import Logo from 'assets/images/logo.png'
import { Input } from 'components/Form'
import { changePasswordApi } from 'lib/api'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

export type ChangePasswordType = {
  password: string
  confirm_password: string
  token: string[] | string
}

export type ChangePasswordError = {
  message: string
}

const changePassValidate = yup.object({
  password: yup
    .string()
    .required('パスワードは必須入力です。')
    .min(8, 'パスワードは8文字以上で入力して下さい。')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/,
      'パスワードは半角英数字を混ぜたものを指定してください。'
    ),
  confirm_password: yup
    .string()
    .required('パスワードの確認は必須入力です。')
    .oneOf([yup.ref('password'), null], 'パスワードの確認が無効です')
})

const ChangePassword: React.VFC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = useForm<ChangePasswordType>({
    defaultValues: {
      password: '',
      confirm_password: '',
      token: ''
    },
    resolver: yupResolver(changePassValidate)
  })

  useEffect(() => {
    if (searchParams.get('token')) {
      setValue('token', searchParams.get('token') as string)
    }
  }, [searchParams, setValue])

  const onSubmit: SubmitHandler<ChangePasswordType> = async (values) => {
    try {
      await changePasswordApi(values)
      toast.success('更新しました。')
      navigate('/login')
    } catch (error) {
      if (error.message) {
        setError((error as ChangePasswordError).message)
      }
      if (error.errors) {
        toast.error('更新に失敗しました。')
      }
    }
  }

  return (
    <Container maxWidth="xs">
      <Stack alignItems="center" mt={8}>
        <img src={Logo} alt="logo" />

        <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)} noValidate>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          <Stack spacing={2} mb={3}>
            <Stack>
              <Input
                fullWidth
                label="パスワード"
                name="password"
                type="password"
                control={control}
              />
              <Typography variant="caption" color="grey.A400">
                パスワードは英大文字、英小文字、数字、記号を混ぜて8文字以上で指定してください。
              </Typography>
            </Stack>

            <Stack>
              <Input
                fullWidth
                label="パスワードの確認"
                name="confirm_password"
                type="password"
                control={control}
              />
              <Typography variant="caption" color="grey.A400">
                パスワードは英大文字、英小文字、数字、記号を混ぜて8文字以上で指定してください。
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              変更する
            </LoadingButton>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export { ChangePassword }
