import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import {
  CheckBox,
  Input,
  InputTag,
  RawInput,
  RichText,
  Select,
  StyledCheckedIcon,
  StyledIcon
} from 'components/Form'
import { Page } from 'components/Layouts'
import { FileBag, useApiResource, useUploader } from 'lib/hooks'
import { CustomerCompany, GroupType, ServerValidateError } from 'lib/types'
import { handleValidateErrors } from 'lib/utils'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

export type FormCompanyValues = Omit<
  App.Models.Company,
  'parent_company' | 'company_report' | 'users' | 'company_report_contents' | 'child_company'
> & {
  res_fd_company_id: number
  parent_company: Omit<
    App.Models.Company,
    'parent_company' | 'company_report' | 'users' | 'company_report_contents' | 'child_company'
  >
}

const FormCompany: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()

  const validateCompany = yup.object({
    name: yup.string().required('企業名は必須です。').trim(),
    res_fd_company_id: yup
      .number()
      .typeError('Freshdesk Company Idが正しい形式ではありません。')
      .required('Freshdesk Company Idは必須です。'),
    address: yup.string().required().label('住所').trim(),
    privacy_policy_path: yup.string().required().label('Privacy Policy'),
    service_policy_path: yup.string().required().label('Terms of Service'),
    report_title: yup
      .string()
      .required()
      .label('月次レポートタイトル')
      .max(255, '入力は255文字までです。')
      .trim()
  })

  const { createOrUpdateApi } = useApiResource<FormCompanyValues>('companies')
  const isEdit = !!params?.id

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<FormCompanyValues>({
    defaultValues: {
      id: Number(params?.id) || undefined,
      name: '',
      address: '',
      invitation_code: '',
      logo_path: '',
      parent_company_id: undefined,
      contact_detail: '',
      privacy_policy_path: '',
      service_policy_path: '',
      faq: '',
      faq_en: '',
      groups: [],
      domains: [],
      is_report: false,
      report_title: '',
      res_fd_company_id: undefined
    },
    resolver: yupResolver(validateCompany)
  })

  const homeContentValues = watch('home_contents')
  const logo_path = watch('logo_path')
  const privacy = watch('privacy_policy_path')
  const service = watch('service_policy_path')

  const [checked, setChecked] = React.useState(false)

  const { data } = useQuery<FormCompanyValues>([`companies/${params?.id}`], {
    onSuccess: (data) => {
      const instanceKeys: Array<keyof FormCompanyValues> = ['groups', 'domains', 'home_contents']
      let name: keyof FormCompanyValues
      for (name in data) {
        if (instanceKeys.includes(name)) {
          setValue(
            name,
            (
              data[name] as
                | Omit<App.Models.Group, 'companies'>[]
                | Omit<App.Models.CompanyDomain, 'company'>[]
            )?.map((el) =>
              name === 'domains' ? (el as Omit<App.Models.CompanyDomain, 'company'>).domain : el.id
            ) as unknown as
              | Omit<App.Models.Group, 'companies'>[]
              | Omit<App.Models.CompanyDomain, 'company'>[]
          )
        } else {
          setValue(name, data[name])
          if (name == 'show_ticket_warning_limit') {
            setChecked(data[name] || false)
          }
        }
      }
    },
    enabled: isEdit
  })

  const { data: homeContents } = useQuery<App.Models.HomeContent[]>([
    'home-contents',
    { per_page: -1, sort: 'id|asc' }
  ])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const onSubmit: SubmitHandler<FormCompanyValues> = async (values) => {
    try {
      const ticketWarningLimit = getValues('ticket_warning_limit') || ''

      if (checked && ticketWarningLimit == '') {
        setError('ticket_warning_limit', { message: '件数は有効である必要があります。' })

        return
      }

      if (ticketWarningLimit != '' && isNaN(parseInt(ticketWarningLimit))) {
        setError('ticket_warning_limit', { message: '件数 が正しい形式ではありません。' })

        return
      }

      await createOrUpdateApi({ ...values, show_ticket_warning_limit: checked })
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/company')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const { onDrop } = useUploader({
    url: 'upload',
    onUploaded: (file: FileBag) => {
      const name = file.extraMeta?.name as keyof FormCompanyValues
      setValue(name, file.responseData.link as never)
      clearErrors(name)
    },
    onFailed(file: FileBag) {
      const name = file.extraMeta?.name as keyof FormCompanyValues
      const message = (file.responseData as ServerValidateError<{ file: string }>).errors.file
      setError(name, { message })
    }
  })

  const { onDrop: onDropPdf } = useUploader({
    url: 'upload-pdf',
    onUploaded: (file: FileBag) => {
      const name = file.extraMeta?.name as keyof FormCompanyValues
      setValue(name, file.responseData.link as never)
      clearErrors(name)
    },
    onFailed(file: FileBag) {
      const name = file.extraMeta?.name as keyof FormCompanyValues
      const message = (file.responseData as ServerValidateError<{ file: string }>).errors.file
      setError(name, { message })
    }
  })

  const handleChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const name = event.target.name
    if (name === 'logo_path') {
      onDrop(target.files as FileList, { name })
    } else {
      onDropPdf(target.files as FileList, { name })
    }
  }

  const handleChangeHomeContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.name)
    const checked = event.target.checked
    const values = (getValues('home_contents') as Array<App.Models.HomeContent>) || []

    if (checked) {
      setValue('home_contents', [...values, id] as Array<App.Models.HomeContent>)
    } else {
      setValue(
        'home_contents',
        values?.filter((v) => (v as unknown as number) !== id)
      )
    }
  }

  return (
    <Page title={isEdit ? '企業編集' : '企業新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Typography variant="h5" mb={2}>
            基本情報
          </Typography>
          <Input fullWidth label="企業名" name="name" control={control} />
          <Input
            fullWidth
            label="Freshdesk Company Id"
            name="res_fd_company_id"
            control={control}
          />
          <Input fullWidth label="住所" name="address" control={control} />
          <InputTag name="domains" label="ユーザー登録可能ドメイン" fullWidth control={control} />

          <Input fullWidth label="招待コード" control={control} name="invitation_code" />

          <Select<GroupType>
            name="groups"
            label="グループ"
            fullWidth
            control={control}
            multiple
            query="groups"
          />

          <Select<CustomerCompany>
            name="parent_company_id"
            label="親会社（Freshdeskデータ連携）"
            fullWidth
            control={control}
            query="companies"
          />

          <RawInput
            label="企業ロゴ（上書きの場合アップロード）"
            type="file"
            variant="base"
            name="logo_path"
            onChange={handleChooseFile}
            fieldError={errors.logo_path}
            layout="image"
            value={logo_path}
          />

          <RichText
            control={control}
            name="contact_detail"
            label="お問い合わせ先"
            defaultValue={data?.contact_detail}
          />

          <RawInput
            label="Privacy Policy（上書きの場合アップデート）"
            type="file"
            variant="base"
            name="privacy_policy_path"
            onChange={handleChooseFile}
            fieldError={errors.privacy_policy_path}
            value={privacy}
            layout="pdf"
          />

          <RawInput
            label="Terms of Service（上書きの場合アップデート）"
            type="file"
            variant="base"
            name="service_policy_path"
            onChange={handleChooseFile}
            fieldError={errors.service_policy_path}
            value={service}
            layout="pdf"
          />

          <RichText
            control={control}
            name="faq"
            label="FAQ（上書きの場合アップデート）"
            defaultValue={data?.faq}
          />
          <RichText
            control={control}
            name="faq_en"
            label="FAQ（英語）"
            defaultValue={data?.faq_en}
          />

          <Typography variant="h5" fontWeight="700" mb={2}>
            Home表示コンテンツ項目
          </Typography>

          {homeContents?.map((c) => (
            <FormControlLabel
              key={c.id}
              control={
                <Checkbox
                  name={c.id.toString()}
                  onChange={handleChangeHomeContent}
                  checkedIcon={<StyledCheckedIcon />}
                  icon={<StyledIcon />}
                />
              }
              label={c.name}
              checked={
                (
                  (getValues('home_contents') || homeContentValues) as unknown as number[]
                )?.includes(c.id) || false
              }
              labelPlacement="start"
              sx={{
                justifyContent: 'start',
                '& .MuiTypography-root': { fontSize: 15, fontWeight: 500 }
              }}
            />
          ))}

          <Typography variant="h5" fontWeight="700" mb={2}>
            月次レポート
          </Typography>

          <CheckBox
            controlProps={{
              sx: { display: 'flex', flexFlow: 'row-reverse', justifyContent: 'start' }
            }}
            control={control}
            name="is_report"
            label="月次レポート"
          />
          <Input control={control} label="月次レポートタイトル" fullWidth name="report_title" />

          <Typography variant="h5" fontWeight="700" mb={2}>
            相談件数アラート
          </Typography>

          <Grid container justifyContent="start" alignItems="center">
            <Typography variant="h5" fontWeight="500" fontSize={15}>
              アラート：
            </Typography>
            <Switch checked={checked} onChange={handleChange} color="primary" />
            <Typography variant="h5" fontWeight="500" fontSize={15}>
              {checked ? 'On' : 'Off'}
            </Typography>
          </Grid>

          <Input
            fontWeightLabel={400}
            control={control}
            label="件数（下記数値以上でアラートを表示する）"
            fullWidth
            name="ticket_warning_limit"
          />
        </Stack>

        <Grid container justifyContent="center">
          {params?.id ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export { FormCompany }
