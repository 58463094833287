import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Container, Grid, Stack } from '@mui/material'
import App from 'App'
import { Input, Select } from 'components/Form'
import { Page } from 'components/Layouts'
import { useApiResource } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

type GroupType = Omit<App.Models.Group, 'companies'> & {
  companies: Array<Omit<App.Models.Company, 'parent_company' | 'users' | 'groups'>>
}

const validateGroup = yup.object({
  name: yup.string().required().label('グループ名').trim()
})

const FormGroup: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const isEdit = !!params?.id

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting }
  } = useForm<GroupType>({
    defaultValues: {
      id: Number(params?.id),
      name: '',
      description: '',
      companies: []
    },
    resolver: yupResolver(validateGroup)
  })

  useQuery<GroupType>([`groups/${params.id}`], {
    onSuccess: (data) => {
      const instanceKeys: Array<keyof GroupType> = ['companies']
      let name: keyof GroupType
      for (name in data) {
        if (instanceKeys.includes(name)) {
          setValue(
            name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            data[name]?.map((el) => el.id)
          )
        } else {
          setValue(name, data[name])
        }
      }
    },
    enabled: isEdit
  })

  const { createOrUpdateApi } = useApiResource<GroupType>('groups')

  const onSubmit: SubmitHandler<GroupType> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/group')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title={isEdit ? 'グループ編集' : 'グループ新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Input fullWidth label="グループ名" name="name" control={control} />

          <Input
            fullWidth
            label="グループ説明"
            name="description"
            control={control}
            multiline
            rows="4"
          />

          <Select<App.Models.Company>
            name="companies"
            label="対象企業"
            fullWidth
            control={control}
            multiple
            query="companies"
          />
        </Stack>

        <Grid container justifyContent="center">
          {params?.id ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export { FormGroup }
