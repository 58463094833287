import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { DialogBase } from 'components/Dialog'
import { ModalProps } from 'lib/types'
import { useState, VFC } from 'react'
import { languageEnum } from 'screens/new/NewPreviewModal'

export type ConciergePreviewModal = ModalProps<App.Models.Concierge>

const ConciergePreview: VFC<ConciergePreviewModal> = ({ open, onClose, ...data }) => {
  const [language, setLanguage] = useState(languageEnum.JA)

  const isEng = language === languageEnum.Eng

  const handleChangeLanguage = (event: React.MouseEvent<HTMLElement>, newLang: number) => {
    setLanguage(newLang)
  }

  return (
    <DialogBase open={open}>
      <ClearIcon
        onClick={onClose}
        sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 20 }}
      />
      <ToggleButtonGroup
        value={language}
        exclusive
        sx={{ height: 30, position: 'sticky', top: '50%', left: 10 }}
        orientation="vertical"
        onChange={handleChangeLanguage}
      >
        <ToggleButton value={languageEnum.Eng} aria-label="English">
          <Typography width={30} variant="subtitle1">
            Eng
          </Typography>
        </ToggleButton>
        <ToggleButton value={languageEnum.JA} aria-label="Japan">
          <Typography width={30} variant="subtitle1">
            JA
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <Container disableGutters maxWidth="md">
        <Grid container mb={1}>
          <Grid item md={3} xs={12}>
            <img
              src={data?.image_path}
              alt="events"
              width={148}
              height={148}
              style={{ borderRadius: 74, objectFit: 'cover' }}
            />
          </Grid>

          <Grid item container xs={12} md={9} alignItems="end" mb={2}>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="flex-end" mb={3}>
                <Typography variant="h4" fontWeight="bold">
                  {data?.name}
                </Typography>

                <Typography color="grey.600" ml={1}>
                  {data?.name_en}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" color="grey.600">
                  {data?.languages.join('/')}
                </Typography>

                <Box>
                  {data.strong_fields &&
                    data.strong_fields.map((field: string) => (
                      <Chip
                        size="small"
                        label={field}
                        key={field}
                        clickable
                        sx={{ borderRadius: 1, ml: 1 }}
                      />
                    ))}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 5 }} />

        <Grid item xs={12}>
          <Typography
            mb={4}
            component="div"
            sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
            dangerouslySetInnerHTML={{ __html: isEng ? data?.profile_en : data?.profile }}
          />
        </Grid>
      </Container>
    </DialogBase>
  )
}

export default ConciergePreview
