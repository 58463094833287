import { DatePicker } from '@mui/lab'
import { Button, Stack, TextField } from '@mui/material'
import { mainRef } from 'components/Layouts'
import { usePDFExport } from 'lib/hooks'
import { formatISODate, yearMonthFormat } from 'lib/utils'
import { CompanyMonthlyReportType } from '..'

export type RightHeaderProps = {
  date?: Date | null
  onChange: (_date: Date | null) => void
  data?: CompanyMonthlyReportType
}

const RightHeader: React.VFC<RightHeaderProps> = ({ date, data, onChange }) => {
  const { exportPDF } = usePDFExport({
    element: mainRef.current,
    filename: (data?.company?.name + '-' + formatISODate(date as Date, '/')) as string
  })

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        sx={{ bgcolor: (theme) => theme.palette.common.white }}
        color="inherit"
        onClick={exportPDF}
      >
        PDFエクスポート
      </Button>

      <DatePicker
        value={date}
        onChange={onChange}
        openTo="month"
        views={['year', 'month']}
        renderInput={(params) => (
          <TextField
            sx={{ bgcolor: (theme) => theme.palette.common.white, maxWidth: 150 }}
            size="small"
            {...params}
          />
        )}
        inputFormat={yearMonthFormat}
        disableMaskedInput
      />
    </Stack>
  )
}

export { RightHeader }
