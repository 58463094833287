import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { PDF_MARGIN } from 'lib/constants'
import { useState } from 'react'

type Options = {
  element: HTMLDivElement | null
  filename?: string
}

const proxyENV = import.meta.env.VITE_HTML2CANVAS_PROXY as string

const proxyUrl =
  proxyENV === 'staging'
    ? 'https://staging-admin.yourconcierge.jp/html2canvas-proxy'
    : proxyENV === 'production'
    ? 'https://admin.yourconcierge.jp/html2canvas-proxy'
    : 'http://localhost:3000/html2canvas-proxy'

const usePDFExport = ({ element, filename }: Options) => {
  const [loading, setLoading] = useState(false)

  const exportPDF = () => {
    if (element) {
      const pdf = new jsPDF('p', 'px', 'a4', true)
      let heightLeft = element.offsetHeight
      setLoading(true)

      html2canvas(element, {
        useCORS: false,
        proxy: proxyUrl,
        allowTaint: false,
        scale: window.devicePixelRatio
      })
        .then((canvas) => {
          const canvasWidth = canvas.width
          const canvasHeight = canvas.height
          heightLeft = Math.max(canvasHeight, heightLeft)
          const pdfWidth = pdf.internal.pageSize.width
          const pdfHeight = pdf.internal.pageSize.height
          const widthRatio = pdfWidth / canvasWidth
          const sX = 0
          const sWidth = canvasWidth
          const sHeight = pdfHeight + (pdfHeight - pdfHeight * widthRatio) / widthRatio
          const dX = 0
          const dY = 0
          const dWidth = sWidth
          const dHeight = sHeight

          let pageCnt = 1
          while (heightLeft > 0) {
            heightLeft -= sHeight
            const sY = sHeight * (pageCnt - 1)
            const childCanvas = document.createElement('canvas')
            childCanvas.setAttribute('width', sWidth + 'px')
            childCanvas.setAttribute('height', sHeight + 'px')
            const childCanvasCtx = childCanvas.getContext('2d')
            ;(childCanvasCtx as CanvasRenderingContext2D).drawImage(
              canvas,
              sX,
              sY,
              sWidth,
              sHeight,
              dX,
              dY,
              dWidth,
              dHeight
            )
            if (pageCnt > 1) {
              pdf.addPage()
            }
            pdf.setPage(pageCnt)
            pdf.addImage(
              childCanvas.toDataURL('image/png'),
              'PNG',
              PDF_MARGIN.X * 3,
              PDF_MARGIN.Y,
              canvas.width * widthRatio - PDF_MARGIN.X * 6,
              dHeight * widthRatio - PDF_MARGIN.Y * 2 - 3
            )
            pageCnt++
          }
          pdf.save(filename ? `${filename}.pdf` : 'download.pdf')
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    } else {
      console.log('No element export!')
    }
  }

  return { exportPDF, loading }
}

export { usePDFExport }
