import { Box, Stack, Typography } from '@mui/material'
import { FormDetail, Tag } from 'components/Form'
import { Page } from 'components/Layouts'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const GroupDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.Group>([`groups/${params?.id}`])

  return (
    <Page title="グループ詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="グループ名">
          <Typography>{data?.name}</Typography>
        </FormDetail>

        <FormDetail label="グループ名">
          <Typography>{data?.description}</Typography>
        </FormDetail>

        <FormDetail label="対象企業">
          <Stack direction="row" spacing={1}>
            <Box sx={{ wordBreak: 'break-word' }}>
              {data?.companies &&
                data?.companies?.map((group) => (
                  <Tag label={group.name} key={group.id} sx={{ m: 0.5 }} />
                ))}
            </Box>
          </Stack>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { GroupDetail }
