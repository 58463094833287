import { LineChartOption } from '.'
import { BarChartOption } from './BarChart'
import { PieChartOption } from './PieChart'

export const defaultPieOptions: PieChartOption = {
  tooltip: {
    trigger: 'item'
  },
  title: {
    top: '5%',
    left: 'auto',
    textStyle: {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(', ')
    }
  },
  legend: {
    top: '5%',
    left: 'center',
    icon: 'circle'
  },
  series: {
    type: 'pie',
    radius: ['25%', '70%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    emphasis: {
      label: {
        show: true,
        fontSize: '18',
        fontWeight: 'bold'
      }
    },
    labelLine: {
      show: false
    },
    data: []
  }
}

export const defaultBarOptions: BarChartOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  title: {
    textStyle: {
      fontSize: 16,
      fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(', ')
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  }
}

export const defaultLineOptions: LineChartOption = {
  tooltip: {
    trigger: 'item'
  },
  title: {
    textStyle: {
      fontSize: 18,
      fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(', ')
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false
  },
  yAxis: {
    type: 'value'
  }
}
