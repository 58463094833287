import { Stack, Typography } from '@mui/material'
import { FormDetail, Tag } from 'components/Form'
import { Page } from 'components/Layouts'
import { statusNew } from 'lib/constants'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

const NewDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.News>([`news/${params.id}`])

  return (
    <Page title="ニュース詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="タイトル">
          <Typography>{data?.title}</Typography>
        </FormDetail>

        <FormDetail label="アイキャッチ画像">
          {data?.image_path && (
            <img
              src={data?.image_path}
              alt="logo"
              width={150}
              height={150}
              style={{ objectFit: 'cover' }}
            />
          )}
        </FormDetail>

        <FormDetail label="詳細">
          {data?.detail && <div dangerouslySetInnerHTML={{ __html: data.detail }} />}
        </FormDetail>

        <FormDetail label="英語タイトル">
          <Typography>{data?.title_en}</Typography>
        </FormDetail>

        <FormDetail label="英語詳細">
          {data?.detail_en && <div dangerouslySetInnerHTML={{ __html: data.detail_en }} />}
        </FormDetail>

        <FormDetail label="配信グループ">
          <Stack direction="row" spacing={1}>
            {(data?.groups as App.Models.Group[])?.map((group: App.Models.Group) => (
              <Tag label={group.name} key={group.id} />
            ))}
          </Stack>
        </FormDetail>
        <FormDetail label="配信期間">
          <Typography>
            {data?.status && statusNew.find((s) => s.value === data.status)?.label}
          </Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { NewDetail }
