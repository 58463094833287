import {
  Pagination as MPagination,
  PaginationProps as MPaginationProps,
  TablePagination
} from '@mui/material'
import { ReactElement, useCallback } from 'react'
import { TableInstance } from 'react-table'
import TablePaginationActions from './PaginationAction'

type PaginationProps<T extends object> = {
  instance: TableInstance<T>
  type?: 'table' | 'normal'
  nPaginationProps?: MPaginationProps
}

function Pagination<T extends object>({
  instance,
  type = 'table',
  nPaginationProps
}: PaginationProps<T>): ReactElement {
  const {
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize }
  } = instance

  const handleChangePage = useCallback(
    (_, newPage: number) => {
      if (newPage === pageIndex + 1) {
        nextPage()
      } else if (newPage === pageIndex - 1) {
        previousPage()
      } else {
        gotoPage(newPage)
      }
    },
    [gotoPage, nextPage, pageIndex, previousPage]
  )

  const handleChangeRowsPerPage = useCallback(
    (e) => {
      setPageSize(Number(e.target.value))
    },
    [setPageSize]
  )

  const handleChangeNormalPage = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value - 1)
  }

  if (Math.ceil(pageCount / pageSize) === 1 && pageCount <= 10) return <></>

  return type === 'table' ? (
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, { label: 'All', value: 10000 }]}
      count={pageCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page'
        },
        native: true
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      sx={{ border: 'none' }}
      component="div"
    />
  ) : (
    <MPagination
      count={Math.ceil(pageCount / pageSize)}
      color="primary"
      page={pageIndex + 1}
      onChange={handleChangeNormalPage}
      {...nPaginationProps}
    />
  )
}

export { Pagination }
