import { Stack, Typography } from '@mui/material'
import { FormDetail } from 'components/Form'
import { Page } from 'components/Layouts'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

const InstructorDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.Instructor>([`instructors/${params?.id}`])

  return (
    <Page title="イベント講師詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="プロフィール画像">
          <img
            src={data?.image_path}
            alt="logo"
            width={150}
            height={150}
            style={{ objectFit: 'cover' }}
          />
        </FormDetail>

        <FormDetail label="会社名・肩書き（日本語）">
          <Typography>{data?.company_name}</Typography>
        </FormDetail>

        <FormDetail label="会社名・肩書き（English）">
          <Typography>{data?.company_name_en}</Typography>
        </FormDetail>

        <FormDetail label="表示名（日本語）">
          <Typography>{data?.display_name}</Typography>
        </FormDetail>

        <FormDetail label="表示名（English）">
          <Typography>{data?.display_name_en}</Typography>
        </FormDetail>

        <FormDetail label="URL">
          <Typography>{data?.url}</Typography>
        </FormDetail>

        <FormDetail label="プロフィール（日本語）">
          <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {data?.profile}
          </Typography>
        </FormDetail>

        <FormDetail label="プロフィール（English）">
          <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {data?.profile_en}
          </Typography>
        </FormDetail>

        <Typography variant="h5" fontWeight="bold">
          業務情報
        </Typography>

        <FormDetail label="請求先 氏名">
          <Typography>{data?.demand_name}</Typography>
        </FormDetail>

        <FormDetail label="請求先 会社名">
          <Typography>{data?.demand_company_name}</Typography>
        </FormDetail>

        <FormDetail label="メールアドレス">
          <Typography>{data?.email}</Typography>
        </FormDetail>

        <FormDetail label="電話番号">
          <Typography>{data?.tel}</Typography>
        </FormDetail>

        <FormDetail label="注意事項">
          <Typography>{data?.notes}</Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { InstructorDetail }
