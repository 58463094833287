import { yupResolver } from '@hookform/resolvers/yup'
import ClearIcon from '@mui/icons-material/Clear'
import { LoadingButton } from '@mui/lab'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { DialogBase } from 'components/Dialog'
import { DatePicker, Input, Select } from 'components/Form'
import { format } from 'date-fns'
import { operations } from 'lib/constants'
import { useApiResource, useAuth } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import * as yup from 'yup'

const schema = yup.object({
  working_time: yup
    .number()
    .typeError('作業時間が正しい形式ではありません。')
    .min(1, '1より大きい値を入力してください。')
    .required('作業時間は必須です。')
    .test({
      name: 'divisible by 5',
      test: (value: number | undefined) => !!value && value % 5 === 0,
      message: '5分単位で入力してください。',
      exclusive: true
    }),
  working_date: yup
    .date()
    .required('対応日は必須です。')
    .typeError('正しい日付形式を入力してください。'),
  operation_id: yup.string().required('作業内容を選択してください。'),
  fd_ticket_id: yup.string().required('Freshdesk チケットIDを選択してください。')
})

export type ModalProps<T> = {
  open: boolean
  onClose(): void
  editID?: number
} & T

export type Props = ModalProps<{
  refetchWR: () => void
}>

const WorkingRecordDialog: React.VFC<Props> = ({ open, onClose, refetchWR, editID }) => {
  const edited = !!editID
  const { user } = useAuth()

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { isSubmitting }
  } = useForm<Omit<App.Models.WorkingRecord, 'fd_ticket'>>({
    defaultValues: {
      id: Number(editID) || undefined,
      fd_ticket_id: undefined,
      admin_id: undefined,
      working_date: undefined,
      working_time: undefined,
      operation_id: undefined
    },
    resolver: yupResolver(schema)
  })

  useQuery<Omit<App.Models.WorkingRecord, 'fd_ticket'>>([`working-records/${editID}`], {
    onSuccess: (data) => {
      let name: keyof Omit<App.Models.WorkingRecord, 'fd_ticket'>
      for (name in data) {
        setValue(name, data[name])
      }
    },
    enabled: edited
  })

  const { createOrUpdateApi } = useApiResource<App.Models.WorkingRecord>('working-records')

  const { data: fdTicket } = useQuery<App.Models.FdTicket[]>([
    `fd-tickets-researcher`,
    {
      all: 1,
      per_page: -1,
      'custom_fields->cf_sub_category_notEqual': 'TPO Event,Replay Event',
      working_ticket: 1
    }
  ])
  const optionFdTicket = fdTicket?.map((fd) => ({
    label: fd?.fd_ticket_id + ': ' + fd?.subject,
    value: fd.id
  }))

  const onSubmit: SubmitHandler<App.Models.WorkingRecord> = async (values) => {
    const _values = {
      ...values,
      admin_id: user?.id as number,
      working_date: format(new Date(values['working_date']), 'yyyy/MM/dd')
    }
    try {
      await createOrUpdateApi(_values)
      toast.success(edited ? '更新しました。' : '登録しました。')
      refetchWR()
      reset()
      onClose()
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(edited ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <DialogBase open={open} fullHeight={false} maxWidth="md">
      <ClearIcon
        onClick={handleClose}
        sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 20 }}
      />
      <Typography variant="h3" sx={{ marginLeft: 10 }}>
        {edited ? '稼働時間変更' : '稼働時間追加'}
      </Typography>

      <Container
        maxWidth="sm"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        sx={{ p: 3 }}
      >
        <Stack spacing={2} mb={3}>
          <Stack>
            <Typography variant="inherit" sx={{ fontSize: 15, fontWeight: 700 }}>
              担当者名
            </Typography>
            <Typography ml={2}>{user?.name}</Typography>
          </Stack>

          <DatePicker name="working_date" label="対応日" control={control} />

          <Input
            name="working_time"
            label="作業時間（5分単位で入力）"
            control={control}
            type="number"
            inputProps={{
              step: 5
            }}
          />

          <Select<App.Models.FdTicket>
            name="fd_ticket_id"
            label="Freshdesk チケットID"
            fullWidth
            control={control}
            options={optionFdTicket}
          />

          <Select
            name="operation_id"
            label="作業内容"
            fullWidth
            control={control}
            options={operations}
          />
        </Stack>

        <Grid container justifyContent="center">
          {edited ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Grid>
      </Container>
    </DialogBase>
  )
}

export default WorkingRecordDialog
