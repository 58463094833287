const grey = {
  50: '#fafafa',
  100: '#e0e0e0',
  200: '#dcdfe6',
  300: '#bfc2cc',
  400: '#bdbdbd',
  500: '#888888',
  600: '#797979',
  900: '#1b1e25',
  A100: '#ececec',
  A200: '#f4f4f4',
  A400: '#afafaf'
}

const green = {
  100: '#e6faec',
  300: '#a8f29d',
  600: '#1ec64f',
  900: '#077e2a',
  700: '#6fcf97'
}

const red = {
  100: '#ffd9d3',
  300: '#ffa69a',
  600: '#ff0000',
  900: '#951b0b'
}

const pink = {
  300: '#ffd7d7'
}

const blue = {
  900: '#002076',
  600: '#3189f8',
  300: '#92adfb',
  100: '#d4e0ff'
}

const yellow = {
  600: '#ffee00',
  500: '#ffef42',
  300: '#fffde5',
  dark: '#c7b900'
}

const linearGradient = {
  defaultOverlay: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
}

export { grey, green, red, blue, yellow, pink, linearGradient }
