import { createTheme } from '@mui/material'
import { blue, grey, red, yellow } from './colors'
import { textBoldButton } from './customStyles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    modal: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textBold: true
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: yellow[600],
      light: yellow[300]
    },
    grey: {
      900: grey[900],
      600: grey[600],
      500: grey[500],
      400: grey[400],
      300: grey[300],
      200: grey[200],
      100: grey[100],
      50: grey[50],
      A200: grey['A200'],
      A100: grey['A100'],
      A400: grey['A400']
    },
    info: {
      main: blue[600]
    },
    error: {
      main: red[600]
    }
  },
  mixins: {
    toolbar: {
      minHeight: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  typography: {
    fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(', '),
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem' // 36px
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.875rem' // 30px
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem' // 28px
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem' // 24px
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.375rem' // 22px
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.125rem' // 18px
    },
    subtitle1: {
      fontWeight: 700
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 940,
      lg: 1100,
      xl: 1440,
      modal: 1300
    }
  },
  shape: {
    borderRadius: 5
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        root: {
          '&:hover': {
            opacity: 0.7
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: yellow[600]
          }
        }
      },
      defaultProps: {
        disableRipple: true,
        disableElevation: true
      },
      variants: [
        {
          props: { variant: 'textBold' },
          style: textBoldButton
        }
      ]
    },
    MuiTypography: {
      styleOverrides: {}
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: grey[600]
        },
        colorPrimary: {
          fill: grey[600]
        },
        colorAction: {
          fill: 'white'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[100]
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 500
        }
      }
    }
  }
})

defaultTheme.typography.body1 = {
  ...defaultTheme.typography.body1,
  [defaultTheme.breakpoints.down('sm')]: {
    fontSize: '0.875rem'
  }
}

defaultTheme.shadows[1] = '0 0 2px rgb(0 0 0 / 10%)'
defaultTheme.shadows[2] = '0 0 10px -4px rgba(0, 0, 0, 0.19)'
defaultTheme.shadows[3] = '0 0 8px -2px rgba(0, 0, 0, 0.25)'

export { defaultTheme }
