import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import format from 'date-fns/format'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { CellProps, Column, Row } from 'react-table'

const Content: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.Content>('contents')
  const { deleteApi } = useApiResource<App.Models.Content>('contents')

  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const columns = useMemo<Column<App.Models.Content>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: 'タイトル',
        accessor: 'title',
        width: 400
      },
      {
        Header: '配信グループ',
        accessor: 'group.name' as 'group_id',
        searchType: 'select',
        regex: '_equal',
        queryKey: 'group_id',
        additionSearchProps: {
          query: 'groups',
          labelValueKeys: ['name', 'id']
        },
        width: 150
      },
      {
        Header: '講師',
        accessor: 'instructor.display_name' as 'instructor',
        searchType: 'select',
        regex: '_equal',
        queryKey: 'instructor_id',
        additionSearchProps: {
          query: 'instructors',
          labelValueKeys: ['display_name', 'id']
        },
        width: 150
      },
      {
        Header: '配信期間',
        Cell: ({ row }: CellProps<App.Models.Content>) =>
          format(new Date(row.original.distribute_from_date), 'yyyy/MM/dd HH:mm') +
          ' ~ ' +
          format(new Date(row.original.distribute_to_date), 'yyyy/MM/dd HH:mm'),
        search: false,
        width: 200
      }
    ],
    []
  )

  const dialog = useDialog()

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.Content>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<App.Models.Content>) => {
    navigate('/content/detail/' + original.id)
  }

  return (
    <Page title="コンテンツ一覧">
      <TopHeader
        path="/content/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
      />

      <ReactTable
        columns={columns}
        {...paginationData}
        defaultActionEdit
        onRowClick={onRowClick}
        onActionDelete={handleDelete}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { Content }
