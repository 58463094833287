import { Checkbox, FormControlLabel, Grid, Stack, styled, Typography } from '@mui/material'
import { FormDetail, Tag } from 'components/Form'
import { Page } from 'components/Layouts'
import format from 'date-fns/format'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

const GridDateTime = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[50]
}))

export type InstructorType = App.Models.Instructor & {
  instructor_fee: number
}

const EventDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.Event>([`events/${params?.id}`])
  const { data: adminUser } = useQuery<App.Models.AdminUser[]>([
    'admin-users',
    { per_page: -1, with_trashed: true }
  ])

  const optionAdmin = (adminUser || [])?.map((admin) => ({ label: admin.name, value: admin.id }))

  return (
    <Page title="イベント詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="タイトル">
          <Typography>{data?.title}</Typography>
        </FormDetail>

        <FormDetail label="アイキャッチ画像">
          <img
            src={data?.image_path}
            alt="logo"
            width={150}
            height={150}
            style={{ objectFit: 'cover' }}
          />
        </FormDetail>

        <FormDetail label="詳細">
          {data?.detail && <div dangerouslySetInnerHTML={{ __html: data?.detail || '' }} />}
        </FormDetail>

        <FormDetail label="英語タイトル">
          <Typography>{data?.title_en}</Typography>
        </FormDetail>

        <FormDetail label="英語詳細">
          {data?.detail_en && <div dangerouslySetInnerHTML={{ __html: data?.detail_en || '' }} />}
        </FormDetail>

        <FormDetail label="配信グループ">
          <Typography>{data?.group?.name}</Typography>
        </FormDetail>

        <FormDetail label="講師1">
          <Typography>
            {data?.instructors && (data.instructors[0] as InstructorType)?.display_name}
          </Typography>
        </FormDetail>

        <FormDetail label="講師1の報酬">
          <Typography>
            {data?.instructors && (data.instructors[0] as InstructorType)?.instructor_fee} 円
          </Typography>
        </FormDetail>

        <FormDetail label="講師2">
          <Typography>
            {data?.instructors && (data.instructors[1] as InstructorType)?.display_name}
          </Typography>
        </FormDetail>

        <FormDetail label="講師2の報酬">
          <Typography>
            {data?.instructors && (data.instructors[1] as InstructorType)?.instructor_fee} 円
          </Typography>
        </FormDetail>

        <FormDetail label="開催場所">
          <Typography>{data?.venue}</Typography>
        </FormDetail>

        <FormDetail label="ZOOM アカウント">
          <Typography>{data?.zoom_account}</Typography>
        </FormDetail>

        <FormDetail label="ZOOM URL">
          <Typography>{data?.zoom_url}</Typography>
        </FormDetail>

        <FormDetail label="対応言語">
          <Stack direction="row" spacing={2}>
            {data?.is_english ? <Tag label={data?.is_english ? '英語' : '日本語'} /> : ''}
            {data?.is_japanese ? <Tag label={data?.is_japanese ? '日本語' : '英語'} /> : ''}
          </Stack>
        </FormDetail>

        <FormDetail label="Freshdesk Category 1">
          <Typography>{data?.fd_ticket_category_1}</Typography>
        </FormDetail>

        <FormDetail label="Freshdesk Category 2">
          <Typography>TPO Event</Typography>
        </FormDetail>

        <FormDetail label="Freshdesk Category 3">
          <Typography>{data?.fd_ticket_category_3}</Typography>
        </FormDetail>

        <FormDetail label="freshdesk  Tags">
          <Stack direction="row" spacing={2}>
            {data?.fd_tags &&
              (JSON.parse(data?.fd_tags) as string[]).map((el: string) => (
                <Tag key={el} label={el} />
              ))}
          </Stack>
        </FormDetail>

        <FormDetail label="Freshdesk Description">
          {data?.fd_description && (
            <div dangerouslySetInnerHTML={{ __html: data.fd_description }} />
          )}
        </FormDetail>

        {data?.event_dates &&
          data?.event_dates.map(
            (event, index) =>
              event && (
                <GridDateTime p={4} key={index} sx={{ width: '500px' }}>
                  <Stack direction="row" spacing={20}>
                    <FormDetail label="イベント日時">
                      <Typography>
                        {format(new Date(event.start_date_time), 'yyyy/MM/dd H:mm')} {' - '}
                        {format(new Date(event.end_date_time), 'H:mm')}
                      </Typography>
                    </FormDetail>

                    <FormDetail label="最大予約数">
                      <Typography>{event?.max_booking_number}</Typography>
                    </FormDetail>
                  </Stack>

                  <FormDetail label="ホスト担当者">
                    <Typography>
                      {optionAdmin.find((p) => p.value === event?.admin_user_id)?.label}
                    </Typography>
                  </FormDetail>

                  <FormDetail label="見逃し配信動画の録画">
                    <FormControlLabel
                      control={<Checkbox checked={!!event?.is_rec} />}
                      label="見逃し配信動画の録画"
                      sx={{ '& .MuiTypography-root': { fontSize: 15, fontWeight: 500 } }}
                    />
                  </FormDetail>
                </GridDateTime>
              )
          )}
      </Stack>
    </Page>
  )
}

export { EventDetail }
