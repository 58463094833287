import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { sliceString } from 'lib/utils'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { CellProps, Column, Row } from 'react-table'

const Instructor: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.Instructor>('instructors')

  const navigate = useNavigate()
  const dialog = useDialog()
  const { isFullAction } = useCheckPerm()

  const { deleteApi } = useApiResource<App.Models.Instructor>('instructors')

  const columns = useMemo<Column<App.Models.Instructor>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: '講師名',
        accessor: 'display_name',
        width: 220
      },
      {
        Header: '請求先 氏名',
        accessor: 'demand_name',
        width: 220
      },
      {
        Header: '会社名',
        accessor: 'company_name',
        width: 200
      },
      {
        Header: 'メールアドレス',
        accessor: 'email',
        width: 200
      },
      {
        Header: '電話番号',
        accessor: 'tel'
      },
      {
        Header: '注意事項',
        accessor: 'notes',
        Cell: ({ value }) => (value ? sliceString(value, 20) : '')
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.Instructor>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<App.Models.Instructor>) => {
    navigate('/instructor/detail/' + original.id)
  }

  return (
    <Page title="イベント講師一覧">
      <TopHeader
        path="/instructor/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
      />

      <ReactTable<App.Models.Instructor>
        columns={columns}
        defaultActionEdit
        onActionDelete={handleDelete}
        onRowClick={onRowClick}
        {...paginationData}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { Instructor }
