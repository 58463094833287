import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Provider } from 'jotai'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

const environment = import.meta.env.VITE_SENTRY_ENV as string

Sentry.init({
  dsn: 'https://74404c579d0a4b70b225055f42645c7e@o1116911.ingest.sentry.io/6250172',
  integrations: [new BrowserTracing()],
  environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
