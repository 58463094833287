import { styled, Typography } from '@mui/material'

const Empty = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 50,
  backgroundColor: theme.palette.grey[200],
  marginBottom: theme.spacing(2),
  width: '100%',
  borderRadius: 5
}))

const EmptyTable: React.VFC = () => {
  return (
    <Empty>
      <Typography variant="h6" color="text.secondary">
        データなし
      </Typography>
    </Empty>
  )
}

export default EmptyTable
