import { IAllProps } from '@tinymce/tinymce-react'
import { uploadApi } from 'lib/api'

export const init: IAllProps['init'] = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount'
  ],
  toolbar:
    'bold italic backcolor forecolor fontsize | link unlink |  image media table |  bullist numlist | removeformat |' +
    'blocks | alignleft aligncenter alignright alignjustify | outdent indent | fontfamily | code',
  toolbar_mode: 'sliding',
  file_picker_types: 'image media file',
  file_picker_callback: function (cb, value, meta) {
    let accept = 'image/*'
    let url = 'upload'

    const input = document.createElement('input')

    if (meta.filetype !== 'image') {
      accept = 'image/*, .pdf'
    }

    input.setAttribute('type', 'file')
    input.setAttribute('accept', accept)
    input.onchange = async function (event: Event) {
      const target = event.target as HTMLInputElement
      const file = (target.files as FileList)[0]

      if (file.type.includes('pdf')) {
        url = 'upload-pdf'
      }

      try {
        const res = await uploadApi(
          file,
          {
            url
          },
          'content-images'
        )
        cb(res.data.link, { title: file.name, text: file.name })
      } catch (error) {
        cb('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' })
      }
    }

    input.click()
  },
  automatic_uploads: true,
  images_upload_handler: (blobInfo, progress) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        const res = await uploadApi(
          blobInfo.blob() as File,
          {
            onUploadProgress: function (e) {
              progress((e.loaded / e.total) * 100)
            }
          },
          'content-images'
        )
        resolve(res.data.link)
      } catch (error) {
        if (error.httpStatus === 403) {
          reject({ message: 'HTTP Error: ' + error.httpStatus, remove: true })
          return
        }

        if (error.httpStatus < 200 || error.httpStatus >= 300) {
          reject('HTTP Error: ' + error.httpStatus)
          return
        }
      }
    }),
  paste_data_images: true,
  content_style: 'body { font-family: "Noto Sans JP",Helvetica,Arial,sans-serif; font-size:14px }',
  fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
  link_target_list: [
    { text: 'Current window', value: '' },
    { text: 'New window', value: '_blank' }
  ],
  link_default_target: '_blank',
  table_default_attributes: {
    cellpadding: 12,
    border: 1
  },
  newline_behavior: 'linebreak'
}

export const apiKey = 'l1g8yue3n2udnaqdc2b5bk2laxyibk286qizr6e87avlqvwt'
