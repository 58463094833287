import { Stack, Typography } from '@mui/material'
import { FormDetail, Tag } from 'components/Form'
import { Page } from 'components/Layouts'
import ReactPlayer from 'react-player'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const ContentDetail: React.VFC = () => {
  const params = useParams()
  const { data } = useQuery<App.Models.Content>([`contents/${params?.id}`])

  return (
    <Page title="コンテンツ詳細">
      <Stack spacing={2} ml={10}>
        <FormDetail label="タイトル">
          <Typography>{data?.title}</Typography>
        </FormDetail>

        <FormDetail label="アイキャッチ画像">
          <img
            src={data?.image_path}
            alt="logo"
            width={150}
            height={150}
            style={{ objectFit: 'cover' }}
          />
        </FormDetail>

        <FormDetail label="詳細">
          <div dangerouslySetInnerHTML={{ __html: data?.detail || '' }} />
        </FormDetail>

        <FormDetail label="英語タイトル">
          <Typography>{data?.title_en}</Typography>
        </FormDetail>

        <FormDetail label="英語詳細">
          <div dangerouslySetInnerHTML={{ __html: data?.detail_en || '' }} />
        </FormDetail>

        <FormDetail label="配信グループ">
          <Typography>{data?.group?.name}</Typography>
        </FormDetail>

        <FormDetail label="講師">
          <Typography>{data?.instructor?.display_name}</Typography>
        </FormDetail>

        <FormDetail label="動画URL">
          <Typography>{data?.video_url}</Typography>
          <ReactPlayer url={data?.video_url} />
        </FormDetail>

        <FormDetail label="対応言語">
          <Stack direction="row" spacing={2}>
            {data?.is_english ? <Tag label={data?.is_english ? '英語' : '日本語'} /> : ''}
            {data?.is_japanese ? <Tag label={data?.is_japanese ? '日本語' : '英語'} /> : ''}
          </Stack>
        </FormDetail>

        <FormDetail label="ユーザー用動画専用カテゴリー">
          <Stack direction="row" spacing={2}>
            {data?.tags &&
              (JSON.parse(data.tags) as string[]).map((el: string) => <Tag key={el} label={el} />)}
          </Stack>
        </FormDetail>

        <FormDetail label="Freshdesk Category1">
          <Tag label={data?.fd_category_1} />
        </FormDetail>

        <FormDetail label="Freshdesk Category2">
          <Tag label={data?.fd_category_2} />
        </FormDetail>

        <FormDetail label="Freshdesk Event">
          <Tag label={data?.fd_category_event} />
        </FormDetail>

        <FormDetail label="Freshdesk Tags">
          <Stack direction="row" spacing={2}>
            {data?.fd_tags &&
              (JSON.parse(data?.fd_tags) as string[])?.map((el: string) => (
                <Tag key={el} label={el} />
              ))}
          </Stack>
        </FormDetail>

        <FormDetail label="公開期間">
          <Typography>
            {[data?.distribute_from_date, data?.distribute_to_date].join(' ~ ')}
          </Typography>
        </FormDetail>

        <FormDetail label="稼働時間">
          <Typography>{data?.total_input_time}</Typography>
        </FormDetail>
      </Stack>
    </Page>
  )
}

export { ContentDetail }
