import { Collapse, CollapseProps } from '@mui/material'
import { usePreserveState } from 'lib/hooks'
import React, { ReactNode, useCallback } from 'react'

export type RenderToggleFn = (
  {
    onClick,
    collapsed
  }: {
    onClick: (collapsed?: boolean) => void
    collapsed: boolean
  },
  handleCollapsed: (collapsed?: boolean) => void
) => JSX.Element

export interface CollapseMenuProps extends CollapseProps {
  collapsed: boolean
  renderToggle: RenderToggleFn
  renderContainer?: ({
    collapsed,
    children
  }: {
    collapsed: boolean
    children: ReactNode
  }) => JSX.Element
  onCollapse: (collapsed?: boolean) => void
  stateByPassed?: boolean
}

const CollapseMenu: React.FC<CollapseMenuProps> = ({
  collapsed: extCollapsed,
  renderToggle,
  renderContainer = ({ children }) => <>{children}</>,
  onCollapse,
  children,
  stateByPassed,
  ...props
}) => {
  const [collapsed, setCollapsed] = usePreserveState<boolean>(
    extCollapsed,
    onCollapse,
    stateByPassed
  )

  const onToggle = useCallback(() => setCollapsed((c) => !c), [setCollapsed])

  const handleCollapse = useCallback(
    (_collapsed?: boolean) => {
      setCollapsed(!!_collapsed)
    },
    [setCollapsed]
  )

  return renderContainer({
    collapsed,
    children: (
      <>
        {renderToggle(
          { onClick: stateByPassed ? onCollapse : onToggle, collapsed },
          handleCollapse
        )}
        <Collapse {...props} in={collapsed}>
          {children}
        </Collapse>
      </>
    )
  })
}

export { CollapseMenu }
