import { Page } from 'components/Layouts'
import { ActionColumnConfig, ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

const actionConfig: ActionColumnConfig = {
  needConfirm: true
}

type CompanyType = App.Models.Company & {
  users_count: number
}

const CustomerCompany: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<CompanyType>('companies')
  const { deleteApi } = useApiResource('companies')
  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const columns = useMemo<Column<CompanyType>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: '企業名',
        accessor: 'name',
        width: 350
      },
      {
        Header: '親会社',
        accessor: 'parent_company.name' as 'parent_company',
        width: 350
      },
      {
        Header: 'グループ',
        accessor: 'groups',
        Cell: ({ value }) => value?.map((el) => el.name).join(', '),
        searchType: 'select',
        regex: '_equal',
        queryKey: 'groups:group_id',
        additionSearchProps: {
          query: 'groups',
          labelValueKeys: ['name', 'id']
        },
        width: 400
      },
      {
        Header: '登録メンバー数',
        accessor: 'users_count',
        search: false,
        width: 150
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<CompanyType>) => {
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, refetch]
  )

  const onRowClick = ({ original }: Row<CompanyType>) => {
    navigate('/company/detail/' + original.id)
  }

  return (
    <Page title="企業一覧">
      <TopHeader
        path="/company/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
      />

      <ReactTable<CompanyType>
        columns={columns}
        defaultActionEdit
        actionConfig={actionConfig}
        onActionDelete={handleDelete}
        onRowClick={onRowClick}
        {...paginationData}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { CustomerCompany }
