import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { CellProps, Column, Row } from 'react-table'

type GroupType = App.Models.Group & {
  companies_count: number
}

const Group: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } = usePaginationQuery<GroupType>('groups')
  const { deleteApi } = useApiResource<GroupType>('groups')
  const dialog = useDialog()
  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const columns = useMemo<Column<GroupType>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: 'グループ名',
        accessor: 'name',
        width: 300
      },
      {
        Header: 'グループ説明',
        accessor: 'description',
        width: 500
      },
      {
        Header: 'グループ企業数',
        accessor: 'companies_count',
        regex: 'none'
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<GroupType>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<GroupType>) => {
    navigate('/group/detail/' + original.id)
  }

  return (
    <Page title="グループ一覧">
      <TopHeader
        path="/group/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
      />

      <ReactTable<GroupType>
        columns={columns}
        defaultActionEdit
        onRowClick={onRowClick}
        onActionDelete={handleDelete}
        {...paginationData}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { Group }
