import MenuIcon from '@mui/icons-material/Menu'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import { createRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import { drawerWidth } from './Drawer'
import Sidebar from './Sidebar'

export const mainRef = createRef<HTMLDivElement>()

const Layout: React.VFC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const toggleSidebar = () => {
    setOpen(!open)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // Detect mobile devices

  return (
    <Box sx={{ display: 'flex' }} bgcolor="grey.A200">
      <Sidebar open={open} toggleSidebar={toggleSidebar} />
      <Box style={{ width: '100%' }} bgcolor="grey.50">
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              padding: '0 24px'
            }}
          >
            <MenuIcon sx={{ cursor: 'pointer' }} onClick={toggleSidebar} />
            <img src={Logo} alt="logo" />
          </Box>
        )}
        <Main ref={mainRef}>
          <Outlet />
        </Main>
      </Box>
    </Box>
  )
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open = true }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  minHeight: '100vh',
  marginLeft: `-${drawerWidth}px`,
  backgroundColor: theme.palette.grey['A200'],
  overflow: 'hidden',

  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}))

export { Layout }
