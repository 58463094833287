import { List, ListItem, ListItemButtonTypeMap, ListItemProps } from '@mui/material'
import { usePreserveState } from 'lib/hooks'
import { UnknownObj } from 'lib/types'
import React, { Fragment, ReactNode } from 'react'
import { CollapseMenu } from './CollapseMenu'
import { Arrow, Divider, RowToggle, StyledList } from './components'

export type NestMenuType<RSS = UnknownObj> = {
  key: string
  label: string
  default?: boolean
  isToggleOutside?: boolean
  subMenus?: NestMenuType[]
  path?: string
  resource?: RSS
  divider?: boolean
}

type GetPropsArgs = {
  selected: boolean
  active: boolean
  collapsed?: boolean
  level: number
  onClick?(): void
}

type GetPropsFn = <T = UnknownObj>(item: NestMenuType, props: GetPropsArgs) => T

export type NestedMenuListProps = {
  menus: NestMenuType[]
  openKeys?: string[]
  selectedKey?: string
  onSelectedKeyChange?: (key: string) => void
  onOpenKeysChange?: (keys: string[]) => void
  renderItem?: (item: NestMenuType, listItemProps: ListItemProps) => ReactNode
  getItemProps?(item: NestMenuType, props: GetPropsArgs): ListItemButtonTypeMap
}

const mapNestedPath = (menus: NestMenuType[]): Record<string, string[]> => {
  const result: Record<string, string[]> = {}
  const traverseItems = (items: NestMenuType[], parents: string[] = []) => {
    items.forEach((item) => {
      const { key, subMenus } = item

      parents.forEach((parent) => {
        result[parent] = [...(result[parent] || []), key]
      })

      if (subMenus) {
        traverseItems(subMenus, [...parents, key])
      } else {
        result[key] = []
      }
    })
  }

  traverseItems(menus, [])

  return result
}

const NestedMenu: React.VFC<NestedMenuListProps> = ({
  menus = [],
  selectedKey: extSelectedKey = '',
  openKeys = [],
  onSelectedKeyChange,
  onOpenKeysChange = () => undefined,
  getItemProps = () => undefined,
  renderItem
}) => {
  const keyMap = mapNestedPath(menus)
  const [selectedKey, setSelectedKey] = usePreserveState(extSelectedKey, onSelectedKeyChange)

  const openKeysCallback = (key: string) => (toggled?: boolean) => {
    if (toggled) return onOpenKeysChange(openKeys.concat(key))
    return onOpenKeysChange(openKeys.filter((k) => k !== key))
  }

  const renderChildren = (items: NestMenuType[], level: number) => {
    return items.map((item) => {
      const { key, label, subMenus, isToggleOutside, divider } = item
      const selected = selectedKey === key
      const active = keyMap[key]?.includes(selectedKey) || selected

      if (Array.isArray(subMenus) && subMenus.length) {
        return (
          <Fragment key={key}>
            <CollapseMenu
              collapsed={openKeys.includes(key)}
              onCollapse={openKeysCallback(key)}
              renderToggle={({ onClick: _onClick, collapsed }) => {
                const onClick = () => _onClick()
                const toToggleProps = (fn: GetPropsFn) =>
                  fn(item, { selected, active, collapsed, level, onClick })

                const itemProps = {
                  selected,
                  ...toToggleProps(getItemProps as GetPropsFn)
                }
                if (!isToggleOutside) {
                  const action = <Arrow disableRipple collapsed={collapsed} />

                  return (
                    <RowToggle>
                      {renderItem ? (
                        renderItem(item, {
                          ...itemProps,
                          onClick,
                          children: action
                        })
                      ) : (
                        <ListItem
                          sx={{ justifyContent: 'space-between', pr: 0 }}
                          {...itemProps}
                          button
                          onClick={onClick}
                        >
                          {label}
                          {action}
                        </ListItem>
                      )}
                    </RowToggle>
                  )
                }

                return (
                  <RowToggle>
                    {renderItem ? (
                      renderItem(item, { ...itemProps, onClick, children: null })
                    ) : (
                      <ListItem button {...itemProps}>
                        {label}
                      </ListItem>
                    )}
                    <Arrow collapsed={collapsed} onClick={onClick} />
                  </RowToggle>
                )
              }}
            >
              <StyledList level={level + 1}>{renderChildren(subMenus, level + 1)}</StyledList>
            </CollapseMenu>
            {divider && <Divider />}
          </Fragment>
        )
      }

      const onClick = () => setSelectedKey(key)
      const itemProps = {
        key,
        selected,
        onClick,
        ...getItemProps(item, { active, selected, level, onClick })
      }
      return (
        <Fragment key={key}>
          {renderItem ? (
            renderItem(item, itemProps)
          ) : (
            <ListItem button {...itemProps}>
              {label}
            </ListItem>
          )}
          {divider && <Divider />}
        </Fragment>
      )
    })
  }

  return <List>{renderChildren(menus, 1)}</List>
}

export { NestedMenu }
