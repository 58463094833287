import { Divider as MDivider, List, styled } from '@mui/material'

type StyleList = {
  level?: number
}

export const StyledList = styled(List)<StyleList>(({ theme, level = 1 }) => ({
  '& .MuiListItem-root': {
    paddingLeft: theme.spacing(level + 2)
  },
  ...(level === 2 && {
    '& .MuiListItem-root': {}
  })
}))

export const Divider = styled(MDivider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  backgroundColor: theme.palette.grey['A100']
}))
