import AddIcon from '@mui/icons-material/Add'
import { Button, useTheme } from '@mui/material'
import { useCheckPerm } from 'lib/hooks'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

const LeftHeader = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { hasCreate } = useCheckPerm()

  const onCreate = () => {
    navigate('/event/create')
  }

  return (
    <div>
      {hasCreate && (
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          startIcon={<AddIcon sx={{ fill: theme.palette.common.black }} />}
          onClick={onCreate}
          sx={{ width: 120 }}
        >
          新規追加
        </Button>
      )}
    </div>
  )
}

export default memo(LeftHeader)
