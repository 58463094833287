import { Box, BoxProps } from '@mui/material'
import { BarSeriesOption, TitleComponentOption } from 'echarts'
import { BarChart as EBarChart } from 'echarts/charts'
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption
} from 'echarts/components'
import type { ComposeOption, ECharts } from 'echarts/core'
import { use } from 'echarts/core'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { merge } from 'lodash'
import { forwardRef } from 'react'
import { CoreChart, CoreChartProps } from './Core'
import { defaultBarOptions } from './defaultOptions'

use([
  TooltipComponent,
  LegendComponent,
  EBarChart,
  CanvasRenderer,
  LabelLayout,
  TitleComponent,
  GridComponent
])

export type BarChartOption = ComposeOption<
  | TooltipComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | TitleComponentOption
  | GridComponentOption
>

type BarChartProps = BoxProps<'div', CoreChartProps<BarChartOption>> & {
  series?: BarSeriesOption
}

const BarChart = forwardRef<ECharts, BarChartProps>(function Pie(props, ref) {
  const { options, settings, loading, series, ...boxProps } = props
  const _options = merge({}, defaultBarOptions, options, { series })

  return (
    <Box width="100%" height="100%" {...boxProps}>
      <CoreChart {...{ options: _options, settings, loading }} ref={ref} />
    </Box>
  )
})

export { BarChart }
