import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Stack, Typography } from '@mui/material'
import { CheckBox, RichText, Select } from 'components/Form'
import { Page } from 'components/Layouts'
import format from 'date-fns/format'
import { updateMonthlyReportApi } from 'lib/api'
import { useModalState } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { CompanyMonthlyReportType } from './CompanyMonthlyReport'
import ReportPreviewModal from './ReportPreviewModal'
import { UserCases } from './UserCases'
import { UserVoices } from './UserVoices'

export type DateCompanyReport = {
  id: number
  ym: string
}

const validateMonthlyReport = yup.object({
  company_id: yup.string().required('企業を選択してください。'),
  company_report_id: yup.string().required('対象月を選択してください。'),
  summary: yup.string().required().label('総括（利用傾向など）'),
  user_voices: yup.array().of(
    yup.object().shape(
      {
        subject: yup
          .string()
          .max(255, '入力は255文字までです。')
          .when('content', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('category_id', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          }),
        content: yup
          .string()
          .when('subject', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('category_id', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          }),
        category_id: yup
          .string()
          .when('subject', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('content', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
      },
      [
        ['subject', 'content'],
        ['content', 'category_id'],
        ['subject', 'category_id']
      ]
    )
  ),
  use_cases: yup.array().of(
    yup.object().shape(
      {
        subject: yup
          .string()
          .max(255, '入力は255文字までです。')
          .when('fd_ticket_category1', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('fd_ticket_category2', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          }),
        fd_ticket_category1: yup
          .string()
          .when('subject', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('fd_ticket_category2', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          }),
        fd_ticket_category2: yup
          .string()
          .when('subject', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
          .when('fd_ticket_category1', {
            is: (value: string) => !!value,
            then: yup.string().required(' ')
          })
      },
      [
        ['subject', 'fd_ticket_category1'],
        ['fd_ticket_category1', 'fd_ticket_category2'],
        ['subject', 'fd_ticket_category2']
      ]
    )
  )
})

export type MonthlyReport = Omit<
  App.Models.CompanyReportQualitative,
  'use_cases' | 'user_voices' | 'company_report'
> & {
  date?: string | undefined
  company_id: number
  use_cases: {
    subject: string
    fd_ticket_category1: string
    fd_ticket_category2: string
  }[]
  user_voices: {
    subject: string
    content: string
    category_id: number
  }[]
  is_private: boolean
}

const initValues = {
  summary: '',
  last_measure: '',
  next_measure: '',
  free: '',
  user_voices: [
    {
      subject: '',
      content: '',
      category_id: undefined
    }
  ],
  use_cases: [
    {
      subject: '',
      fd_ticket_category1: '',
      fd_ticket_category2: ''
    }
  ],
  is_private: false
}

const FormMonthlyReport: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    resetField,
    formState: { isSubmitting }
  } = useForm<MonthlyReport>({
    defaultValues: initValues,
    resolver: yupResolver(validateMonthlyReport)
  })

  const company = watch('company_id')

  useEffect(() => {
    if (company) {
      resetField('use_cases')
      resetField('user_voices')
    }
  }, [company, resetField])

  const { data: dateCompanyReport } = useQuery<DateCompanyReport[]>(
    [`company-report?company_id_equal=${company}`, { per_page: -1 }],
    {
      enabled: !!company
    }
  )

  const dateOptions = dateCompanyReport?.map((d) => ({
    label: format(new Date(d.ym), 'yyyy/MM'),
    value: d.id
  }))

  const monthYear = format(new Date(searchParams.get('month_year') as string), 'yyyy/MM')
  const month_year = dateOptions?.find((k) => k.label === monthYear)?.value

  useEffect(() => {
    if (params.id) {
      setValue('company_id', Number(params.id))
      setValue('company_report_id', Number(month_year))
    }
  }, [month_year, params.id, setValue])

  const company_report_id = watch('company_report_id')

  const date = dateCompanyReport?.find((d) => d.id === company_report_id)?.ym

  const { data } = useQuery<CompanyMonthlyReportType>(
    [
      `company-monthly-report/${company}`,
      {
        month_year: date
      }
    ],
    {
      enabled: !!date,
      onSuccess: (data) => {
        let name: keyof MonthlyReport
        for (name in initValues) {
          if (name === 'use_cases' || name === 'user_voices') {
            setValue(name, (data.report as unknown as MonthlyReport)[name] || initValues[name])
          } else {
            setValue(name, (data.report as unknown as MonthlyReport)[name])
          }
        }
      }
    }
  )

  const onSubmit: SubmitHandler<MonthlyReport> = async (values) => {
    try {
      const _values = { ...values, company_report_id: company_report_id }

      await updateMonthlyReportApi(_values)
      toast.success(company ? '更新しました。' : '登録しました。')
      navigate('/monthly-report')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(company ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  const { isOpen, onClose, onOpen } = useModalState()

  return (
    <Page title="Monthly Report  総括レポート">
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Select<App.Models.Company>
            fullWidth
            name="company_id"
            label="企業"
            control={control}
            query="companies"
          />

          <Select name="company_report_id" label="対象月" control={control} options={dateOptions} />

          <RichText
            name="summary"
            label="総括（利用傾向など）"
            defaultValue={data?.report?.summary || ''}
            control={control}
          />

          <RichText
            name="last_measure"
            label="前回の施策・結果"
            defaultValue={data?.report?.last_measure || ''}
            control={control}
          />

          <RichText
            name="next_measure"
            label="今後の施策・取り組み"
            defaultValue={data?.report?.next_measure || ''}
            control={control}
          />

          <RichText
            name="free"
            label="Free"
            defaultValue={data?.report?.free || ''}
            control={control}
          />

          <Typography>お客様からの声・クライアントへの課題共有</Typography>
          <UserVoices name="user_voices" control={control} />

          <Typography>利用事例</Typography>
          <UserCases name="use_cases" control={control} />

          <CheckBox name="is_private" label="非公開" control={control} />
        </Stack>

        <Stack direction="row" spacing={5} justifyContent="center">
          {company && date ? (
            <>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                保存
              </LoadingButton>
              <Button variant="outlined" color="inherit" onClick={onOpen}>
                プレビュー
              </Button>
            </>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Stack>
      </Container>
      <ReportPreviewModal open={isOpen} onClose={onClose} {...watch()} date={date} />
    </Page>
  )
}

export { FormMonthlyReport }
