import { Add, CachedOutlined, Close } from '@mui/icons-material'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import { FormHelper, FormLabel as Label, Input, InputTag } from 'components/Form'
import { uploadApi } from 'lib/api/upload'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFieldArray, UseFieldArrayUpdate, useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { RichTextNone } from '../../../components/Form/RichText/NoneMenuBar/RichTextNone'
import { CreateCustomerReportForm } from '../CreateCustomerReport'

function isValidHttpUrl(string: string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

function isSelfOGPUpload(url: string) {
  if (
    url.includes('https://tpo-staging.s3.ap-northeast-1.amazonaws.com/ogp-images/') ||
    url.includes('https://tpo-prod.s3.ap-northeast-1.amazonaws.com/ogp-images/')
  ) {
    return true
  }
  return false
}

export type OGP = {
  title: string
  description: string
  type: string
  url: string
  image: string
}

const ImageBox = styled('img')({
  height: 196,
  objectFit: 'cover'
})

const FormLabel = styled(Label)({
  fontSize: 14,
  fontWeight: 700,
  marginTop: 12,
  marginBottom: 12
})

const ContentCard = ({
  index,
  update,
  remove
}: {
  index: number
  update: UseFieldArrayUpdate<CreateCustomerReportForm, 'customer_report_contents'>
  item: CreateCustomerReportForm['customer_report_contents'][number]
  remove: (index?: number) => void
}) => {
  const { control, formState } = useFormContext<CreateCustomerReportForm>()
  const values = useWatch({ control, name: `customer_report_contents.${index}` })
  const watchUrl = values.url
  const watchImagePath = values.image_path
  const hasOGP = !!watchImagePath && !isSelfOGPUpload(watchImagePath)

  const { errors } = formState
  const theme = useTheme()

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        const res = await uploadApi(acceptedFiles[0], {}, 'ogp')
        if (res.data.link) {
          update(index, { ...values, image_path: res.data.link })
        }
      } catch (error) {
        console.log(error)
      }
    },
    [index, update, values]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1
  })

  const { isError, isFetching } = useQuery<OGP>(['fetch-ogp', { url: watchUrl, index }], {
    enabled: !!isValidHttpUrl(watchUrl) && values.should_fetch,
    onSuccess({ image }) {
      if (!!isValidHttpUrl(watchUrl) && values.should_fetch) {
        update(index, { ...values, image_path: image, should_fetch: false })
      }
    }
  })

  const { data: ogp } = useQuery<OGP>(['fetch-ogp', { url: watchUrl, index }], {
    enabled: hasOGP && !!isValidHttpUrl(watchUrl)
  })

  return (
    <Stack justifyContent="center">
      <Paper variant="outlined" sx={{ p: 1, width: '100%' }}>
        {watchImagePath && (
          <Stack direction="row" justifyContent="flex-end" mb={1}>
            <Close
              sx={{ fill: '#000', fontSize: 16, cursor: 'pointer' }}
              onClick={() => update(index, { ...values, image_path: '', should_fetch: false })}
            />
          </Stack>
        )}
        <Stack width="100%" spacing={1}>
          {watchImagePath ? (
            <Box width="100%" position="relative">
              <ImageBox src={watchImagePath} width="100%" alt="ogp" />
              {hasOGP && (
                <Stack
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    bgcolor: 'rgba(255,255,255,0.9)',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'block',
                    px: 1,
                    py: 0.5,
                    height: 50
                  }}
                >
                  <Typography textOverflow="ellipsis" variant="body2" fontWeight={700}>
                    {ogp?.title}
                  </Typography>
                  <Typography
                    fontSize={11}
                    textOverflow="ellipsis"
                    sx={{
                      // textTransform: 'uppercase',
                      color: (theme) => theme.palette.grey[600]
                    }}
                  >
                    {watchUrl}
                  </Typography>
                </Stack>
              )}
            </Box>
          ) : (
            <Box
              height={220}
              display="flex"
              justifyContent="center"
              alignItems="center"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here ...</p>
              ) : isFetching ? (
                <Stack direction="row" alignItems="center">
                  <CircularProgress size={14} color="info" />
                  <Typography variant="caption" ml={0.5} color="info">
                    画像の取得
                  </Typography>
                </Stack>
              ) : (
                <Stack>
                  <Stack alignItems="center" mb={1}>
                    <CloudUploadOutlinedIcon
                      sx={{
                        fontSize: 32,
                        fill: theme.palette.grey[500]
                      }}
                    />
                    <Typography>Drag&Drop file here</Typography>
                    <Typography variant="caption" mb={0.5}>
                      or
                    </Typography>
                    <Button
                      variant="outlined"
                      color="info"
                      sx={{
                        textTransform: 'none'
                      }}
                      size="small"
                    >
                      Browse File
                    </Button>
                  </Stack>
                  {isError && (
                    <Typography variant="caption" color="error">
                      OGP画像データを取得できません
                    </Typography>
                  )}
                  {watchUrl && !values.should_fetch && (
                    <Button
                      color="info"
                      sx={{
                        fontSize: 10,
                        '& .MuiButton-startIcon': {
                          svg: {
                            fontSize: 16,
                            fill: (theme) => theme.palette.info.main
                          },
                          mr: 0.5
                        }
                      }}
                      startIcon={<CachedOutlined color="info" sx={{ fontSize: 10 }} />}
                      onClick={(e) => {
                        e.stopPropagation()
                        update(index, { ...values, should_fetch: true })
                      }}
                    >
                      画像を再取得する
                    </Button>
                  )}
                </Stack>
              )}
            </Box>
          )}
          {!!errors?.customer_report_contents?.[index]?.image_path && (
            <FormHelper error>
              {errors?.customer_report_contents?.[index]?.image_path?.message}
            </FormHelper>
          )}
          <Divider sx={{ mt: 1, mb: 2 }} />

          <Input
            placeholder="Title"
            control={control}
            name={`customer_report_contents.${index}.title`}
            sx={{
              '&&& input': {
                py: 0
              }
            }}
            size="small"
          />
          <Input
            placeholder="URL"
            control={control}
            name={`customer_report_contents.${index}.url`}
            size="small"
            sx={{
              '&&& input': {
                py: 0
              }
            }}
          />

          <InputTag
            control={control}
            placeholder="Tags"
            name={`customer_report_contents.${index}.tags`}
            size="small"
            sx={{
              '&&& input': {
                fontSize: 14
              },
              height: 'auto'
            }}
          />

          <Divider sx={{ mt: 1, mb: 2 }} />
          <FormLabel>Information</FormLabel>
          <RichTextNone
            control={control}
            name={`customer_report_contents.${index}.information`}
            initOptions={{ height: 250, toolbar_mode: 'floating' }}
            defaultValue={values.information}
          />
          <Divider sx={{ mt: 1, mb: 2 }} />

          <FormLabel>Concierge’s Comment</FormLabel>
          <RichTextNone
            control={control}
            name={`customer_report_contents.${index}.comment`}
            initOptions={{ height: 250, toolbar_mode: 'floating' }}
            defaultValue={values.comment}
          />
        </Stack>
      </Paper>

      <Button color="error" onClick={() => remove(index)}>
        削除
      </Button>
    </Stack>
  )
}

const CreateReportContent = () => {
  const { control } = useFormContext<CreateCustomerReportForm>()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'customer_report_contents'
  })

  const handleAdd = () => {
    append({
      title: '',
      url: '',
      information: '',
      comment: '',
      image_path: '',
      tags: [],
      should_fetch: true
    })
  }

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => (
        <Grid item key={field.id} md={4}>
          <ContentCard index={index} update={update} remove={remove} item={field} />
        </Grid>
      ))}

      {fields.length < 3 && (
        <Grid item display="flex" alignItems="flex-end">
          <ButtonBase
            sx={{
              width: 48,
              height: 48,
              borderRadius: 24,
              backgroundColor: '#c4c4c4',
              mb: 4,
              ml: 1
            }}
            onClick={handleAdd}
          >
            <Add
              sx={{
                fill: '#000',
                fontSize: 28
              }}
            />
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  )
}

export { CreateReportContent }
