import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Container,
  Divider,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { DialogBase } from 'components/Dialog'
import { ModalProps } from 'lib/types'
import { formatISODate } from 'lib/utils'
import React, { useState } from 'react'
import { NewFormValues } from './FormNew'

const StyledImg = styled('img')(({ theme }) => ({
  borderRadius: 10,
  height: 360,
  width: '100%',
  marginBottom: theme.spacing(3),
  objectFit: 'cover'
}))

export type NewPreviewModal = ModalProps<NewFormValues>

export enum languageEnum {
  Eng,
  JA
}

const NewPreviewModal: React.VFC<NewPreviewModal> = ({ open, onClose, ...data }) => {
  const [language, setLanguage] = useState(languageEnum.JA)

  const isEng = language === languageEnum.Eng

  const handleChangeLanguage = (event: React.MouseEvent<HTMLElement>, newLang: number) => {
    setLanguage(newLang)
  }

  return (
    <DialogBase open={open}>
      <ClearIcon
        onClick={onClose}
        sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 20 }}
      />
      <ToggleButtonGroup
        value={language}
        exclusive
        sx={{ height: 30, position: 'sticky', top: '50%', left: 10 }}
        orientation="vertical"
        onChange={handleChangeLanguage}
      >
        <ToggleButton value={languageEnum.Eng} aria-label="English">
          <Typography width={30} variant="subtitle1">
            Eng
          </Typography>
        </ToggleButton>
        <ToggleButton value={languageEnum.JA} aria-label="Japan">
          <Typography width={30} variant="subtitle1">
            JA
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <Container disableGutters maxWidth="md">
        <StyledImg src={data?.image_path} alt="news" />

        <Typography variant="body1" color="grey.400">
          {data?.updated_at && formatISODate(data.updated_at, '/')}
        </Typography>

        <Typography variant="h4" mb={3}>
          {isEng ? data?.title_en : data?.title}
        </Typography>

        <Box
          mb={4}
          component="div"
          sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
          dangerouslySetInnerHTML={{ __html: isEng ? data?.detail_en : data?.detail }}
        />

        <Divider sx={{ mb: 5 }} />
      </Container>
    </DialogBase>
  )
}

export default NewPreviewModal
