import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { FilterBar, ReactTable } from 'components/ReactTable'
import { endOfMonth, startOfMonth } from 'date-fns'
import format from 'date-fns/format'
import { operations } from 'lib/constants'
import { useApiResource, useCheckPerm, useModalState, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { request } from 'lib/request'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { CellProps, Column } from 'react-table'
import WorkingRecordDialog from './WorkingRecordDialog'

const startMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd')
const endMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd')

const d = new Date()
const startLastMonth = format(new Date(d.getFullYear(), d.getMonth() - 1, 1), 'yyyy-MM-dd')
const endLastMonth = format(new Date(d.getFullYear(), d.getMonth(), 0), 'yyyy-MM-dd')

const WorkingRecord: React.VFC = () => {
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const { paginationData, refetch, handleChangeParams, queries } =
    usePaginationQuery<App.Models.WorkingRecord>('working-records', {
      working_date_between: startMonth + ',' + endMonth
    })
  const {
    paginationData: data,
    refetch: refetchData,
    handleChangeParams: handleChangeParamsLastMonth,
    queries: lastMonthQueries
  } = usePaginationQuery<App.Models.WorkingRecord>('working-records', {
    working_date_between: startLastMonth + ',' + endLastMonth
  })

  const dialog = useDialog()
  const { deleteApi } = useApiResource<App.Models.WorkingRecord>('working-records')

  const hasLastMonthEdit = new Date().getDate() <= 5

  const columns = useMemo<Column<App.Models.WorkingRecord>[]>(
    () => [
      {
        Header: '対応日',
        accessor: 'working_date',
        width: 100
      },
      {
        Header: 'ID',
        accessor: 'fd_ticket.fd_ticket_id' as 'fd_ticket_id',
        width: 60
      },
      {
        Header: 'Freshdesk タイトル',
        accessor: 'fd_ticket.subject' as 'fd_ticket',
        width: 500,
        clipText: true
      },
      {
        Header: '会社名',
        accessor: (row) => {
          if (row.fd_ticket?.user?.company_id_1) {
            return row.fd_ticket.user.parent_company?.name
          } else {
            return row.fd_ticket?.user?.child_company?.name
          }
        },
        width: 200,
        clipText: true
      },
      {
        Header: '担当者',
        accessor: 'user.name' as 'user',
        width: 140
      },
      {
        Header: '稼働時間',
        accessor: 'working_time',
        Cell: ({ value }) => value,
        width: 100
      },
      {
        Header: '作業内容',
        accessor: 'operation_id',
        Cell: ({ value }) => operations.find((o) => o.value === value)?.label,
        width: 150
      }
    ],
    []
  )

  const searchColumns = useMemo<Column<App.Models.WorkingRecord & { company_name: string }>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'fd_ticket.fd_ticket_id' as 'fd_ticket_id'
      },
      {
        Header: 'Freshdesk タイトル',
        accessor: 'fd_ticket.subject' as 'fd_ticket'
      },
      {
        Header: '会社名',
        accessor: 'company_name',
        searchType: 'text',
        regex: 'none',
        queryKey: 'company_name'
      },
      {
        Header: '担当者',
        accessor: 'user.name' as 'user',
        searchType: 'select',
        regex: '_equal',
        queryKey: 'admin_id',
        additionSearchProps: {
          query: 'admin-users',
          labelValueKeys: ['name', 'id']
        }
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.WorkingRecord>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
        refetchData()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch, refetchData]
  )

  const { isOpen, onClose, onOpen } = useModalState()
  const [editID, setEditID] = useState<number>()

  const handleEdit = useCallback(
    async ({ row }: CellProps<App.Models.WorkingRecord>) => {
      setEditID(row.original.id)
      onOpen()
    },
    [onOpen]
  )

  const refetchRecord = () => {
    refetch()
    refetchData()
  }

  const closeModal = () => {
    onClose()
    setEditID(0)
  }

  const theme = useTheme()
  const { hasCreate } = useCheckPerm()

  const exportCSV = async (params: any, month: string) => {
    try {
      setExportLoading(true)
      const res = await request.get('/export-working-records', {
        responseType: 'blob',
        params: { ...params, per_page: -1 }
      })

      setExportLoading(false)

      const blob = res.data
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `working-record_${format(new Date(month), 'yyyyMM')}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      setExportLoading(false)
      toast.error('ダウンロードに失敗しました。')
    }
  }
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={{ md: 1 }}
        mt={8}
      >
        <Typography variant="h4">今月の稼働時間</Typography>

        <Grid>
          <WorkingRecordDialog
            open={isOpen}
            onClose={closeModal}
            refetchWR={refetchRecord}
            editID={editID}
          />
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            disabled={exportLoading}
            sx={{ bgcolor: (theme) => theme.palette.common.white, mr: 1 }}
            onClick={() => exportCSV(queries, startMonth)}
          >
            CSVエクスポート
          </Button>

          {hasCreate && (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              startIcon={<AddIcon sx={{ fill: theme.palette.common.black }} />}
              onClick={onOpen}
              sx={{ width: 120 }}
            >
              新規追加
            </Button>
          )}
        </Grid>
      </Stack>

      <Box mb={3}>
        <FilterBar
          searchColumns={searchColumns}
          layout={'inline'}
          handleChangeParams={handleChangeParams}
        />
      </Box>

      <ReactTable<App.Models.WorkingRecord>
        columns={columns}
        onActionEdit={handleEdit}
        onActionDelete={handleDelete}
        actionConfig={{
          columnWidth: 60
        }}
        {...paginationData}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={{ md: 1 }}
        mt={8}
      >
        <Typography variant="h4">前月の稼働時間（閲覧のみ）</Typography>

        <Button
          variant="outlined"
          color="inherit"
          size="small"
          disabled={exportLoading}
          sx={{ bgcolor: (theme) => theme.palette.common.white, mr: 1 }}
          onClick={() => exportCSV(lastMonthQueries, startLastMonth)}
        >
          CSVエクスポート
        </Button>
      </Stack>

      <Box mb={3}>
        <FilterBar
          searchColumns={searchColumns}
          layout={'inline'}
          handleChangeParams={handleChangeParamsLastMonth}
        />
      </Box>

      <ReactTable<App.Models.WorkingRecord>
        columns={
          hasLastMonthEdit
            ? columns
            : columns.concat({
                width: 76,
                Header: '',
                id: '__temp'
              } as unknown as Column<App.Models.WorkingRecord>)
        }
        onActionEdit={hasLastMonthEdit ? handleEdit : undefined}
        actionConfig={{
          columnWidth: 60
        }}
        {...data}
      />
    </>
  )
}

export { WorkingRecord }
