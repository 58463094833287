export const genderOptions = [
  { value: 1, label: '男性' },
  { value: 2, label: '女性' }
]

export const isCheck = {
  TRUE: 1,
  FALSE: 0
}

export const statusOptions = [
  { value: 1, label: 'アクティブ' },
  { value: 0, label: '非アクティブ' }
]

export const roleOptions = [
  { value: 1, label: 'admin' },
  { value: 0, label: 'general' }
]

export const permissions = [
  { value: 1, label: '管理者' },
  { value: 2, label: 'コンテンツマネージャー' },
  { value: 3, label: 'コンシェルジュ' },
  { value: 4, label: 'リサーチャー' }
]

export enum PERM {
  ADMIN = 1,
  CONTENT_MANAGER = 2,
  CONCIERGE = 3,
  RESEARCH = 4
}

export const language = [
  { value: 'is_japanese', label: '日本語' },
  { value: 'is_english', label: '英語' }
]

export const fdCategory = {
  category1: 35000779575,
  category2: 35000779138,
  event: 35000781967
}

export const statusNew = [
  { value: 1, label: '公開' },
  { value: 2, label: '非公開' },
  { value: 3, label: '下書き' }
]

export const languageUser = [
  { value: 1, label: '日本語' },
  { value: 2, label: '英語' }
]

export const operations = [
  { value: 1, label: '依頼者へ質問・会話' },
  { value: 2, label: '全てのプロセス' },
  { value: 3, label: 'RSに割り当て' },
  { value: 4, label: '社内協議・アイディア' },
  { value: 5, label: 'クオリティチェック' },
  { value: 6, label: 'レポート・返信' },
  { value: 7, label: 'リサーチ' }
]

export const userVoicesCategories = [
  { value: 1, label: 'コンシェルジュサービス' },
  { value: 2, label: '施設・設備' },
  { value: 3, label: '課題共有・相談事項（月次）' }
]

export const fdStatus = {
  CLOSED: 5,
  RESOLVED: 4
}

export const fdStatusOption = [
  { value: 4, label: 'Open' },
  { value: 5, label: 'Closed' }
]

export const PDF_MARGIN: { X: number; Y: number } = {
  X: 5,
  Y: 16
}
