import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Grid, Stack } from '@mui/material'
import { Input, InputTag, RichText } from 'components/Form'
import { ImageUploader } from 'components/ImageUploader'
import { Page } from 'components/Layouts'
import { useApiResource, useModalState } from 'lib/hooks'
import { handleValidateErrors } from 'lib/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import ConciergePreview from './component/ConciergePreview'

const validateConcierge = yup.object({
  image_path: yup.string().required('アイキャッチ画像を選択してください。'),
  name: yup.string().required('名前は必須です。').trim(),
  name_en: yup.string().required('名前 (English)は必須です。').trim(),
  profile: yup.string().required('プロフィールは必須です。'),
  profile_en: yup.string().required('プロフィール (English)は必須です。'),
  languages: yup.array().required('プロフィール (English)は必須です。'),
  strong_fields: yup.array().required('プロフィール (English)は必須です。'),
  sort_order: yup
    .number()
    .typeError('正しい形式を入力してください。')
    .positive('0より大きい値を入力してください。')
})

const FormConcierge: React.VFC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const isEdit = !!params?.id
  const { isOpen, onClose, onOpen } = useModalState()

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { isSubmitting }
  } = useForm<App.Models.Concierge>({
    defaultValues: {
      id: Number(params?.id),
      name: '',
      name_en: '',
      profile: '',
      profile_en: '',
      languages: [],
      strong_fields: [],
      image_path: '',
      sort_order: undefined
    },
    resolver: yupResolver(validateConcierge)
  })

  const { data } = useQuery<App.Models.Concierge>([`concierges/${params.id}`], {
    onSuccess: (data) => {
      let name: keyof App.Models.Concierge
      for (name in data) {
        setValue(name, data[name])
      }

      setValue('languages', JSON.parse(data.languages))
      setValue('strong_fields', JSON.parse(data.strong_fields))
    },
    enabled: isEdit
  })

  const { createOrUpdateApi } = useApiResource<App.Models.Concierge>('concierges')

  const onSubmit: SubmitHandler<App.Models.Concierge> = async (values) => {
    try {
      await createOrUpdateApi(values)
      toast.success(isEdit ? '更新しました。' : '登録しました。')
      navigate('/concierge')
    } catch (error) {
      if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error(isEdit ? '更新に失敗しました。' : '登録に失敗しました。')
      }
      if (error.errors) {
        handleValidateErrors(error, setError)
      }
    }
  }

  return (
    <Page title={isEdit ? 'コンシェルジュ編集' : 'コンシェルジュ新規登録'}>
      <Container
        maxWidth="md"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} mb={3}>
          <Grid container justifyContent="center">
            <ImageUploader name="image_path" control={control} label="プロフィール画像" />
          </Grid>

          <Input fullWidth label="名前" name="name" control={control} />

          <Input fullWidth label="名前（English)" name="name_en" control={control} />

          <RichText
            name="profile"
            label="プロフィール"
            defaultValue={data?.profile}
            control={control}
          />

          <RichText
            name="profile_en"
            label="プロフィール（English）"
            defaultValue={data?.profile_en}
            control={control}
          />

          <InputTag fullWidth label="語学" name="languages" control={control} />

          <InputTag fullWidth label="得意な領域・サポート" name="strong_fields" control={control} />

          <Input fullWidth label="表示順" name="sort_order" control={control} type="number" />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="outlined" color="inherit" onClick={onOpen}>
            プレビュー
          </Button>
          {isEdit ? (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              更新する
            </LoadingButton>
          ) : (
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              新規登録
            </LoadingButton>
          )}
        </Stack>
      </Container>
      <ConciergePreview open={isOpen} onClose={onClose} {...watch()} />
    </Page>
  )
}

export { FormConcierge }
