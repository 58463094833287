import { Page } from 'components/Layouts'
import { ReactTable } from 'components/ReactTable'
import { TopHeader } from 'components/TopHeader'
import { useApiResource, useCheckPerm, usePaginationQuery } from 'lib/hooks'
import { useDialog } from 'lib/providers'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CellProps, Column, Row } from 'react-table'

const Concierge: React.VFC = () => {
  const { paginationData, refetch, handleChangeParams } =
    usePaginationQuery<App.Models.Concierge>('concierges')
  const { deleteApi } = useApiResource<App.Models.Concierge>('concierges')
  const dialog = useDialog()
  const navigate = useNavigate()
  const { isFullAction } = useCheckPerm()

  const columns = useMemo<Column<App.Models.Concierge>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        search: false
      },
      {
        Header: '名前',
        accessor: 'name',
        width: 500
      },
      {
        Header: '名前(English)',
        accessor: 'name_en',
        width: 500
      },
      {
        Header: '表示順',
        accessor: 'sort_order',
        regex: '_equal'
      }
    ],
    []
  )

  const handleDelete = useCallback(
    async ({ row }: CellProps<App.Models.Concierge>) => {
      await dialog({
        description: '削除してもよろしいですか？',
        dialogProps: {
          maxWidth: 'xs'
        }
      })
      try {
        await deleteApi(row.original.id)
        toast.success('削除しました。')
        refetch()
      } catch (error) {
        toast.error('削除に失敗しました。')
        console.log(error)
      }
    },
    [deleteApi, dialog, refetch]
  )

  const onRowClick = ({ original }: Row<App.Models.Concierge>) => {
    navigate('/concierge/detail/' + original.id)
  }

  return (
    <Page title="コンシェルジュ一覧">
      <TopHeader
        path="/concierge/create"
        searchColumns={columns}
        handleChangeParams={handleChangeParams}
      />

      <ReactTable<App.Models.Concierge>
        columns={columns}
        defaultActionEdit
        onRowClick={onRowClick}
        onActionDelete={handleDelete}
        {...paginationData}
        disabledRowClick={!isFullAction}
      />
    </Page>
  )
}

export { Concierge }
